import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  Stack,
  Center,
  Modal,
  Group,
  Text,
  createStyles,
  Drawer,
  Button,
  Box,
  Loader,
} from "@mantine/core";

import { setHiddenSidebar, setSpace } from "../../store/app";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import isDark from "../../utils/IsDarkColor";
// import {treeData} from '../../collections/testData'
import tinycolor from "tinycolor2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import SpaceModel from "../../models/Space";

import PremiumModal from "../app/PremiumModal";
import Colors from "./colors/Form";

const offsetTop = {
  base: 60,
  origin: 0,
};

import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

import Swal from "sweetalert2";

const useStyles = createStyles((theme, { colors }, getRef) => ({
  breadcrumbs: {
    height: "40px",
    marginBottom: 5,
    backgroundColor: colors.background_color,
  },
  breadcrumbWrap: {
    color: isSpaceThemed(colors)
      ? colors.default_text_color
      : tinycolor(colors.default_text_color).lighten(60).toString(),
    textDecoration: "none",
  },
  breadcrumbLink: {
    color: isSpaceThemed(colors)
      ? colors.default_text_color
      : tinycolor(colors.default_text_color).lighten(40).toString(),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  breadcrumbButton: {
    color: isSpaceThemed(colors)
      ? colors.default_text_color
      : tinycolor(colors.default_text_color).lighten(40).toString(),
    textDecoration: "none",
    // backgroundColor: 'red',
    "&:hover": {
      // backgroundColor: "blue",
      // textDecoration: "underline",
    },
  },
  breadcrumbButtonHover: {
    backgroundColor: "red",
  },
  fixed: {
    position: "fixed",
  },
  topOffset: {
    top: theme.other.headerHeight,
  },
  buttonItem: {
    // border: `1px solid ${tinycolor(colors.default_text_color)
    //   .lighten(90)
    //   .toString()}`,
    paddingTop: "3px",
    paddingBottom: "3px",
    paddingLeft: "4px",
    paddingRight: "4px",
    fontWeight: 400,
    borderRadius: 5,
    color: isSpaceThemed(colors)
      ? colors.default_text_color
      : tinycolor(colors.default_text_color).lighten(40).toString(),
    backgroundColor: isSpaceThemed(colors) ? isDark(colors.background_color)
      ? `${tinycolor(colors.background_color).lighten(10).toString()}`
      : `${tinycolor(colors.background_color).darken(10).toString()}` : colors.secondary_button_background_color,
    "&:hover": {
      color: colors.default_text_color,
      backgroundColor: `transparent`,
    },
    // "&:hover": {
    //   backgroundColor: tinycolor(colors.sidebar_background_color).darken(4).toString()
    // },
  },
}));

export default function TopActions(props) {
  const space = useSelector((state) => state.space);
  const scroll = useSelector((state) => state.scroll);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [fixed, setFixed] = useState(false);
  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(true);

  const hiddenLayoutElements = useRef([]);
  const loading = useRef(false);
  const loaded = useRef(null);

  const [opened, setOpened] = useState([]);
  const [canUploadCover, setCanUploadCover] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [openedColors, setOpenedColors] = useState(false);

  const [isImgEditorHeaderShown, setIsImgEditorHeaderShown] = useState(false);

  const htmlImageHeader = useRef();
  const fileInputHeader = useRef();

  useEffect(() => {
    if (space && space.slug) {

      const changes = JSON.stringify(space.hidden_layout_elements) != JSON.stringify(hiddenLayoutElements.current)

      if (loaded.current == space.slug && !changes){
        return
      }

      loading.current = true;

      loaded.current = space.slug;
      
      setCanUploadCover(
        space && space.permissions && space.permissions.can_upload_cover
      );

      if (window.$hiddenLayoutElements) {
        hiddenLayoutElements.current = [...window.$hiddenLayoutElements];
      } else {
        hiddenLayoutElements.current = [...space.hidden_layout_elements];
      }

      setSidebar(hiddenLayoutElements.current.indexOf("sidebar") >= 0);

      setTimeout(() => {
        loading.current = false;
      }, 1000);
    }
  }, [space]);

  useEffect(() => {
    if (loading.current) {
      return;
    }
    if (sidebar) {
      if (!isHidden("sidebar")) {
        hiddenLayoutElements.current.push("sidebar");
      }
    } else {
      if (isHidden("sidebar")) {
        removeHiddenLayoutElements("sidebar");
      }
    }

    dispatch(
      setHiddenSidebar({
        hidden: sidebar,
      })
    );

    onSave();
  }, [sidebar]);

  const onAddConver = () => {};

  const isHidden = (element) => {
    return hiddenLayoutElements.current.indexOf(element) >= 0;
  };

  const removeHiddenLayoutElements = (element) => {
    const index = hiddenLayoutElements.current.indexOf(element);
    if (index >= 0) {
      hiddenLayoutElements.current.splice(index, 1);
    }
  };

  const onSave = () => {
    if (loading.current) {
      return;
    }
    window.$hiddenLayoutElements = [...hiddenLayoutElements.current];
    SpaceModel.onUpdate(
      space.slug,
      {
        hidden_layout_elements: [...hiddenLayoutElements.current],
      },
      (data) => {},
      (data) => {}
    );
  };

  const handleFileInputHeader = (e) => {
    const file = e.target.files[0];

    if (!isFileValid(file, e.target)) {
      return;
    }

    htmlImageHeader.current = document.createElement("img");

    if (file) {
      const reader = new FileReader();

      reader.addEventListener("load", function () {
        htmlImageHeader.current.src = reader.result;
        openImgEditorHeader();
      });

      reader.readAsDataURL(file);
    } else {
      // Clear the value of the file input
      if (fileInputHeader.current) {
        fileInputHeader.current.value = "";
      }
    }
  };

  const openImgEditorHeader = () => {
    setIsImgEditorHeaderShown(true);
  };

  const closeImgEditorHeader = () => {
    setIsImgEditorHeaderShown(false);
    if (fileInputHeader.current) {
      fileInputHeader.current.value = "";
    }
  };

  const isFileValid = (selectedFile, target) => {
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (selectedFile.size > maxSize) {
      Swal.fire("", "Your image must be less than 5MB", "info");
      target.value = ""; // Clear the selected file
      return false;
    }
    return true;
  };

  const onCoverUploadFinished = (url) => {
    const newSpace = { ...space };
    newSpace.header.url = url;
    dispatch(setSpace(newSpace));
    closeImgEditorHeader();
  };

  const onUploadHeader = (file) => {
    setUploading(true);
    SpaceModel.onUploadHeader(
      space.slug,
      file.imageBase64,
      (data) => {
        setTimeout(() => {
          setUploading(false);
        }, 500);
        onCoverUploadFinished(data.url);
      },
      (data) => {}
    );
  };

  const onRemoveHeader = () => {
    SpaceModel.onRemoveHeader(
      space.slug,
      (data) => {
        onCoverUploadFinished(null);
      },
      (data) => {}
    );
  };

  const onOpenFileSelector = (event) => {
    try {
      event.target.children[0].click();
    } catch {}
  };

  return (
    <Stack
      justify="flex-end"
      className={cx(
        classes.breadcrumbs,
        { [classes.fixed]: fixed },
        { [classes.topOffset]: fixed }
      )}
    >
      <Group id="top-actions">
        {sidebar ? (
          <Button
            leftIcon={<FontAwesomeIcon icon={solid("table-columns")} />}
            className={classes.buttonItem}
            variant="subtle"
            compact
            onClick={(event) => setSidebar(false)}
          >
            Show sidebar
          </Button>
        ) : (
          <Button
            leftIcon={<FontAwesomeIcon icon={solid("table-columns")} />}
            className={classes.buttonItem}
            variant="subtle"
            compact
            onClick={(event) => setSidebar(true)}
          >
            Hide sidebar
          </Button>
        )}
        {space && space.header && !space.header.url ? (
          canUploadCover[0] ? (
            <Button
              leftIcon={<FontAwesomeIcon icon={solid("image")} />}
              className={classes.buttonItem}
              variant="subtle"
              compact
              onClick={(event) => onOpenFileSelector(event)}
            >
              <input
                ref={fileInputHeader}
                type="file"
                style={{ display: "none" }}
                accept={"image/*"}
                onChange={handleFileInputHeader}
              />
              Add cover
            </Button>
          ) : (
            <Button
              leftIcon={<FontAwesomeIcon icon={solid("image")} />}
              className={classes.buttonItem}
              variant="subtle"
              compact
              onClick={(event) =>
                setOpened([true, canUploadCover[1], canUploadCover[2]])
              }
            >
              Add cover
            </Button>
          )
        ) : (
          <Button
            leftIcon={<FontAwesomeIcon icon={solid("image")} />}
            className={classes.buttonItem}
            variant="subtle"
            compact
            onClick={() => onRemoveHeader()}
          >
            Remove cover
          </Button>
        )}
        <Button
          leftIcon={<FontAwesomeIcon icon={solid("palette")} />}
          className={classes.buttonItem}
          variant="subtle"
          compact
          onClick={(event) => setOpenedColors(true)}
        >
          Change colors
        </Button>
      </Group>
      <Modal
        withCloseButton={false}
        size="xl"
        opened={isImgEditorHeaderShown}
        onClose={() => setIsImgEditorHeaderShown(false)}
        title={""}
      >
        {uploading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <FilerobotImageEditor
            source={htmlImageHeader.current}
            onSave={(editedImageObject, designState) =>
              onUploadHeader(editedImageObject, designState)
            }
            onClose={closeImgEditorHeader}
            Rotate={{ angle: 90, componentType: "slider" }}
            Crop={{
              ratio: 1200 / 300,
              ratioTitleKey: "Profile picture",
              noPresets: true,
              autoResize: true,
            }}
            defaultSavedImageQuality={0.7}
            defaultSavedImageName={"profileImage"}
            tabsIds={[TABS.ADJUST]} // or {['Adjust', 'Annotate', 'Watermark']}
            defaultTabId={TABS.ADJUST} // or 'Annotate'
            defaultToolId={TOOLS.TEXT} // or 'Text'
            onBeforeSave={() => false}
          />
        )}
      </Modal>
      <PremiumModal opened={opened} setOpened={setOpened} />
      <Drawer
        // zIndex={5555}
        opened={openedColors}
        onClose={() => setOpenedColors(false)}
        // title="Appearance options"
        title={<Text size="xs">APPEARANCE OPTIONS</Text>}
        styles={{
          header: {
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          },
        }}
        size="xl"
      >
        <Box p={20} style={{ height: "100%" }}>
          <Colors setOpened={setOpenedColors} />
        </Box>
      </Drawer>
    </Stack>
  );
}
