import React, { useEffect, useState } from "react";
import {
  Button,
  Title,
  PasswordInput,
  Container,
  Alert,
  Divider,
  Text,
  Accordion,
  MantineProvider,
  ScrollArea,
  Box,
  Table,
  Anchor,
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/app";
import UserModel from "../../models/User";
import StripeModel from "../../models/Stripe";
import UserSettingModel from "../../models/UserSetting";
import { useTranslation } from "react-i18next";
import { AlertCircle } from "tabler-icons-react";
import { useNavigate, Link } from "react-router-dom";
import { colors } from "../../collections/colors";

import { useForm } from "@mantine/form";

import Swal from "sweetalert2";

import { Helmet } from "react-helmet";

export default function Account() {
  const { t, i18n } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [connections, setConnections] = useState([]);

  useEffect(() => {
    onFetchStripeConnections();
  }, [user]);

  const form = useForm({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },

    validate: {
      current_password: (value) =>
        value.length > 0
          ? null
          : t("settings.accountSettings.invalidCurrentPasswordErrorMsg"),
      password: (value) =>
        value.length >= 8
          ? null
          : t("settings.accountSettings.invalidPasswordErrorMsg"),
      password_confirmation: (value) =>
        value == form.values.password
          ? null
          : t("settings.accountSettings.invalidPasswordConfirmationErrorMsg"),
    },
  });

  const onChangePassword = () => {
    UserModel.onChangePassword(
      user.id,
      form.values,
      (data) => {
        form.reset();
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
      },
      (data) => {
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onDeleteAccount = () => {
    if (user && user.account_type == "basic") {
      Swal.fire({
        title: t("settings.accountSettings.deleteAccountAlert.title"),
        html: t("settings.accountSettings.deleteAccountAlert.message"),
        showDenyButton: true,
        confirmButtonText: t(
          "settings.accountSettings.deleteAccountAlert.confirmButton"
        ),
        denyButtonText: t(
          "settings.accountSettings.deleteAccountAlert.cancelButton"
        ),
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
        input: "text",
        inputLabel: t("settings.accountSettings.deleteAccountAlert.input"),
        inputValidator: (value) => {
          if (!value || value != "delete my account") {
            return "Please follow the instructions";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          onDeleteAccountConfirmation(result.value);
        } else if (result.isDenied) {
        }
      });
    } else {
      Swal.fire({
        title: t("settings.accountSettings.cancelSubscriptionAlert.title"),
        html: t("settings.accountSettings.cancelSubscriptionAlert.message"),
        showDenyButton: true,
        confirmButtonText: t(
          "settings.accountSettings.cancelSubscriptionAlert.confirmButton"
        ),
        denyButtonText: t(
          "settings.accountSettings.cancelSubscriptionAlert.cancelButton"
        ),
        confirmButtonColor: colors.blue,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/settings/billing");
        } else if (result.isDenied) {
        }
      });
    }
  };

  const onDeleteAccountConfirmation = (value) => {
    UserModel.onDestroy(
      user.id,
      value,
      (data) => {
        // Success callback
        form.reset();
        navigate("/user/sign_out");
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "green",
          })
        );
      },
      (data) => {
        // Error callback
        dispatch(
          setAlert({
            opened: true,
            title: data.title,
            message: data.message,
            color: "red",
          })
        );
      }
    );
  };

  const onResetStripe = (value) => {
    Swal.fire({
      title: t("settings.accountSettings.stripeAccount.confirmTitle"),
      html: t("settings.accountSettings.stripeAccount.confirmMessage"),
      showDenyButton: true,
      confirmButtonText: t(
        "settings.accountSettings.stripeAccount.confirmButton"
      ),
      denyButtonText: t("settings.accountSettings.stripeAccount.cancelButton"),
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        UserSettingModel.onDestroy(
          user.id,
          (data) => {
            Swal.fire(data.title, data.message, "success");
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onFetchStripeConnections = () => {
    UserModel.onFetchStripeConnections(
      (data) => {
        setConnections([...data]);
      },
      (data) => {}
    );
  };

  const onDisconnectSpace = (space) => {
    Swal.fire({
      title: "Are you sure?",
      html: `Your Stripe credentials will be cleared from ${space.name}?`,
      showDenyButton: true,
      confirmButtonText: "Yes, disconnect",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        StripeModel.onDisconnect2(
          space.slug,
          (data) => {
            onFetchStripeConnections();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const goToSpace = (space) => {
    return `/space/${space.slug}/${space.version}`;
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Helmet>
        <title>{`Sutra - Account settings`}</title>
      </Helmet>
      <Container
        style={{ marginBottom: "50px" }}
        size={1000}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
      >
        <form onSubmit={form.onSubmit((values) => onChangePassword(values))}>
          <Title sx={(theme) => ({ fontWeight: 900 })}>
            {t("settings.accountSettings.accountSettings")}
          </Title>

          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={10}
            mb={20}
          />

          <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={40}>
            {t("settings.accountSettings.changePassword")}
          </Title>
          <Divider
            sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
            mt={10}
            mb={20}
          />
          <PasswordInput
            {...form.getInputProps("current_password")}
            label={t("settings.accountSettings.currentPassword")}
            mb={20}
          />
          <PasswordInput
            {...form.getInputProps("password")}
            label={t("settings.accountSettings.newPassword")}
            mb={20}
          />
          <PasswordInput
            {...form.getInputProps("password_confirmation")}
            label={t("settings.accountSettings.newPasswordConfirmation")}
            mb={20}
          />
          <Button mt="xl" radius="xl" size="lg" mb={15} type="submit">
            {t("settings.accountSettings.updatePassword")}
          </Button>

          <Box mt={50}>
            {connections && connections.length > 0 && (
              <Box>
                <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={10}>
                  {"Disconnect Stripe by space"}
                </Title>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  mt={10}
                />
                <Table>
                  <thead>
                    <tr>
                      <th>{"Space name"}</th>
                      <th>{"Slug"}</th>
                      <th>{"Actions"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {connections.map((connection) => {
                      return (
                        <tr>
                          <td>
                            <Anchor
                              variant="subtle"
                              component={Link}
                              to={goToSpace(connection.pod)}
                            >
                              {connection.pod.name}
                            </Anchor>
                          </td>
                          <td>{connection.pod.slug}</td>
                          <td>
                            <Button
                              compact
                              variant="outline"
                              radius={"xl"}
                              onClick={() => onDisconnectSpace(connection.pod)}
                            >
                              Disconnect
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Box>
            )}
            {user.can_accept_charges && (
              <Box mt={50}>
                <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={10}>
                  {t("settings.accountSettings.stripeAccount.title")}
                </Title>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  mt={10}
                />
                <Text mt={10} color="dimmed">
                  {t("settings.accountSettings.stripeAccount.alertMessage")}
                </Text>
                <Button
                  mt="xl"
                  radius="xl"
                  size="lg"
                  mb={15}
                  onClick={() => onResetStripe()}
                >
                  {t("settings.accountSettings.stripeAccount.buttonText")}
                </Button>
              </Box>
            )}
          </Box>

          <Accordion
            iconSize={32}
            offsetIcon={false}
            mt={50}
            sx={(theme) => ({
              borderRadius: 10,
              borderBottom: "none",
              border: "solid 1px #f1f1f1",
            })}
            styles={{
              item: {
                borderBottom: "none",
              },
              control: {
                borderRadius: 10,
              },
            }}
          >
            <Accordion.Item label={t("settings.accountSettings.advanced")}>
              <Box>
                <Title sx={(theme) => ({ fontWeight: 600 })} order={2} mt={10}>
                  {t("settings.accountSettings.deleteAccount")}
                </Title>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  mt={10}
                  mb={20}
                />
                <MantineProvider theme={{ primaryShade: 8 }}>
                  <Alert
                    icon={<AlertCircle size={16} />}
                    title={t("settings.accountSettings.warning")}
                    color="red"
                  >
                    {t("settings.accountSettings.deleteAccountWarning")}
                  </Alert>
                  <Button
                    mt="xl"
                    radius="xl"
                    size="lg"
                    color="red"
                    mb={15}
                    onClick={() => onDeleteAccount()}
                  >
                    {t("settings.accountSettings.closeYourAccount")}
                  </Button>
                </MantineProvider>
              </Box>
            </Accordion.Item>
          </Accordion>
        </form>
      </Container>
    </ScrollArea>
  );
}
