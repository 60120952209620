import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import {
  Text,
  Button,
  Stack,
  Box,
  Tooltip,
  createStyles,
  Progress,
  Popover,
  Indicator,
  Grid,
  ScrollArea,
  Drawer,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";

import isSpaceThemed from "../../utils/IsSpaceThemed";
import useSidebarStyles from "../../utils/SidebarStyles";
import defaultSpaceColors from "../../collections/defaultSpaceColors";
import useNotificationsStyle from "./style/notifications.js";

import tinycolor from "tinycolor2";

import AWS from "aws-sdk";

import { ActionCableContext } from "../../components/Index";

import Broadcaster from "../../helpers/Broadcaster";

import { v4 as uuidv4 } from "uuid";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors }) => ({
  primaryButtonStyle: {
    backgroundColor: isSpaceThemed(colors)
      ? colors.primary_button_background_color
      : theme.colors.gray[2],
    color: isSpaceThemed(colors) ? colors.primary_button_text_color : "#000000",
    "&:hover": {
      backgroundColor: isSpaceThemed(colors)
        ? tinycolor(colors.primary_button_background_color).darken(4).toString()
        : theme.colors.gray[1],
    },
  },
}));

const S3_VIDEO_BUCKET = "sutra-froala";
const S3_IMAGE_BUCKET = "sutra-froala";
const S3_AUDIO_BUCKET = "sutra-froala";
const S3_FILE_BUCKET = "sutra-froala";
const REGION = "us-west-1";

export default function Uploader(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { notificationsStyle } = useNotificationsStyle();
  const mainActions = useSelector((state) => state.mainActions);

  const { settingsView, registrationView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  colors = !settingsView && !registrationView ? colors : defaultSpaceColors;
  const { classes, cx } = useStyles({ colors });

  const [opened, handlers] = useDisclosure(false);
  const [count, setCount] = useState(0);
  const [updateUploads, setUpdateUploads] = useState(0);
  const [uploads, setUploads] = useState([]);

  const awsChannel = useRef();
  const compId = useRef();

  const cable = useContext(ActionCableContext);

  useEffect(() => {
    compId.current = uuidv4();

    AWS.config.update({
      accessKeyId: window.$RAILS_ENV.S3_ACCESS_KEY,
      secretAccessKey: window.$RAILS_ENV.S3_SECRET_KEY,
    });

    AWS.config.httpOptions.timeout = 0;

    cableConnect();

    // checkUploads()

    deleteLocalUploadRecordsIfNeeded();

    Broadcaster.receive("new_uploadFile", document.body, (event, data) => {
      if (!document.getElementById(compId.current)) {
        return;
      }
      if (data.status == "pending") {
        processUpload(data);
        setTimeout(() => {
          handlers.open();
        }, 500);
      } else if (data.status == "used") {
        markUploadAs(data);
      }
    });

    return () => {
      Broadcaster.unRegisterElement("new_uploadFile", document.body);

      if (awsChannel.current) {
        awsChannel.current.unsubscribe();
        awsChannel.current = null;
      }
    };
  }, []);

  useEffect(() => {
    checkUploads();
    deleteLocalUploadRecordsIfNeeded();
  }, [mainActions]);

  const checkUploads = () => {
    setTimeout(() => {
      const currentUploads = localStorage.getItem("currentUploads");
      if (currentUploads) {
        window.$currentUploads = JSON.parse(currentUploads);
        for (let i = 0; i < window.$currentUploads.length; i++) {
          const upload = window.$currentUploads[i];
          checkOldUploadStatus(upload);
          // upload.status = "used";
        }
        refresh();
      }
    }, 5000);
  };

  const checkOldUploadStatus = (upload) => {
    if (upload.type == "video") {
      upload.progress = 100;
      if (upload.status == "pending" || upload.status == "uploading") {
        // Marc upload with error
        upload.status = "error2";
        const ele = document.getElementById(upload.id);
        Broadcaster.send("set_uploadFile", ele, {
          uploader: { ...upload },
          src: upload.url,
        });
        if (!ele) {
          upload.status = "used";
          markUploadAs(upload);
        }
      } else if (upload.status == "processing") {
        if (!upload.intervalCount) {
          upload.intervalCount = 0;
          upload.intervalId = setInterval(() => {
            upload.intervalCount++;
            if (upload.intervalCount < 100) {
              const videoURL = `${upload.url}`;
              isVideoURLValid(videoURL).then((valid) => {
                if (valid) {
                  //splice upload
                  upload.status = "completed";

                  Broadcaster.send(
                    "set_uploadFile",
                    document.getElementById(upload.id),
                    {
                      uploader: { ...upload },
                      src: upload.url,
                    }
                  );
                  // upload.status = "used";
                  // markUploadAs(upload);
                } else {
                  console.log("Video URL is not valid or does not exist.");
                }
              });
            } else {
              //splice upload
              upload.status = "used";
              markUploadAs(upload);
            }
          }, 5000);
        }
      } else {
        //splice upload
        upload.status = "used";
        markUploadAs(upload);
      }
    } else {
      //splice upload
    }
  };

  const isVideoURLValid = (url, upload) => {
    try {
      return fetch(`/api/v4/proxy?url=${url}`, { method: "head" })
        .then((response) => {
          // Check if the response status is in the 200-299 range, indicating a successful request.
          if (response.ok) {
            return true;
          } else {
            return false;
          }
        })
        .catch((error) => {
          // An error occurred, which likely means the URL is not valid or doesn't exist.
          return false;
        });
    } catch {
      return false;
    }
  };

  const storeLastUploadedAt = () => {
    const currentTimestamp = new Date().getTime();
    localStorage.setItem('lastUploadedAt', currentTimestamp.toString());
  }
  
  // Function to retrieve the stored timestamp and check if it's more than 1 day ago
  const deleteLocalUploadRecordsIfNeeded = () => {
    const storedTimestamp = localStorage.getItem('lastUploadedAt');
  
    if (storedTimestamp) {
      const storedDate = new Date(parseInt(storedTimestamp, 10));
      const currentDate = new Date();
  
      // Calculate the time difference in milliseconds
      const timeDifference = currentDate - storedDate;
      
      // Define the threshold for 1 day (in milliseconds)
      const oneDayThreshold = 24 * 60 * 60 * 1000;
  
      // Check if the stored timestamp is more than 1 day ago
      if (timeDifference >= oneDayThreshold) {
        console.log("More than 1 day has passed since the last timestamp.");
        localStorage.setItem(
          "currentUploads",
          "[]"
        );
      } else {
        console.log("Less than 1 day has passed since the last timestamp.");
      }
    } else {
      localStorage.setItem(
        "currentUploads",
        "[]"
      );
      window.$currentUploads = []
      refresh();
    }
  }

  // Example usage:
  const videoURL = "https://example.com/sample-video.mp4";

  const refresh = () => {
    if (window.$currentUploads) {
      setCount(window.$currentUploads.length);
    }

    setUploads([...window.$currentUploads]);
    setUpdateUploads(updateUploads + 1);
  };

  const markUploadAs = (data) => {
    if (!window.$currentUploads) {
      return
    }
    for (let i = 0; i < window.$currentUploads.length; i++) {
      const upload = window.$currentUploads[i];
      if (upload.id == data.id) {
        upload.status = data.status;

        if (data.status == "used") {
          window.$currentUploads.splice(i, 1);
          localStorage.setItem(
            "currentUploads",
            JSON.stringify(window.$currentUploads)
          );
          storeLastUploadedAt()
        }

        refresh();

        return;
      }
    }
  };

  const processUpload = (data) => {
    if (!window.$currentUploads) {
      window.$currentUploads = [];
    }

    let exist = false;
    for (let i = 0; i < window.$currentUploads.length; i++) {
      const upload = window.$currentUploads[i];
      if (upload.id == data.id) {
        exist = true;
        break;
      }
    }

    if (!exist) {
      const upload = {
        id: data.id,
        type: data.type,
        client: newClient(data.type),
        progress: 0,
        status: "pending",
        file: data.file,
        space: data.space,
        url: data.url,
        extension: data.extension,
      };

      window.$currentUploads.push(upload);
      localStorage.setItem(
        "currentUploads",
        JSON.stringify(window.$currentUploads)
      );
      storeLastUploadedAt()
      uploadFile(upload);
    }

    refresh();
  };

  const newClient = (type) => {
    if (type == "video") {
      return new AWS.S3({
        params: { Bucket: S3_VIDEO_BUCKET },
        region: REGION,
        keyStart: window.$RAILS_ENV.AWS_CONFIG.key_start,
      });
    } else if (type == "audio") {
      return new AWS.S3({
        params: { Bucket: S3_AUDIO_BUCKET },
        region: REGION,
        keyStart: window.$RAILS_ENV.AWS_CONFIG.key_start,
      });
    } else if (type == "file") {
      return new AWS.S3({
        params: { Bucket: S3_FILE_BUCKET },
        region: REGION,
        keyStart: window.$RAILS_ENV.AWS_CONFIG.key_start,
      });
    } else {
      return new AWS.S3({
        params: { Bucket: S3_IMAGE_BUCKET },
        region: REGION,
        keyStart: window.$RAILS_ENV.AWS_CONFIG.key_start,
      });
    }
  };

  const getVideoPreview = (upload) => {
    if (upload.extension == "mov" || upload.extension == "avi") {
      setTimeout(() => {
        upload.thumbnail =
          "https://sutra-froala.s3.us-west-1.amazonaws.com/video_default_thumbnail.jpeg";

        Broadcaster.send("set_uploadFile", document.getElementById(upload.id), {
          uploader: upload,
          src: upload.url,
        });
      }, 3000);

      return;
    }

    const videoElement = document.createElement("video");
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    videoElement.src = URL.createObjectURL(upload.file);

    videoElement.addEventListener("loadeddata", function () {
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      const durationInSeconds = videoElement.duration;

      setTimeout(() => {
        if (durationInSeconds > 10) {
          videoElement.currentTime = 10;
        } else if (durationInSeconds > 5) {
          videoElement.currentTime = 5;
        } else {
          videoElement.currentTime = 1;
        }
      }, 3000);
    });

    videoElement.addEventListener("seeked", () => {
      context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a data URL (base64-encoded image)
      const thumbnailDataURL = canvas.toDataURL("image/jpeg", 0.5); // Adjust quality as needed

      // Display the thumbnail image
      upload.thumbnail = thumbnailDataURL;

      Broadcaster.send("set_uploadFile", document.getElementById(upload.id), {
        uploader: upload,
        src: upload.url,
      });
    });

    videoElement.load();
  };

  const startProcessingInterval = (upload) => {
    if (upload.status == "processing") {
      return;
    }

    const videoElement = document.createElement("video");

    videoElement.src = URL.createObjectURL(upload.file);
    videoElement.addEventListener("loadedmetadata", function () {
      const durationInSeconds = videoElement.duration;

      console.log(`PROCESSING FILE: ${durationInSeconds} seconds`);
      const minutesPer100Mb = 2;
      const size = upload.file.size;
      let durationIncrement = durationInSeconds / 30.0 / 15.0;
      if (durationIncrement < 1) {
        durationIncrement = 1;
      }
      const fileSizeIn100MBChunks = size / (100 * 1024 * 1024);
      const minutesToProcess = fileSizeIn100MBChunks * minutesPer100Mb;
      const secondsToProcess = minutesToProcess * 60 * durationIncrement;
      let secondsPassed = 0;
      upload.progress = 0;
      upload.status = "processing";
      localStorage.setItem(
        "currentUploads",
        JSON.stringify(window.$currentUploads)
      );
      storeLastUploadedAt()

      Broadcaster.send("set_uploadFile", document.getElementById(upload.id), {
        uploader: upload,
        src: upload.url,
      });

      const interval = setInterval(() => {
        secondsPassed++;
        upload.progress = (secondsPassed * 100) / secondsToProcess;

        refresh();

        if (
          upload.progress >= 100 ||
          upload.status == "completed" ||
          upload.status == "error"
        ) {
          clearInterval(interval);

          upload.status = "completed";
          localStorage.setItem(
            "currentUploads",
            JSON.stringify(window.$currentUploads)
          );
          storeLastUploadedAt()
          Broadcaster.send(
            "set_uploadFile",
            document.getElementById(upload.id),
            {
              uploader: upload,
              src: upload.url,
            }
          );
        }
      }, 1000);
    });

    videoElement.load();
  };

  const uploadFile = (upload) => {
    const key = `uploads/${upload.id}`;

    const folderParams = {
      ACL: "public-read",
      Body: "",
      Bucket: upload.client.config.params.Bucket,
      Key: key.split("/")[0] + "/",
    };

    const params = {
      ACL: "public-read",
      Body: upload.file,
      Bucket: upload.client.config.params.Bucket,
      Key: key,
    };

    upload.status = "uploading";
    localStorage.setItem(
      "currentUploads",
      JSON.stringify(window.$currentUploads)
    );
    storeLastUploadedAt()

    getVideoPreview(upload);

    upload.client.putObject(folderParams, (err, data) => {
      if (err) {
      } else {
        upload.client
          .putObject(params, (err, data) => {
            if (upload.type == "video") {
              startProcessingInterval(upload);
            } else {
              upload.status = "finishing";
              setTimeout(() => {
                upload.status = "completed";
                Broadcaster.send(
                  "set_uploadFile",
                  document.getElementById(upload.id),
                  {
                    src: `https://${upload.client.config.params.Bucket}.s3.${REGION}.amazonaws.com/${key}`,
                    uploader: upload,
                    id: upload.id,
                  }
                );
                refresh();
              }, 1000);
            }
          })
          .on("httpUploadProgress", (evt) => {
            if (upload.status == "processing") {
              return;
            }
            const newProgress = Math.round((evt.loaded / evt.total) * 100);
            if (upload.progress < newProgress) {
              upload.progress = newProgress;
            }

            refresh();
          })
          .send((err) => {
            if (err) console.log(err);
          });
      }
    });
  };

  const cableConnect = () => {
    if (!awsChannel.current) {
      awsChannel.current = cable.subscriptions.create(
        {
          channel: "VideoChannel",
          token: window.$videoUploadKey,
        },
        {
          connected: () => {},
          disconnected: () => {},
          received: (data) => {
            console.log("VIDEOOOOO");
            if (!window.$currentUploads) {
              return;
            }
            console.log(data);
            for (let i = 0; i < window.$currentUploads.length; i++) {
              const upload = window.$currentUploads[i];
              if (upload.id.indexOf(data.aws_data.id) >= 0) {
                upload.url = data.aws_data.url;
                if (data.aws_data.result.state == "COMPLETED") {
                  upload.status = "completed";
                } else {
                  upload.status = "error";
                }
                upload.result = { ...data.aws_data.result };
                refresh();

                Broadcaster.send(
                  "set_uploadFile",
                  document.getElementById(upload.id),
                  {
                    uploader: upload,
                    src: upload.url,
                  }
                );

                return;
              }
            }
          },
        }
      );
    }
  };

  const hide = () => {
    const mustHide =
      !window.$currentUploads ||
      (window.$currentUploads && window.$currentUploads.length == 0);

    if (mustHide) {
      handlers.close();
    }

    return mustHide;
  };

  const getErrorMessage = (upload) => {
    if (upload.status == "error2") {
      return "Error: Upload was cancelled";
    }
    const message = upload.result.messageDetails.split(":").pop();
    return `Error: ${message}`;
  };

  const RenderTriggerButton = () => {
    return (
      <Indicator
        size={count > 0 ? 20 : 0}
        label={count > 0 ? count : ""}
        offset={4}
        color="sutrapink"
        onClick={() => handlers.toggle()}
        sx={(theme) => ({
          cursor: "pointer",
          "&:hover button": {
            backgroundColor: isSpaceThemed(colors)
              ? tinycolor(colors.primary_button_background_color)
                  .darken(4)
                  .toString()
              : theme.colors.gray[1],
          },
        })}
      >
        <Tooltip disabled={window.$isTouchDevice && window.$isTouchDevice()} label="Uploads" withArrow>
          <Button
            variant="light"
            color="dark"
            radius="xl"
            onClick={() => handlers.toggle()}
            className={classes.primaryButtonStyle}
          >
            <FontAwesomeIcon icon={solid("cloud-arrow-up")} />
          </Button>
        </Tooltip>
      </Indicator>
    );
  };

  const RenderInsides = () => {
    return (
      <Box key={`uploads-${new Date()}`}>
        <ScrollArea style={{ height: props.component == "drawer" ? "calc(100vh - 100px)" : "calc(100vh - 150px)" }}>
          {uploads.map((upload, index) => {
            return (
              <Box sx={(theme) => notificationsStyle.notificationItem}>
                <Grid grow>
                  <Grid.Col span={10}>
                    <Text color="dimmed" size="sm">
                      {upload.space.name}
                    </Text>
                    <Box sx={{ overflow: "hidden", maxWidth: "250px" }}>
                      <Text
                        color="black"
                        weight="600"
                        pb={2}
                        sx={{ lineHeight: 1.2 }}
                        lineClamp={1}
                      >
                        {upload.file.name}
                      </Text>
                    </Box>
                    <Progress
                      color={
                        upload.status == "error" || upload.status == "error2"
                          ? "red"
                          : upload.status == "processing"
                          ? "blue"
                          : "green"
                      }
                      size="xl"
                      mb={10}
                      value={upload.progress}
                      striped={upload.intervalId != null}
                      animate={upload.intervalId != null}
                    />
                    <Text
                      size="xs"
                      sx={(theme) => {
                        color: theme.colors.sutrapink[4];
                      }}
                    >
                      {upload.status != "error" && upload.status != "error2"
                        ? t(`layout.uploader.${upload.status}`)
                        : getErrorMessage(upload)}
                    </Text>
                  </Grid.Col>
                  {false && (
                    <Grid.Col span={1}>
                      <Stack sx={(theme) => notificationsStyle.badge}>
                        <FontAwesomeIcon icon={solid("circle")} />
                      </Stack>
                    </Grid.Col>
                  )}
                </Grid>
              </Box>
            );
          })}
        </ScrollArea>
      </Box>
    );
  };

  return (
    <>
      {props.component == "drawer" ? (
        <>
          <Drawer
            opened={props.opened}
            onClose={() => props.setOpened(false)}
            title=""
            padding={0}
            size="lg"
          >
            {RenderInsides()}
          </Drawer>
        </>
      ) : (
        <Popover
          id={compId.current}
          style={{
            display: !hide() ? "block" : "none",
          }}
          opened={opened}
          onClose={handlers.close}
          width={300}
          position="bottom"
          placement="end"
          spacing={0}
          shadow="lg"
          target={RenderTriggerButton()}
        >
          {RenderInsides()}
        </Popover>
      )}
    </>
  );
}
