import React, { useState, useEffect, useRef } from "react";

import {
  createStyles,
  Text,
  ActionIcon,
  Button,
  MantineProvider,
  Group,
  Box,
  Select,
  Accordion
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Swal from "sweetalert2";
import { DateTime } from "luxon";

import useLangUser from "../../../../utils/LangUser";
import TriggerModel from "../../../../models/EngagementTrigger";
import FunctionModel from "../../../../models/EngagementFunction";
import Step from "./Step";
import AddTrigger from "./AddTrigger";
import StepIcon from "./StepIcon";

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    padding: `15px 20px 15px 5px`,
    // paddingRight: theme.spacing.xs, // to offset drag handle
    backgroundColor: "#fff",
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    width: 80,
  },

  settings: {
    color: theme.colors.gray[6],
    fontSize: 18,
    width: 30,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.colors.gray[6],
    paddingLeft: 10,
    paddingRight: 0,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  stepFooterPadding: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "spaceBetween",
  },

  stepFooterLeft: {
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterRight: {
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterAddStep: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
  },

  addStepHidden: {
    visibility: "hidden",
  },

  addStepHover: {
    "&:hover": {
      color: "#fff",
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      backgroundColor: theme.colors.sutrablue[3],
    },
  },

  bottomConnector: {
    fontSize: 20,
    color: theme.colors.gray[4],
    marginTop: -15,
    textAlign: "center",
    height: 20,
  },

  addStepButtonWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },

  addStepButtonHover: {
    "&:hover": {
      backgroundColor: theme.colors.sutrablue[3],
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      color: "#fff",
    },
  },
}));

export default function Engagement({ onUpdate }) {
  const { classes, cx, theme } = useStyles();
  const { language, setLangUser } = useLangUser();
  const space = useSelector((state) => state.space);
  const { t, i18n } = useTranslation();
  const [triggers, setTriggers] = useState([])
  const [addStepOpened, setAddStepOpened] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [steps, handlers] = useListState([]);
  const [selectedStep, setSelectedStep] = useState("");
  const [stepPosition, setStepPosition] = useState(null);
  const [functions, setFunctions] = useState([]);
  const [oriFunctions, setOriFunctions] = useState([]);

  useEffect(() => {
    onFetchFunctions();
  }, []);

  useEffect(() => {
    onFetch()
  }, [space])

  const onFetchFunctions = () => {
    FunctionModel.onFetch(
      (data) => {
        setFunctions(data.map(item => ({label: item.name, value: item.slug})));
        setOriFunctions(data);
      },
      (data) => {}
    );
  };

  const onFetch = () => {
    if (!space.slug){
      return
    }
    TriggerModel.onFetch(
      space.slug,
      (data) => {
        setTriggers(data);
        handlers.setState(data.steps);
      },
      (data) => {}
    );
  };

  return (
    <>
      <Text size="sm" color="dimmed" mb={20}>
        {"This sections allows you to create a sequence of automated notifications that are triggered based on a person’s activity in a your interactive learning space.  Here you can add triggers based on different events and configure corresponding actions."}
      </Text>
      {triggers.map(trigger => {
        return (
          <Accordion iconPosition="right" icon={<FontAwesomeIcon icon={solid('plus')} />} styles={{
            item: {
              border: "1px solid #dee2e6",
              marginBottom: "10px",
              borderRadius: "10px",
              "&:hover": {
                borderRadius: "10px",
              }
            },
            control: {
              "&:hover": {
                borderRadius: "10px",
              }
            },
            content: {
              paddingLeft: "5px"
            }
          }}>
            <Accordion.Item label={trigger.name_with_variable}>
              <Step trigger={trigger} onUpdateTriggers={onFetch} functions={oriFunctions} />

            </Accordion.Item>
          </Accordion>
        )
      })}
      <AddTrigger onUpdateTriggers={onFetch} functions={functions} oriFunctions={oriFunctions}/>
    </>
  );
}
