import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Box,
  Text,
  Group,
  Accordion,
  Skeleton,
  ScrollArea,
} from "@mantine/core";

import { useNavigate, useParams } from "react-router-dom";

import InvitePeople from "./members/InvitePeople";

import Scheduling from "./main/Scheduling";
import Additional from "./main/Additional";
import Seo from "./main/Seo";
import Registration from "./main/Registration";
import Top from "./main/Top";
import Privacy from "./main/Privacy";
import Appearances from "../colors/Form";

import { useTranslation } from "react-i18next";

import RegistrationFlow from "./registration/RegistrationFlow";
import Engagement from "./engagement/Engagement";

import SpaceSettingModel from "../../../models/SpaceSetting";

import PremiumModal from "../../app/PremiumModal";

export default function Main() {
  const { t, i18n } = useTranslation();

  const paramsUrl = useParams();

  const [privacySelection, setPrivacySelection] = useState("open");
  const [blockUntilRegistered, setBlockUntilRegistered] = useState();
  const [accordionState, setAccordionState] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });

  const [inviteOpened, setInviteOpened] = useState(false);

  const [opened, setOpened] = useState(false);

  const [loading, setLoading] = useState(false);

  const accordionLoaded = useRef();

  useEffect(() => {
    if (accordionLoaded.current) {
      localStorage.setItem(
        "lastOpenedSettingsTabs",
        JSON.stringify(accordionState)
      );
    }
  }, [accordionState]);

  useEffect(() => {
    const search = window.location.search;
    const section = new URLSearchParams(search).get("section");
    if (section) {
      const sections = {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      };
      sections[section] = true;
      setAccordionState(sections);
    } else {
      const auxState = localStorage.getItem("lastOpenedSettingsTabs", "");
      if (auxState && auxState != "null") {
        setAccordionState(JSON.parse(auxState));
      }
    }

    accordionLoaded.current = true;
  }, [paramsUrl]);

  const itemsList = [
    {
      label: t("spaces.settings.main.main.label2"),
      index: 0,
    },

    {
      label: t("spaces.settings.main.main.label3"),
      description: t("spaces.settings.main.main.description4"),
      index: 1,
    },
    {
      label: t("spaces.settings.main.main.label4"),
      description: t("spaces.settings.main.main.description5"),
      index: 2,
    },
    {
      label: t("spaces.settings.main.main.label5"),
      description: t("spaces.settings.main.main.description6"),
      index: 3,
    },
    
    {
      label: t("spaces.settings.main.main.label6"),
      description: t("spaces.settings.main.main.description7"),
      index: 4,
    },
    
    {
      label: "Appearance",
      description: "Change the look and feel of your space with custom covers, logos, page layouts and color themes",
      index: 5,
    },
    {
      label: t("spaces.settings.main.main.label7"),
      description: t("spaces.settings.main.main.description8"),
      index: 6,
    },
    {
      label: "Advanced engagement",
      description: "Create a sequence of emails to engage participants in your conversational experience.",
      index: 7,
    },
  ];

  const privacyOptions = [
    {
      label: t("spaces.settings.main.main.label8"),
      value: "open",
      description: t("spaces.settings.main.main.description9"),
    },

    {
      label: t("spaces.settings.main.main.label9"),
      value: "private",
      description: t("spaces.settings.main.main.description10"),
    },
    {
      label: t("spaces.settings.main.main.label10"),
      value: "payment",
      description: t("spaces.settings.main.main.description11"),
    },
  ];

  const registrationOptions = [
    {
      value: "open and on",
      description: t("spaces.settings.main.main.description12"),
    },

    {
      value: "open and off",
      description: t("spaces.settings.main.main.description13"),
    },
    {
      value: "private and on",
      description: t("spaces.settings.main.main.description14"),
    },
    {
      value: "private and off",
      description: t("spaces.settings.main.main.description15"),
    },
    {
      value: "payment and on",
      description: t("spaces.settings.main.main.description16"),
    },
    {
      value: "payment and off",
      description: t("spaces.settings.main.main.description17"),
    },
  ];

  const AccordionLabel = ({ label, description, index }) => {
    return (
      <Group noWrap>
        <div>
          <Text size="lg" weight={700}>
            {label}
          </Text>

          {!accordionState[index] ? (
            <Text size="sm" color="dimmed" weight={400}>
              {description}
            </Text>
          ) : (
            ""
          )}
        </div>
      </Group>
    );
  };

  const onUpdate = (params) => {
    if (Object.keys(params).length === 0) {
      return;
    }
    SpaceSettingModel.onUpdate(
      paramsUrl.slug,
      params,
      (data) => {},
      (data) => {}
    );
  };

  const getPrivacyMessage = () => {
    const selection = privacyOptions.filter(
      (item) => item.value == privacySelection
    )[0];
    if (selection) {
      return `${selection.label} - ${selection.description}`;
    } else {
      return "";
    }
  };

  const getRegistrationMessage = () => {
    const showPage = blockUntilRegistered ? "on" : "off";
    const registrationSetting = `${privacySelection} and ${showPage}`;
    const selection = registrationOptions.filter(
      (item) => item.value == registrationSetting
    )[0];

    if (selection) {
      return selection.description;
    } else {
      return "";
    }
  };

  if (loading) {
    return (
      <Container
        sx={(theme) => ({
          // maxWidth: "960px",
          // width: "960px",
          height: "100%",
        })}
      >
        <Skeleton height={25} width="40%" mt={30} ml={30} />
        <Skeleton height={40} width="60%" mt={20} mb={20} ml={30} />
      </Container>
    );
  }

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          // width: "960px",
          // maxWidth: "960px",
        })}
      >
        <Top setInviteOpened={setInviteOpened} />

        <Accordion
          initialItem={1}
          iconPosition="right"
          multiple
          pl={5}
          state={accordionState}
          onChange={(event) => setAccordionState(event)}
        >
          <Accordion.Item
            label={
              <AccordionLabel
                {...itemsList[0]}
                key={itemsList[0].label}
                description={`${getPrivacyMessage()}`}
              />
            }
          >
            <Privacy
              privacyOptions={privacyOptions}
              onUpdate={onUpdate}
              privacySelection={privacySelection}
              setPrivacySelection={setPrivacySelection}
            />
          </Accordion.Item>

          <Accordion.Item
            label={
              <AccordionLabel
                {...itemsList[1]}
                key={itemsList[1].label}
                description={`${getRegistrationMessage()}`}
              />
            }
          >
            <Registration
              onUpdate={onUpdate}
              setBlockUntilRegistered={setBlockUntilRegistered}
            />
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[2]} />}
            key={itemsList[2].label}
          >
            <Text size="sm">
              <RegistrationFlow onUpdate={onUpdate} />
            </Text>
          </Accordion.Item>
          

          <Accordion.Item
            label={<AccordionLabel {...itemsList[3]} />}
            key={itemsList[3].label}
          >
            <Scheduling onUpdate={onUpdate} />
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[4]} />}
            key={itemsList[4].label}
          >
            <Text size="sm">
              <Additional onUpdate={onUpdate} />
            </Text>
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[5]} />}
            key={itemsList[5].label}
          >
            <Text size="sm">
              <Appearances />
            </Text>
          </Accordion.Item>

          <Accordion.Item
            label={<AccordionLabel {...itemsList[6]} />}
            key={itemsList[6].label}
          >
            <Text size="sm">
              <Seo onUpdate={onUpdate} />
            </Text>
          </Accordion.Item>
          {window.$currentRole == "admin" && (
            <Accordion.Item
              label={<AccordionLabel {...itemsList[7]} />}
              key={itemsList[7].label}
            >
              <Text size="sm">
                <Engagement onUpdate={onUpdate} />
              </Text>
            </Accordion.Item>
          )}
        </Accordion>

        <Box mb={50} />

        <InvitePeople opened={inviteOpened} setOpened={setInviteOpened} />
        <PremiumModal opened={opened} setOpened={setOpened} />
      </Container>
    </ScrollArea>
  );
}
