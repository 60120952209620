import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActions } from "../../../../store/app";
import { storeJSON, getJSON } from "../../../../utils/LocalDB";
import pako from "pako";

import { useParams } from "react-router-dom";

import {
  Stack,
  Box,
  ScrollArea,
  createStyles,
  Container,
  SimpleGrid,
  Drawer,
  Paper,
  Avatar,
  Group,
  Text,
  ActionIcon,
  Tooltip,
  Loader,
  Center,
  Chips,
  Chip,
} from "@mantine/core";

import SpaceModel from "../../../../models/Space";
import SpaceNeoModel from "../../../../models/neo4j/Space";

import ViewModel from "../../../../models/View";
import DefaultAvatar from "../../../../src/images/fallback/default.png";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";
import SpaceListItem from "../List";

import NewSpace from "./NewSpace";
import Settings from "./Settings";
import Thumbnail from "./Thumbnail";
import Edit from "./Edit";
import DuplicateForm from "../../form/DuplicateForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useNavigate } from "react-router-dom";

import TiptapChannel from "../../../tiptap/components/TiptapChannel";
import TiptapInline from "../../../tiptap/components/TiptapInline";
import TiptapReadonly from "../../../tiptap/components/TiptapReadonly";

import Broadcaster from "../../../../helpers/Broadcaster";

import tinycolor from "tinycolor2";

import { useTranslation } from "react-i18next";

import TagsChips from "../discussion/TagsChips";

const paginator = {
  per: 50,
  page: 1,
};

const scrollTimeoutIds = [];

const useStyles = createStyles((theme, { colors }, getRef) => ({
  spaceContainer: {
    backgroundColor: colors.background_color,
    // backgroundColor: colors.sidebar_background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
      borderRadius: "5px",
    },
    chipStyle: {
      color: colors.default_text_color,
      backgroundColor: colors.background_color,
      height: "auto",
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(40).toString()
              : tinycolor(colors.background_color).darken(40).toString()
          }`
        : `1px solid #ced4da`,
      "&:hover": {
        backgroundColor: isSpaceThemed(colors)
          ? `${
              isDark(colors.background_color)
                ? tinycolor(colors.background_color).lighten(5).toString()
                : tinycolor(colors.background_color).darken(5).toString()
            }`
          : `#e9ecef`,
      },
    },
  },
  separator: {
    borderBottom: isSpaceThemed(colors)
      ? `1px solid ${
          isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(20).toString()
            : tinycolor(colors.background_color).darken(20).toString()
        }`
      : `1px solid #e9ecef`,
  },
}));

export default function List(props) {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const children = useSelector((state) => state.children);
  const mainActions = useSelector((state) => state.mainActions);
  const [spaces, setSpaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSpaceOpened, setNewSpaceOpened] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [thumbnailOpened, setThumbnailOpened] = useState(false);
  const [editOpened, setEditOpened] = useState(false);
  const [openedDuplicate, setOpenedDuplicate] = useState(false);

  const [settings, setSettings] = useState({});
  const [content, setContent] = useState({});
  const [loadingMore, setLoadingMore] = useState(false);
  const [tags, setTags] = useState(null);
  const [tagsList, setTagsList] = useState([]);

  const colors = useSelector((state) => state.colors);
  const { classes, cx, theme } = useStyles({ colors });

  const viewport = useRef();
  const selectedSpace = useRef(null);
  const spacesArray = useRef([]);
  const reload = useRef(false);
  const contentEmpty = useRef(true);
  const noMore = useRef(false);
  const lastSlug = useRef();
  const tagFiltering = useRef();
  const tagTimeout = useRef();
  const tagsTimeout = useRef();
  const ctrlTags = useRef();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActions({ editPage: false }));
    setLoading(true);
    return () => {
      paginator.page = 1;
      setSpaces([]);
      spacesArray.current = [];
      setContent({});
    };
  }, []);

  useEffect(() => {
    if (params.slug) {
      if (params.slug && lastSlug.current != params.slug) {
        lastSlug.current = params.slug;
        paginator.page = 2;
        const ctrl = new AbortController();
        // fetchSpacesIDB();
        onFetchView();
      }
    }
  }, [params]);

  useEffect(() => {
    onFetchTags();
  }, [space]);

  useEffect(() => {
    if (children.slug != params.slug) {
      setSpaces([]);
      spacesArray.current = [];
      return;
    }
    if (children.children) {
      let newSpaces = []
      if (spacesArray.current.length > 0){
        for (let i = 0; i < children.children.length; i++) {
          const child = children.children[i];
          if (!child.update_count){
            child.update_count = 0
          }
          child.update_count += 1
          newSpaces.push(child)
        }
      }
      else{
        newSpaces = [...children.children]
      }
      setSpaces(newSpaces);
      spacesArray.current = [...newSpaces];
      setLoading(false);
    }
  }, [children]);

  useEffect(() => {
    if (space && space.slug) {
      setSettings({
        openPost: space.resource_editing == "open",
        visualType: space.present_as,
        authors: space.settings.list_show_author,
        listChildVersion: space.settings.list_child_version,
        listActionText: space.settings.list_action_text,
        listTemplateId: space.settings.list_template_id,
        listLinkAction: space.settings.list_link_action,
        listShowPreviewText: space.settings.list_show_preview_text,
        listShowThumbnail: space.settings.list_show_thumbnail,
        listShowComments: space.settings.list_show_comments_count,
        listShowMembers: space.settings.list_show_members,
        listShowTimestamp: space.settings.list_show_timestamp,
        listAutoThumbnail: space.settings.list_auto_thumbnail,
        listPrivacyControl: space.settings.list_privacy_control,
        listFilterBy: space.settings.list_filter_by,
        listFlatView: space.settings.list_flat_view,
        listAllowLikes: space.settings.list_allow_likes,
        spaceWidth: space.settings.space_width,
        openInModal: space.settings.list_open_in_modal,
        listTitleLineClamp: space.settings.list_title_line_clamp,
        listAbstractLineClamp: space.settings.list_abstract_line_clamp,
      });
    }
  }, [space]);

  useEffect(() => {
    onFinishedLoading();
  }, [spaces]);

  // useEffect(() => {
  //   if (reload.current == true) {
  //     paginator.page = 1;
  //     const ctrl = new AbortController();
  //     fetchSpaces(ctrl);
  //   }
  // }, [settings]);

  useEffect(() => {
    if (!tags) {
      return;
    }
    const tagsFilter = tags.length > 0 ? { tags: tags } : {};

    if (tagTimeout.current) {
      clearTimeout(tagTimeout.current);
    }

    tagTimeout.current = setTimeout(() => {
      tagFiltering.current = true;
      paginator.page = 1;
      noMore.current = false;
      const ctrl = new AbortController();
      fetchSpaces(ctrl, false, [], tagsFilter);
    }, 800);
  }, [tags]);

  const decompressGzip = (compressedData) => {
      
    try {
      const decompressed = pako.ungzip(compressedData.data, { to: "string" });
      return JSON.parse(decompressed);
    } catch (err) {
      console.error("Error during decompression:", err);
      return null;
    }
    
  };

  const fetchSpaces = (ctrl, check = false, oldData = [], filters = {}) => {
    if (paginator.page == 1 && !tagFiltering.current) {
      setLoading(true);
    } else {
      if (loadingMore || noMore.current) {
        return;
      }
      setLoadingMore(true);

      setTimeout(() => {
        setLoadingMore(false);
      }, 1500);

      // scrollToBottom();
    }
    tagFiltering.current = false;

    filters.topSlug = window.$fetchedTopSlug;
    filters.role = window.$currentRole;
    filters.page = paginator.page;
    filters.per = 50;

    SpaceNeoModel.onFetchChildren(
      params.slug,
      filters,
      (data) => {
        const space = { ...data.data.space };
        const _children = [...decompressGzip(data.data.children)];

        if (parseInt(data.page) == 1) {
          const newData = [..._children];
          if (filters == {}) {
            // if (!check || (check && newDataFromApi(newData, oldData))) {
            spacesArray.current = [...newData];
            setSpaces(newData);
            // }
            paginator.page = 2;
            onFinishedLoading();
          } else {
            spacesArray.current = [...newData];
            setSpaces(newData);
            paginator.page = 2;
            onFinishedLoading(false);
          }
        } else {
          if (_children.length > 0) {
            spacesArray.current = spacesArray.current.concat(_children);
            setSpaces([...spacesArray.current]);
            paginator.page = parseInt(data.page) + 1;
          }
          onFinishedLoading(filters == {});
        }

        if (_children.length == 0){
          noMore.current = true;
        }
      },
      (data) => {
        onFinishedLoading();
      },
      ctrl.signal
    );
  };

  const onFinishedLoading = (loadmore = true) => {
    // if (loading) {
    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        if ((paginator.page == 2 || paginator.page == 3) && loadmore) {
          // onScrollBottom();
        }
      }, 300);
    }, 300);
    // }
  };

  const scrollToBottom = () => {
    // setIsFirstLoad(false);
    if (viewport.current) {
      // viewport.current.style.scrollBehavior = "auto";
    }
    setTimeout(() => {
      if (viewport.current) {
        const top = viewport.current.scrollHeight + 2000;
        viewport.current.scrollTo({ top: top, behavior: "instant" });
        // viewport.current.style.scrollBehavior = "smooth";
      }
    }, 500);
  };

  const onScrollPositionChange = (pos) => {
    const div = viewport.current;
    // const div = document.getElementsByClassName("app-main-frame")[0]
    clearScrollTimeouts();

    if (pos.y + div.clientHeight >= div.scrollHeight - 200) {
      console.log("On bottom");
      const scrollTimeoutId = setTimeout(() => {
        console.log("On bottom timer");
        // At bottom
        onScrollBottom();
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else if (pos.y == 0) {
      const scrollTimeoutId = setTimeout(() => {
        // At Top
      }, 1000);
      scrollTimeoutIds.push(scrollTimeoutId);
    } else {
      // In between
    }
  };

  const clearScrollTimeouts = () => {
    for (let i = 0; i < scrollTimeoutIds.length; i++) {
      const id = scrollTimeoutIds[i];
      clearTimeout(id);
    }
  };

  const onScrollBottom = () => {
    if (!loading) {
      const tagsFilter = tags && tags.length > 0 ? { tags: tags } : {};
      const ctrl = new AbortController();
      fetchSpaces(ctrl, false, [], tagsFilter);
    }
  };

  const onThumbnailSave = (spaceId, url) => {
    const auxSpaces = [...spaces];
    for (let i = 0; i < auxSpaces.length; i++) {
      if (auxSpaces[i].slug == spaceId) {
        auxSpaces[i].update_count = auxSpaces[i].update_count
          ? auxSpaces[i].update_count + 1
          : 1;
        auxSpaces[i].gallery_feature = { url: url };

        setTimeout(() => {
          setSpaces(auxSpaces);
          spacesArray.current = [...auxSpaces];
        }, 100);

        return;
      }
    }
  };

  const onEditSave = (spaceId, name, abstract) => {
    const auxSpaces = [...spaces];
    for (let i = 0; i < auxSpaces.length; i++) {
      if (auxSpaces[i].slug == spaceId) {
        auxSpaces[i].update_count = auxSpaces[i].update_count
          ? auxSpaces[i].update_count + 1
          : 1;
        auxSpaces[i].name = name;
        auxSpaces[i].abstract = abstract;

        setTimeout(() => {
          setSpaces(auxSpaces);
          spacesArray.current = [...auxSpaces];
        }, 100);

        return;
      }
    }
  };

  const onSave = (spaceId, spaceLink, spaceName = null) => {
    // SpaceModel.onFetchSimple(
    //   spaceId,
    //   "simple",
    //   (data) => {
    //     const auxSpaces = [...spacesArray.current];
    //     for (let i = 0; i < auxSpaces.length; i++) {
    //       if (auxSpaces[i].slug == spaceId) {
    //         auxSpaces[i] = { ...data };
    //         auxSpaces[i].update_count = auxSpaces[i].update_count
    //           ? auxSpaces[i].update_count + 1
    //           : 1;
    //         setSpaces([]);
    //         setTimeout(() => {
    //           setSpaces(auxSpaces);
    //           spacesArray.current = [...auxSpaces];
    //         }, 100);
    //         return;
    //       }
    //     }
    //     auxSpaces.unshift({ ...data });
    //     setSpaces(auxSpaces);
    //     spacesArray.current = [...auxSpaces];
    //   },
    //   (data) => {}
    // );
  };

  const onUpdate = (spaceId, action) => {

    const auxSpaces = [...spacesArray.current];

    if (action == "pin" || action == "unpin") {
      for (let i = 0; i < auxSpaces.length; i++) {
        const space = auxSpaces[i];
        if (space.slug == spaceId) {
          if (action == "pin") {
            space.is_pinned.is_pinned = true;
            space.is_pinned.date = new Date();
          } else {
            space.is_pinned.is_pinned = false;
          }
          space.update_count = space.update_count ? space.update_count + 1 : 1;
          break;
        }
      }
      setSpaces(auxSpaces);
      spacesArray.current = [...auxSpaces];
    } else if (action == "delete") {
      const auxSpaces = [...spaces];
      console.log(`AUX SPACES: ${auxSpaces.length}`);
      for (let i = 0; i < auxSpaces.length; i++) {
        const space = auxSpaces[i];
        if (space.slug == spaceId) {
          console.log(`AUX SPACES: ${i}`);
          auxSpaces.splice(i, 1);
          break;
        }
      }
      setSpaces(auxSpaces);
      spacesArray.current = [...auxSpaces];
    } else if (action == "thumbnail-upload") {
      selectedSpace.current = spaceId;
      setThumbnailOpened(true);
    } else if (action == "thumbnail-delete") {
      selectedSpace.current = spaceId;
      onRemoveThumbnail(spaceId);
    } else if (action == "edit") {
      selectedSpace.current = spaceId;
      setEditOpened(spaceId);
    }

    // Broadcaster.send(
    //   "change_sidebarTreeNode",
    //   document.getElementById(`sidebar_tree_node-${space.slug}`),
    //   { data: { space: space, children: children, role: window.$currentRole } }
    // );

    
  };

  const onRemoveThumbnail = (spaceId) => {
    SpaceModel.onRemoveThumbnail(
      spaceId,
      (data) => {
        setTimeout(() => {
          onThumbnailSave(spaceId, null);
        }, 1000);
      },
      (data) => {}
    );
  };

  const onSettingsSave = (_settings) => {
    reload.current =
      settings.listFilterBy != _settings.listFilterBy;

    onFetchView();
    if (settings.spaceWidth != _settings.spaceWidth) {
    }

    // setSettings(_settings);
  };

  const onSetSettingsOpened = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    setSettingsOpened(value);
  };

  const onListActionTextChanged = (text) => {
    setListActionText(text);
  };

  const onListTemplateIdChanged = (id) => {
    setListTemplateId(id);
  };

  const pinned = () => {
    return spaces
      .filter((space) => space.is_pinned.is_pinned == true)
      .sort((a, b) => new Date(a.is_pinned.date) - new Date(b.is_pinned.date));
  };

  const unpinned = () => {
    return spaces.filter((space) => space.is_pinned.is_pinned == false);
  };

  const onFetchView = () => {
    ViewModel.onFetch(
      "description_view",
      params.slug,
      (data) => {
        contentEmpty.current = data.is_empty;
        console.log("window.$spaceWideWidth 1");
        window.$spaceWideWidth = data.view_width;
        setContent({ ...data.content });
      },
      (data) => {
        setContent({});
      }
    );
  };

  const onContentUpdate = (_content, html, callback, isEmpty) => {
    ViewModel.onUpdate(
      "description_view",
      params.slug,
      _content,
      (data) => {
        contentEmpty.current = data.is_empty;
        setContent({ ..._content });
      },
      (data) => {}
    );
  };

  const onJoin = () => {
    if (!window.$currentUserAuthToken) {
      localStorage.setItem("join_slug", params.slug);
      navigate(`/user/start?s=${params.slug}`);
      return;
    }
    SpaceModel.onJoin(
      params.slug,
      (data) => {
        navigate(`${data.redirect_to.url}`);
      },
      (data) => {}
    );
  };

  const onFetchTags = () => {
    if (space.tags) {
      setTagsList([...space.tags.filter(Boolean)]);
    }
  };

  const onNewPost = () => {
    if (space.slug && space.permissions.can_edit) {
      setNewSpaceOpened(true);
    } else {
      onJoin();
    }
  };

  const onOpenedDuplicate = (spaceId, spaceName) => {
    selectedSpace.current = { slug: spaceId, name: spaceName };
    setOpenedDuplicate(true);
  };

  const onDuplicate = (id, link) => {
    setOpenedDuplicate(false);
    onSave(id);
  };

  if (loading) {
    return <Loading type="content" />;
  }

  return (
    <Stack
      className={classes.spaceContainer}
      spacing={0}
      style={{ height: "100%" }}
    >
      <ScrollArea
        style={{ height: "100%" }}
        onScrollPositionChange={onScrollPositionChange}
        viewportRef={viewport}
      >
        <HeaderImage />
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={mainActions.editPage}
          components={[
            "breadcrumbs",
            "title",
            "calendar",
            "status",
            "interactions",
            "tag",
            "topActions"
          ]}
        />
        {mainActions.editPage && (
          <Container size={space.space_width == "wide" ? 960 : 801} mt={10}>
            <Box>
              <TiptapInline
                editable={true}
                content={contentEmpty.current ? "" : content["content"]}
                modals={true}
                exclude={["formatbar"]}
                onUpdate={onContentUpdate}
                placeholder={t("spaces.presentations.list.placeholder")}
                canToggleFormatbar={true}
              />
            </Box>
          </Container>
        )}
        {!mainActions.editPage && content && !contentEmpty.current && (
          <Container
            size={window.$spaceWideWidth == "wide" ? 960 : 801}
            mt={10}
          >
            <Box>
              <TiptapReadonly content={content} />
            </Box>
          </Container>
        )}
        {(settings.openPost ||
          (space.slug &&
            space.permissions &&
            space.permissions.can_manage)) && (
          <Container size={space.space_width == "wide" ? 960 : 801}>
            <Paper
              mt={15}
              mb={settings.listFlatView ? 0 : 0}
              onClick={() => onNewPost()}
              shadow="sm"
              radius="md"
              // withBorder
              pl={20}
              pr={25}
              pt={20}
              pb={20}
              sx={{
                cursor: "pointer",
                border: isSpaceThemed(colors)
                  ? `1px solid ${
                      isDark(colors.background_color)
                        ? tinycolor(colors.background_color)
                            .lighten(20)
                            .toString()
                        : tinycolor(colors.background_color)
                            .darken(20)
                            .toString()
                    }`
                  : `1px solid #e9ecef`,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Group
                  position="apart"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Group spacing={15}>
                    <Avatar
                      size={40}
                      src={window.$currentUserAvatar}
                      radius={35}
                    />
                    <Text size="xl" weight={700} color="grey">
                      {settings.listActionText
                        ? settings.listActionText
                        : t("spaces.presentations.list.createButton")}
                    </Text>
                  </Group>
                  {space &&
                    space.permissions &&
                    space.permissions.can_manage && (
                      <Tooltip
                        disabled={window.$isTouchDevice && window.$isTouchDevice()}
                        label={t(
                          "spaces.presentations.list.listSettingsTooltip"
                        )}
                        withArrow
                      >
                        <ActionIcon
                          size={20}
                          radius="xl"
                          onClick={(event) => onSetSettingsOpened(event, true)}
                        >
                          <FontAwesomeIcon size="lg" icon={solid("gear")} />
                        </ActionIcon>
                      </Tooltip>
                    )}
                </Group>
              </Box>
            </Paper>
          </Container>
        )}

        <Container size={space.space_width == "wide" ? 960 : 801}>
          {tagsList && tagsList.length > 0 && (

            <TagsChips tagsList={tagsList} setTags={setTags} selectedTag={tags} />
          )}
        </Container>

        {settings.visualType == "list" && (
          <Container
            size={space.space_width == "wide" ? 960 : 801}
            style={{ marginBottom: "50px" }}
          >
            {pinned().map((child) => {
              return (
                <Box
                  sx={(theme) => ({
                    margin: settings.listFlatView ? "0px" : "15px 0px",
                  })}
                >
                  <SpaceListItem
                    key={`space-list-item-${child.slug}`}
                    space={child}
                    settings={settings}
                    viewAs={"post"}
                    onUpdate={onUpdate}
                    showAuthor={settings.authors}
                    {...settings}
                    canPin={space.permissions.can_manage}
                    onDuplicate={onOpenedDuplicate}
                    openInModal={settings.openInModal}
                    lineClamp={[settings.listTitleLineClamp ? settings.listTitleLineClamp : 2, settings.listAbstractLineClamp ? settings.listAbstractLineClamp : 2]}
                  />
                  {settings.listFlatView && (
                    <Box className={classes.separator}></Box>
                  )}
                </Box>
              );
            })}
            {unpinned().map((child) => {
              return (
                <Box
                  sx={(theme) => ({
                    margin: settings.listFlatView ? "0px" : "15px 0px",
                  })}
                >
                  <SpaceListItem
                    key={`space-list-item-${child.slug}`}
                    space={child}
                    settings={settings}
                    viewAs={"post"}
                    onUpdate={onUpdate}
                    showAuthor={settings.authors}
                    {...settings}
                    canPin={space.permissions.can_manage}
                    onDuplicate={onOpenedDuplicate}
                    openInModal={settings.openInModal}
                    lineClamp={[settings.listTitleLineClamp ? settings.listTitleLineClamp : 2, settings.listAbstractLineClamp ? settings.listAbstractLineClamp : 2]}
                  />
                  {settings.listFlatView && (
                    <Box className={classes.separator}></Box>
                  )}
                </Box>
              );
            })}
          </Container>
        )}
        {settings.visualType == "grid" && (
          <Container
            size={space.space_width == "wide" ? 960 : 801}
            style={{ marginBottom: "50px" }}
          >
            <SimpleGrid
              cols={3}
              spacing={settings.listFlatView ? 30 : 20}
              mt={20}
              breakpoints={[
                { maxWidth: 980, cols: 3, spacing: 15 },
                { maxWidth: 755, cols: 2, spacing: 15 },
                { maxWidth: 600, cols: 1, spacing: 15 },
              ]}
            >
              {pinned().map((child) => {
                return (
                  <Box
                    sx={(theme) => ({
                      margin: "0px 0px",
                    })}
                  >
                    <SpaceListItem
                      key={`space-list-item-${child.slug}`}
                      space={child}
                      settings={settings}
                      viewAs={"grid"}
                      onUpdate={onUpdate}
                      showAuthor={settings.authors}
                      {...settings}
                      canPin={space.permissions.can_manage}
                      onDuplicate={onOpenedDuplicate}
                      openInModal={settings.openInModal}
                      lineClamp={[settings.listTitleLineClamp ? settings.listTitleLineClamp : 2, settings.listAbstractLineClamp ? settings.listAbstractLineClamp : 2]}
                    />
                  </Box>
                );
              })}
              {unpinned().map((child) => {
                return (
                  <Box
                    sx={(theme) => ({
                      margin: "0px 0px",
                    })}
                  >
                    <SpaceListItem
                      key={`space-list-item-${child.slug}`}
                      space={child}
                      settings={settings}
                      viewAs={"grid"}
                      onUpdate={onUpdate}
                      showAuthor={settings.authors}
                      {...settings}
                      canPin={space.permissions.can_manage}
                      onDuplicate={onOpenedDuplicate}
                      openInModal={settings.openInModal}
                      lineClamp={[settings.listTitleLineClamp ? settings.listTitleLineClamp : 2, settings.listAbstractLineClamp ? settings.listAbstractLineClamp : 2]}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          </Container>
        )}
        {loadingMore && (
          <Center mb={40}>
            <Loader />
          </Center>
        )}
        <NewSpace
          opened={newSpaceOpened}
          setOpened={setNewSpaceOpened}
          spaceType={"content"}
          onSave={onSave}
          content={settings.listChildVersion != "content"}
          noAdvance={true}
          linkOnly={settings.listChildVersion == "link"}
          duplicateFrom={
            settings.listTemplateId != "create" ? settings.listTemplateId : null
          }
          actionButtonText={
            settings.listActionText
              ? settings.listActionText
              : t("spaces.presentations.list.createButton")
          }
          privacyControl={
            settings.listPrivacyControl ? settings.listPrivacyControl : "open"
          }
        />
        <Drawer
          opened={openedDuplicate}
          onClose={() => setOpenedDuplicate(false)}
          title={
            <Text size="xs">
              {t("spaces.presentations.list.duplicateFormTitle")}
            </Text>
          }
          padding="xl"
          size="xl"
        >
          <DuplicateForm
            setOpened={setOpenedDuplicate}
            onDuplicate={onDuplicate}
            spaceId={selectedSpace.current ? selectedSpace.current.slug : ""}
            spaceName={selectedSpace.current ? selectedSpace.current.name : ""}
          />
        </Drawer>

        {space && space.permissions && space.permissions.can_manage && (
          <Settings
            opened={settingsOpened}
            setOpened={setSettingsOpened}
            onSave={onSettingsSave}
            spaceId={params.slug}
            onListActionTextChanged={onListActionTextChanged}
            onListTemplateIdChanged={onListTemplateIdChanged}
            parentVersion="list"
            onFetchTags={onFetchTags}
          />
        )}
        <Thumbnail
          opened={thumbnailOpened}
          setOpened={setThumbnailOpened}
          onSave={onThumbnailSave}
          spaceId={selectedSpace.current}
        />

        <Edit
          opened={editOpened}
          setOpened={setEditOpened}
          onSave={onEditSave}
          spaceId={selectedSpace.current}
        />
        <TiptapChannel onActionCallback={onSave} />
      </ScrollArea>
    </Stack>
  );
}
