import React, { useState, useEffect } from "react";

import { Text, Badge, Group, Menu, Tooltip, Stack } from "@mantine/core";

import { useDispatch } from "react-redux";
import useFrequencyOptions from "../../../../collections/FrequencyOptions";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import StripeModel from "../../../../models/Stripe";
import { colors } from "../../../../collections/colors";

import Avatar from "../../../utils/LazyAvatar";

import getSymbolFromCurrency from "currency-symbol-map";

import Swal from "sweetalert2";

import { setTiptapSidemenu } from "../../../../store/app";

const statusColors = {
  paid: "green",
  refunded: "red",
  failed: "yellow",
  pending: "yellow",
};

const statusString = {
  paid: "Paid",
  refunded: "Refunded",
  failed: "Failed",
  pending: "Pending",
};

const statusMessage = {
  paid: "This payment is complete",
  refunded: "This payment has been refunded",
  failed: "This payment has not been processed",
  pending: "This payment has not been processed",
};

export default function PaymentRow(props) {
  
  const dispatch = useDispatch();

  const [payment, setPayment] = useState({ user: { avatar: {} } });
  const { frequencyOptions } = useFrequencyOptions();
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    if (!payment.id || payment.id != props.payment.id) {
      setPayment(props.payment);
    }
  }, [props]);

  const onRefund = () => {
    Swal.fire({
      title: "Are you sure?",
      html: "You are about to refund the full amount of this payment",
      showDenyButton: true,
      confirmButtonText: "Yes, refund",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        StripeModel.onRefund(
          props.spaceId,
          payment.id,
          (data) => {
            Swal.fire(data.title, data.message, "success");
          },
          (data) => {
            Swal.fire(data.title, data.message, "error");
          }
        );
      } else if (result.isDenied) {
      }
    });
  };

  const openProfile = () => {
    dispatch(setTiptapSidemenu({ opened: "profile", option: payment.user.username}));
  }

  if (!payment || !payment.user) {
    return null;
  }

  return (
    <tr>
      <td>
        <Group
          spacing="sm"
          onClick={() => openProfile()}
          sx={{
            color: "#000",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          noWrap
        >
          <Avatar size={30} src={payment.user.avatar.url} radius={30} />
          <Stack spacing="0">
            <Text size="sm" weight={500}>
              {payment.user.full_name}
            </Text>
            <Text size="xs" color="dimmed" mt={-3}>
              @{payment.user.username}
            </Text>
          </Stack>
        </Group>
      </td>

      <td>
        {getSymbolFromCurrency(payment.currency)}
        {payment.formatted_amount}
      </td>

      <td>{new Date(payment.created_at).toLocaleString(DateTime.DATE_MED)}</td>

      <td>
        <Tooltip
          disabled={window.$isTouchDevice && window.$isTouchDevice()}
          wrapLines
          width={220}
          label={statusMessage[payment.status]}
          withArrow
        >
          <Badge color={statusColors[payment.status]} variant={"light"}>
            {statusString[payment.status]}
          </Badge>
        </Tooltip>
      </td>

      <td>
        {payment.status == "paid" ? (
          <Group spacing="sm" position="right">
            <Menu transition="pop" withArrow placement="end">
              <Menu.Item
                icon={<FontAwesomeIcon icon={regular("credit-card")} />}
                color="red"
                onClick={() => onRefund()}
              >
                {t("spaces.settings.members.paymentRow.payment.refundPayment")}
              </Menu.Item>
            </Menu>
          </Group>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}
