import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";

import { createStyles, Text } from "@mantine/core";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  settingsNav: {
    paddingTop: 20,
    height: `calc(100vh - ${theme.other.headerHeight}px)`,
  },
  link: {
    color: "#fff",
    display: "block",
    padding: "10px 25px",
    marginLeft: "15px",
    textDecoration: "none",
    fontSize: theme.fontSizes.lg,
    fontWeight: 500,
    borderTopLeftRadius: theme.radius.xl,
    borderBottomLeftRadius: theme.radius.xl,

    "&:hover": {
      color: "#fff",
      backgroundColor: theme.colors.gray[6],
      textDecoration: "none",
    },
  },
  active: {
    "&, &:hover": {
      color: "#000",
      backgroundColor: "#fff",
    },
  },
}));

export default function NavbarContent() {
  const { t, i18n } = useTranslation();
  const { classes, cx } = useStyles();
  const space = useSelector((state) => state.space);
  const user = useSelector((state) => state.user);
  const location = useLocation();
  const [current, setCurrent] = useState("main");

  const settingsLinks = [
    {
      value: "main",
      label: t("spaces.settings.layout.navbarContent.mainSettings"),
      to: `/space/${space.slug}/settings/main`,
    },
    // {
    //   value: "additional",
    //   label: t('spaces.settings.layout.navbarContent.additionalSettings'),
    //   to: `/space/${space.slug}/settings/additional`
    // },
    {
      value: "members",
      label: t("spaces.settings.layout.navbarContent.members"),
      to: `/space/${space.slug}/settings/members`,
    },
    {
      value: "broadcast",
      label: t("spaces.settings.layout.navbarContent.broadcast"),
      to: `/space/${space.slug}/settings/broadcast`,
    },
    {
      value: "surveys",
      label: t("spaces.settings.layout.navbarContent.surveys"),
      to: `/space/${space.slug}/settings/surveys`,
    },
    {
      value: "structures",
      label: t("spaces.settings.layout.navbarContent.structures"),
      to: `/space/${space.slug}/settings/structures`,
    },
    {
      value: "admin",
      label: t("spaces.settings.layout.navbarContent.sutraAdmin"),
      to: `/space/${space.slug}/settings/admin`,
    },
    {
      value: "scheduled",
      label: t("spaces.settings.layout.navbarContent.scheduled"),
      to: `/space/${space.slug}/settings/scheduled`,
    },
    // {
    //   value: "archived",
    //   label: t("spaces.settings.layout.navbarContent.archived"),
    //   to: `/space/${space.slug}/settings/archived`,
    // },
    {
      value: "participation",
      label: t("spaces.settings.layout.navbarContent.participation"),
      to: `/space/${space.slug}/settings/participation`,
    },
  ];

  useEffect(() => {
    setCurrent(location.pathname.split("/").pop());
  }, [location]);

  return (
    <div className={classes.settingsNav}>
      {settingsLinks.map(
        (item) =>
          user.settings_tabs && user.settings_tabs.indexOf(item.value) >= 0 && (
            <Text
              key={item.value}
              component={Link}
              className={cx(classes.link, {
                [classes.active]: current == item.value,
              })}
              to={item.to}
            >
              {item.label}
            </Text>
          )
      )}
    </div>
  );
}
