import React, { useState, useEffect, useRef, memo } from "react";

import { useSelector } from "react-redux";

import {
  Text,
  Box,
  TextInput,
  Select,
  Divider,
  useMantineTheme,
  Stack,
  Switch,
  Loader,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

import { useForm } from "@mantine/form";

import { useListState } from "@mantine/hooks";

import TiptapInline from "../../../tiptap/components/TiptapInline";

import SpaceModel from "../../../../models/Space";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import PremiumModal from "../../../app/PremiumModal";

const CreateSurveyMain = memo((props) => {
  const space = useSelector((state) => state.space);
  const [scheduled, setScheduled] = useState(false);

  const theme = useMantineTheme();

  // const [questions, setQuestions] = useState([])
  const [surveyId, setSurveyId] = useState(null);
  const { t, i18n } = useTranslation();
  const [questions, handlers] = useListState([]);
  const [currency, setCurrency] = useState(null);

  const [opened, setOpened] = useState(false);
  const [canCreateQuizzes, setCanCreateQuizzes] = useState([false]);
  const [loading, setLoading] = useState(false);
  const [quizMode, setQuizMode] = useState(false);
  const [allowRetake, setAllowRetake] = useState(false);
  const [showScoreAndKey, setShowScoreAndKey] = useState(false);
  const [showRedirect, setShowRedirect] = useState(false);
  const [children, setChildren] = useState([]);
  const [searching2, setSearching2] = useState([]);

  const searchTimeoutId = useRef();
  const selectedSpace = useRef();
  const dataLoaded = useRef();

  const form = useForm({
    initialValues: {
      title: "",
      description: "",
      content: "",
      quiz_mode: false,
      allow_retake: false,
      after_submit_show_score_and_keys: false,
      redirect_to_space: "",
    },

    validate: {
      title: (value) => (value.length > 0 ? null : "Write a title"),
    },
  });

  useEffect(() => {
    setCanCreateQuizzes(
      space && space.permissions && space.permissions.can_create_quizzes
    );
  }, [space]);

  useEffect(() => {
    if (props.survey && props.survey.id && props.count == 1) {
      setLoading(true);
      form.setFieldValue("title", props.survey.title);
      form.setFieldValue("description", props.survey.description);
      form.setFieldValue("content", props.survey.content);
      setQuizMode(props.survey.quiz_mode);
      setAllowRetake(props.survey.allow_retake);
      setShowScoreAndKey(props.survey.after_submit_show_score_and_keys);
      setShowRedirect(props.survey.redirect_to_space ? true : false);
      form.setFieldValue("redirect_to_space", props.survey.redirect_to_space);
      setSurveyId(props.survey.id);
      selectedSpace.current = props.survey.redirect_to_space_obj;
    } else {
      if (!props.survey && props.count == 1) {
        form.setFieldValue("title", "");
        form.setFieldValue("description", "");
        form.setFieldValue("content", "");
        form.setFieldValue("quiz_mode", false);
        setQuizMode(false);
        setAllowRetake(false);
        setShowScoreAndKey(false);
        setShowRedirect(false);
        setSurveyId(null);
        setCurrency(null);
      }
    }
  }, [props]);

  useEffect(() => {
    props.onUpdateForm(form.values);
    props.onUpdateFormFull();
  }, [form.values]);

  useEffect(() => {
    if (!canCreateQuizzes[0] && quizMode){
      setOpened([true, canCreateQuizzes[1], canCreateQuizzes[2]])
      form.setFieldValue("quiz_mode", false);
      setQuizMode(false)
    }
    else{
      form.setFieldValue("quiz_mode", quizMode);
    }
    form.setFieldValue("after_submit_show_score_and_keys", quizMode && showScoreAndKey);
    form.setFieldValue("allow_retake", allowRetake);

    if (!showRedirect && dataLoaded.current){
      form.setFieldValue("redirect_to_space", "");
    }

    setTimeout(() => {
      dataLoaded.current = true
      props.onUpdateFormFull();
    }, 500)
  }, [quizMode, allowRetake, showScoreAndKey, showRedirect]);

  const onContentUpdate = (content, html, callback, isEmpty) => {
    form.setFieldValue("content", content.content);
    form.setFieldValue("description", html);
  };

  const onSearchChildren = (query) => {
    setSearching2(true);
    if (searchTimeoutId.current) {
      clearTimeout(searchTimeoutId.current);
    }
    searchTimeoutId.current = setTimeout(() => {
      onFetchChildren(query);
      setSearching2(false);
    }, 1000);
  };

  const onFetchChildren = (query = null) => {
    const ctrl = new AbortController();
    SpaceModel.onFetchDirectChildren(
      props.spaceId,
      ["order=pods.created_at DESC", "abstract"],
      { page: 1, per: 10 },
      ["active"],
      (data) => {
        const _spaces = selectedSpace.current ? [selectedSpace.current] : [];
        for (let i = 0; i < data.data.length; i++) {
          _spaces.push({
            value: data.data[i].slug,
            label: `${data.data[i].name} (${data.data[i].slug})`,
          });
        }
        setChildren(_spaces);
      },
      (data) => {},
      ctrl.signal
    );
  };

  return (
    <>
    <form onSubmit={form.onSubmit((values) => onSend(values))}>
      {!props.acceptPayments && (
        <TextInput
          {...form.getInputProps("title")}
          radius="sm"
          size="md"
          placeholder={t("spaces.settings.survey.createSurveyForm.form.title")}
          styles={{
            input: {
              // fontWeight: 700
            },
          }}
        />
      )}
      {props.acceptPayments && (
        <Text color="dimmed" mb={20}>
          {t("spaces.settings.survey.createSurveyForm.form.description")}
        </Text>
      )}

      <Box mt={20}>
        <TiptapInline
          editable={true}
          content={props.survey ? props.survey.content : ""}
          modals={true}
          onUpdate={onContentUpdate}
          exclude={["link-action"]}
          placeholder={t(
            "spaces.settings.survey.createSurveyForm.form.optionalDescription"
          )}
        />
      </Box>
      {!props.acceptPayments && (
        <>
          <Stack mt={20}>
            <Switch
              mt={0}
              mb={10}
              checked={quizMode}
              onChange={(event) => setQuizMode(event.currentTarget.checked)}
              label="Enable quiz mode"
            />
          </Stack>
          {quizMode && (
            <Stack>
              <Switch
                mt={0}
                mb={10}
                checked={showScoreAndKey}
                onChange={(event) =>
                  setShowScoreAndKey(event.currentTarget.checked)
                }
                label="After submit show score and answer key"
              />
            </Stack>
          )}

          <Stack>
            <Switch
              mt={0}
              mb={10}
              checked={allowRetake}
              onChange={(event) => setAllowRetake(event.currentTarget.checked)}
              label="Allow retake"
            />
          </Stack>

          <Stack>
            <Switch
              mt={0}
              mb={10}
              checked={showRedirect}
              onChange={(event) => setShowRedirect(event.currentTarget.checked)}
              label="Redirect to space on submit"
            />
          </Stack>
          {showRedirect && (
          <Stack>
            <Select
              zIndex={99999}
              icon={
                searching2 ? (
                  <Loader size="xs" />
                ) : (
                  <FontAwesomeIcon icon={solid("search")} />
                )
              }
              searchable
              clearable
              limit={100}
              mt={5}
              data={children}
              {...form.getInputProps("redirect_to_space")}
              label={"Select a space to redirect on submit"}
              placeholder={t(
                "spaces.settings.main.additional.parentCircles.placeholder"
              )}
              onSearchChange={(query) => onSearchChildren(query)}
            />
          </Stack>
          )}
        </>
      )}

      <Divider
        mt={15}
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      />
    </form>
    <PremiumModal opened={opened} setOpened={setOpened} />
    </>
  );
})

export default CreateSurveyMain
