import React from 'react';
import {
  Text,
  createStyles
} from '@mantine/core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const useStyles = createStyles((theme) => ({

  symbol: {
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 700,
    width: 60,
  }

}));

export default function StepIcon(props) {

  const { classes, cx, theme } = useStyles();

  const displayStepIcon = () => {
    const value = props.icon

    return (
      <Text
        className={classes.symbol}
        sx={(theme)=>({color: theme.colors.blue[5]})}
      >
        {value == "email" && (
          <FontAwesomeIcon icon={solid('envelope')} />
        )}
        {value == "push" && (
          <FontAwesomeIcon icon={solid('bell')} />
        )}
      </Text>
    )
  }

  return (
    <div>
      {displayStepIcon()}
    </div>
  )
}
