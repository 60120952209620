import React, {useRef, useState, useEffect} from "react";
import { Text, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Stack,
  Box,
  ActionIcon,
  NumberInput,
  createStyles,
} from "@mantine/core";

import { Plus, Minus, ListDetails } from "tabler-icons-react";

import SpaceSettingModel from "../../../../models/SpaceSetting";

const useStyles = createStyles((theme) => ({
  item: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  itemTitle: {
    fontSize: 18,
  },

  itemDescription: {
    // fontSize: 14,
    color: theme.colors.gray[6],
  },

  switch: {
    "& *": {
      cursor: "pointer",
    },
  },

  subOptionWrap: {
    marginTop: 15,
    backgroundColor: theme.colors.gray[0],
    // padding: 15,
    borderRadius: 5,
  },

  subOptionItem: {
    paddingBottom: 16,
    marginBottom: 18,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
  },

  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `6px ${theme.spacing.xs}px`,
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.white,

    "&:focus-within": {
      borderColor: theme.colors[theme.primaryColor][6],
    },
  },

  control: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    border: `1px solid ${
      theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3]
    }`,

    "&:disabled": {
      borderColor:
        theme.colorScheme === "dark" ? "transparent" : theme.colors.gray[3],
      opacity: 0.8,
      backgroundColor: "transparent",
    },
  },

  input: {
    textAlign: "center",
    paddingRight: `${theme.spacing.sm}px !important`,
    paddingLeft: `${theme.spacing.sm}px !important`,
    height: 28,
    flex: 1,
  },
}));

export default function FreeTrial(props) {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const handlers = useRef();
  const propsLoaded = useRef(false);
  const [freeTrial, setFreeTrial] = useState(0)

  const space = useSelector((state) => state.space);

  useEffect(() => {
    if (!space.settings) {
      return;
    }

    propsLoaded.current = false;

    setFreeTrial(space.payment_settings.trial_period);

    setTimeout(() => {
      propsLoaded.current = true;
    }, 1000);
  }, [space]);

  useEffect(() => {
    if (propsLoaded.current) {
      onUpdate({ pod_payment_settings_attributes: { trial_period: freeTrial } });
    }
  }, [freeTrial]);

  const onUpdate = (params) => {
    if (Object.keys(params).length === 0){
      return
    }
    SpaceSettingModel.onUpdate(
      space.slug,
      params,
      (data) => {},
      (data) => {}
    );
  };

  return (
    <Box className={classes.subOptionWrap}>
      <Text className={classes.itemDescription} mb={10} >
        {"Add free trial days (optional)"}
      </Text>
      <div className={classes.wrapper}>
        <ActionIcon
          size={28}
          variant="transparent"
          onClick={() => handlers.current.decrement()}
          disabled={freeTrial === 0}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <Minus size={16} />
        </ActionIcon>

        <NumberInput
          variant="unstyled"
          min={0}
          max={365}
          handlersRef={handlers}
          value={freeTrial}
          onChange={setFreeTrial}
          classNames={{ input: classes.input }}
        />

        <ActionIcon
          size={28}
          variant="transparent"
          onClick={() => handlers.current.increment()}
          disabled={freeTrial === 365}
          className={classes.control}
          onMouseDown={(event) => event.preventDefault()}
        >
          <Plus size={16} />
        </ActionIcon>
      </div>
    </Box>
  );
}
