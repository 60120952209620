import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import { setTiptapSidemenu } from "../../../store/app";

import { onDragStart } from "../plugins/EventHandler";

import {
  getSimpleStructure,
  isEmptySelection,
  isDocumentEmpty,
  isTopNode,
  isMultilineSelection,
  updateInteractionsPositions,
  closestInteraction,
  isInDepth
} from "../utils/selection";

import { isTextSelection } from "@tiptap/core";

import SpacesIcon from "../../../src/images/buttons/spacesIcon";
import PremiumModal from "../../app/PremiumModal";
import DuplicateForm from "../../spaces_neo/form/DuplicateForm";

import { useTranslation } from "react-i18next";
import data from "@emoji-mart/data";

import Picker from "@emoji-mart/react";

import Swal from "sweetalert2";

import { colors } from "../../../collections/colors";
import SpaceModel from "../../../models/Space";
import MediaFileModel from "../../../models/MediaFile";

import {
  Paper,
  Group,
  ActionIcon,
  Tooltip,
  Text,
  Box,
  createStyles,
  Popover,
  Menu,
  Drawer,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  newLineButtonStyle: {
    // color: theme.colors.gray[5]
  },
}));

const BlockMenu = (props) => {
  const { t, i18n } = useTranslation();
  const { classes, cx, theme } = useStyles();
  const tiptap = useSelector((state) => state.tiptap);
  const space = useSelector((state) => state.space);
  const colors2 = useSelector((state) => state.colors);

  const [openedEmojis, setOpenedEmojis] = useState(false);
  const [openedDuplicate, setOpenedDuplicate] = useState(false);
  const [validatePremium2, setValidatePremium2] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const rightClicked = useRef();

  const spaceNodeId = props?.editor?.view?.state?.selection?.node?.attrs?.id;
  const spaceNodeName =
    props?.editor?.view?.state?.selection?.node?.attrs?.buttonText;

  useEffect(() => {
    setValidatePremium2(
      space && space.permissions && space.permissions.can_duplicate_spaces
    );
  }, [space]);

  const openBlocksSideMenu = (option = null) => {
    const { from, to, $anchor } = props.editor.view.state.selection;

    const isEmptyTextBlock =
      !props.editor.view.state.doc.textBetween(from, to).length &&
      isTextSelection(props.editor.view.state.selection);
    const onlyTextNodes = !props.excludedFound;

    if (
      ($anchor.parent && $anchor.parent.textContent != "") ||
      props.excludedFound
    ) {
      // props.editor.chain().focus().setTextSelection({ from: to, to: to }).enter().run()
      props.editor.commands.addNewEmptyLine();
      window.$tiptapLastSelectionRange = { from: to + 1, to: to + 1 };

      // props.editor.chain().focus().setTextSelection({ from: to+1, to: to+1 }).run()
    } else {
      window.$tiptapLastSelectionRange = { from: to, to: to };
    }
    if (option == "Templates" || option == "Layouts") {
      dispatch(setTiptapSidemenu({ opened: "templatesModal", option: option }));
    } else {
      dispatch(setTiptapSidemenu({ opened: "blocks", option: option }));
    }
  };

  const onEmojiSelect = (emoji) => {
    props.editor.chain().focus().setEmoji(emoji.id).run();
  };

  const openConfigurationSideMenu = () => {
    const { from, to } = props.editor.view.state.selection;
    window.$tiptapLastSelectionRange = { from, to };
    dispatch(setTiptapSidemenu({ opened: "configuration" }));
  };

  const onButtonDragStart = (e) => {
    const { from, to } = props.editor.view.state.selection;
    window.$tiptapLastSelectionRange = { from, to };

    let node = props.editor.view.state.selection.node;

    if (node) {
      const ghost = document.getElementById(node.attrs.uid);

      if (ghost) {
        e.dataTransfer.setDragImage(ghost, 250, 20);
      }
    }

    setTimeout(() => {
      const slice = onDragStart(e, window.$tiptapAchorPos, props.editor);
      const nodes = getSimpleStructure(props.editor, slice);

      // - If 1 node check if only 1 circle
      // - - Perform current logic
      // - If 1 node and NOT a circle || Multiple nodes
      // - - Perform backend "cut and paste" logic

      window.$draggingNode = {
        nodes: nodes,
        parent: "current",
        outsider: true,
        selection_type: "multiple",
      };

      // if (nodes.length == 1){
      //   if (isSingleCircleNode(props.editor, slice)){
      //     const nodeLeaf = getLeaf(editor, slice)
      //     draggingNode.nodes.push(nodeLeaf.leaf)
      //     draggingNode.type = "circle"
      //   }
      //   else{
      //     draggingNode.nodes.push(nodeLeaf.leaf.attrs.id)
      //     draggingNode.type = "circle"
      //   }
      // }

      // dispatch(setSidebarDragging(draggingNode));
    }, 200);
  };

  const onButtonDragClick = (e) => {
    // onDragClick(e,  props.editor.view)
  };

  const onDeleteNode = () => {
    const { $anchor, node } = props.editor.view.state.selection;

    if (
      (node && node.type.name == "interactiveInput") ||
      $anchor.parent.type.name == "interactiveInput"
    ) {
      Swal.fire({
        title: "Are you sure?",
        html: "Deleting a discussion prompt will delete all associated user responses",
        showDenyButton: true,
        confirmButtonText: "Yes, delete",
        denyButtonText: "No",
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          if (node) {
            props.editor.commands.deleteSelection();
          } else {
            props.editor.commands.deleteNode($anchor.parent.type.name);
          }
          setTimeout(() => {
            updateInteractionsPositions(props.editor);
          }, 500);
        }
      });

      return;
    }

    const dontDelete = [
      "grid1",
      "grid2",
      "grid3",
      "grid4",
      "column",
      "resource",
      "circle",
      "survey",
      "interactiveInput",
      "interactiveButton",
      "interactiveWait",
      "tagsFeed",
      "infobox",
      "orderedList",
      "bulletList",
      "listItem",
      "video",
    ];

    if (node && dontDelete.indexOf(node.type.name) >= 0) {
      Swal.fire({
        title: "Are you sure?",
        html: "You are about to delete a content block",
        showDenyButton: true,
        confirmButtonText: "Yes, delete",
        denyButtonText: "No",
        confirmButtonColor: colors.red,
        denyButtonColor: colors.gray,
      }).then((result) => {
        if (result.isConfirmed) {
          if (node) {
            props.editor.commands.deleteSelection();
          } else {
            props.editor.commands.deleteNode($anchor.parent.type.name);
          }
          if (
            (node && node.type.name.indexOf("interactive") >= 0) ||
            $anchor.parent.type.name.indexOf("interactive") >= 0
          ) {
            setTimeout(() => {
              updateInteractionsPositions(props.editor);
            }, 500);
          }
        }
      });

      return;
    }

    if (node) {
      props.editor.commands.deleteSelection();
    } else {
      props.editor.commands.deleteNode($anchor.parent.type.name);
    }
    if (
      (node && node.type.name.indexOf("interactive") >= 0) ||
      $anchor.parent.type.name.indexOf("interactive") >= 0
    ) {
      setTimeout(() => {
        updateInteractionsPositions(props.editor);
      }, 500);
    }
  };

  const onButtonDuplicate = () => {
    if (!props.editor) return;

    const { $anchor } = props.editor.state.selection;

    const path = $anchor.path;
    const lastChild =
      path.length > 5 ? path[path.length - 3] : $anchor.nodeAfter;
    const prevChild = path.length > 8 ? path[path.length - 6] : null;
    const isListItem = prevChild?.type?.name === "listItem";
    const isColumn = lastChild?.type?.name === "column";
    const parentNode = isListItem
      ? prevChild
      : isColumn
      ? lastChild.content.content[$anchor.parentOffset]
      : lastChild;

    if (!parentNode) return;

    const insertPos =
      isColumn || path.length < 6 ? $anchor.pos : $anchor.after($anchor.depth);
    const copy = parentNode.copy(parentNode.content);

    const transaction = props.editor.state.tr.insert(insertPos, copy);

    props.editor.view.dispatch(transaction);
  };

  const onDuplicateSpace = () => {
    try {
      if (!validatePremium2[0]) {
        setOpenedPremium([true, validatePremium2[1], validatePremium2[2]]);
        return;
      }
    } catch {}
    setOpenedDuplicate(true);
  };

  const onDuplicate = (id, link) => {
    setOpenedDuplicate(false);
    navigate(link);
  };

  const goToSpace = () => {
    const node = props.editor.view.state.selection.node;
    navigate(`/space/${node.attrs.id}`);
  };

  const insertCircle = () => {
    if (window.location.pathname.indexOf("/registration") >= 0) {
      return;
    }
    AddLineBelowSelection();

    dispatch(setTiptapSidemenu({ opened: "newSpace", option: "content" }));
  };

  const insertIj = () => {
    if (window.location.pathname.indexOf("/registration") >= 0) {
      return;
    }
    AddLineBelowSelection();

    dispatch(
      setTiptapSidemenu({
        opened: "loading",
        text: "Discussion prompt is loading, please wait…",
      })
    );

    const spaceName =
      space.name < 90
        ? `${space.name} prompt`
        : `${space.name.slice(0, 90)}... prompt`;

    SpaceModel.onCreate(
      { intention: spaceName, version: "interactive-discussion" },
      space.id,
      (data) => {
        const interaction = closestInteraction(
          tiptap.editor,
          tiptap.editor.view.state.selection.to
        );

        const nextPos = interaction ? interaction.interactivePosition + 1 : 0;

        tiptap.editor
          .chain()
          .addNewEmptyLine()
          .insertContent(
            `<interactiveinput interactiveInputLabel="How do you feel?" interactiveInputPlaceholder="Write something..." interactivePosition="${nextPos}" buttonText="Send" buttonBackgroundColor="${colors2.primary_button_background_color}" buttonBorderColor="${colors2.primary_button_background_color}" buttonTextColor="${colors2.primary_button_text_color}" id="${data.slug}" circle_id="${space.slug}" radius="circle-radius"  ></interactiveinput><p></p>`
          )
          .run();

        dispatch(setTiptapSidemenu({ opened: null }));

        setTimeout(() => {
          updateInteractionsPositions(tiptap.editor);
        }, 500);

        if (!window.$spaceUserInteractions) {
          window.$spaceUserInteractions = {
            position: 9999,
            can_manage: true,
            count: 1,
          };
        }
        setTimeout(() => {
          onTranscriptsTrigger();
        }, 3000);
      },
      (data) => {
        dispatch(setTiptapSidemenu({ opened: null }));
        if (data.permissions) {
          setOpenedPremium([true, data.permissions[1], data.permissions[2]]);
        }
      }
    );
    return;
  };

  const onTranscriptsTrigger = () => {
    const doc = tiptap.editor.view.state.doc;

    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (node.type.name == "embed") {
        const mediaFile = {
          original_url: node.attrs.src,
          url: node.attrs.src,
          upload_type: "embed",
          slug: window.$currentSlug,
          embed_id: node.attrs.videoId,
          apify: true,
        };

        MediaFileModel.onCreate(
          mediaFile,
          (data) => {
            console.log("MEDIA FILE STORED");
          },
          (data) => {}
        );
      }
    });
  };

  const AddLineBelowSelection = () => {
    const { from, to, $anchor } = props.editor.view.state.selection;

    if (!isEmptySelection(props.editor)) {
      props.editor.commands.addNewEmptyLine();
      window.$tiptapLastSelectionRange = { from: to + 1, to: to + 1 };

      // tiptap.editor.chain().focus().setTextSelection({ from: to+1, to: to+1 }).run()
    } else {
      window.$tiptapLastSelectionRange = { from: to, to: to };
    }
  };

  const openDirectMedia = (option) => {
    const { from, to, $anchor } = props.editor.view.state.selection;
    window.$tiptapLastSelectionRange = { from: to, to: to };
    dispatch(setTiptapSidemenu({ opened: "newMedia", option: option }));
  };

  const editMedia = (option) => {
    const { from, to, $anchor } = props.editor.view.state.selection;
    window.$tiptapLastSelectionRange = { from: to, to: to };
    dispatch(
      setTiptapSidemenu({
        opened: "newMedia",
        option: option,
        action: "update",
      })
    );
  };

  const getAlignment = () => {
    if (props.editor.isActive({ textAlign: "left" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    } else if (props.editor.isActive({ textAlign: "center" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-center")} />;
    } else if (props.editor.isActive({ textAlign: "right" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-right")} />;
    } else {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    }
  };

  const basicBlockOptions = () => {
    return (
      <Group spacing={0} sx={{ padding: 5 }}>
        {!props.isMultiple && !props.isEmpty && !window.$spaceIsPost && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label1")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon onClick={() => openBlocksSideMenu()}>
              <FontAwesomeIcon icon={solid("plus")} />
            </ActionIcon>
          </Tooltip>
        )}
        {!props.excludedFound && !props.isMultiple && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label2")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <Popover
              opened={openedEmojis}
              onClose={() => setOpenedEmojis(false)}
              target={
                <ActionIcon
                  className={classes.newLineButtonStyle}
                  onClick={() => setOpenedEmojis(true)}
                >
                  <FontAwesomeIcon icon={solid("face-grin-beam")} />
                </ActionIcon>
              }
              position="bottom"
              withArrow
              width={352}
              styles={{
                popover: {
                  order: "none",
                  backgroundColor: "rgba(255,255,255,0)",
                },
                wrapper: {
                  order: "none",
                  backgroundColor: "rgba(255,255,255,0)",
                },
                inner: {
                  padding: 0,
                  border: "none",
                  backgroundColor: "rgba(255,255,255,0)",
                },
                body: {
                  border: "none",
                  backgroundColor: "rgba(255,255,255,0)",
                },
              }}
            >
              <Picker data={data} onEmojiSelect={onEmojiSelect} />
            </Popover>
          </Tooltip>
        )}
        {!props.isMultiple && !window.$spaceIsPost && (!props.isEmpty || isInDepth(props.editor)) && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label3")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon onClick={() => openConfigurationSideMenu()}>
              <FontAwesomeIcon icon={solid("sliders")} />
            </ActionIcon>
          </Tooltip>
        )}
        {!props.isMultiple && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label4")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon onClick={() => onDeleteNode()}>
              <FontAwesomeIcon icon={solid("trash")} />
            </ActionIcon>
          </Tooltip>
        )}
        {!props.isInteractive && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label5")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon
              onClick={(event) => onButtonDragClick(event)}
              onDragStart={(event) => onButtonDragStart(event)}
              draggable={true}
            >
              <FontAwesomeIcon icon={solid("arrows-up-down-left-right")} />
            </ActionIcon>
          </Tooltip>
        )}
        {!props.isInteractive && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label18")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon
              onClick={(event) => {
                if (props.isASpace) {
                  onDuplicateSpace();
                } else {
                  onButtonDuplicate(event);
                }
              }}
            >
              <FontAwesomeIcon icon={"clone"} />
            </ActionIcon>
          </Tooltip>
        )}
        {props.isASpace && (
          <Tooltip
            disabled={window.$isTouchDevice && window.$isTouchDevice()}
            label={t("tiptap.components.blockMenuActions.label6")}
            position="bottom"
            withArrow
            zIndex={999999}
          >
            <ActionIcon onClick={() => goToSpace()}>
              <FontAwesomeIcon icon={solid("up-right-from-square")} />
            </ActionIcon>
          </Tooltip>
        )}
        {props.isAMedia && (
          <>
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={t("tiptap.components.blockMenuActions.label7")}
              position="bottom"
              withArrow
              zIndex={999999}
            >
              <ActionIcon onClick={() => editMedia(props.isAMedia)}>
                <FontAwesomeIcon icon={solid("arrows-rotate")} />
              </ActionIcon>
            </Tooltip>
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={t("tiptap.components.blockMenuActions.label8")}
              position="top"
              withArrow
              zIndex={999999}
            >
              <Menu
                zIndex={99999}
                position="bottom"
                placement="start"
                size="md"
                p={5}
                // closeOnItemClick={false}
                // className={classes.menuWrap}
                gutter={10}
                styles={{
                  item: {
                    padding: "5px 10px",
                  },
                }}
                control={
                  <ActionIcon
                    // className={classes.menuButton}
                    rightIcon={
                      <FontAwesomeIcon
                        size="sm"
                        color={theme.colors.gray[5]}
                        icon={solid("angle-down")}
                      />
                    }
                    compact
                  >
                    {getAlignment()}
                  </ActionIcon>
                }
              >
                <Menu.Item
                  onClick={() =>
                    props.editor.chain().focus().setTextAlign("left").run()
                  }
                >
                  <Group spacing={10}>
                    <FontAwesomeIcon size="sm" icon={solid("align-left")} />
                    <Text>{t("tiptap.components.blockMenuActions.left")}</Text>
                  </Group>
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    props.editor.chain().focus().setTextAlign("center").run()
                  }
                >
                  <Group spacing={10}>
                    <FontAwesomeIcon size="sm" icon={solid("align-center")} />
                    <Text>
                      {t("tiptap.components.blockMenuActions.center")}
                    </Text>
                  </Group>
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    props.editor.chain().focus().setTextAlign("right").run()
                  }
                >
                  <Group spacing={10}>
                    <FontAwesomeIcon size="sm" icon={solid("align-right")} />
                    <Text>{t("tiptap.components.blockMenuActions.right")}</Text>
                  </Group>
                </Menu.Item>
              </Menu>
            </Tooltip>
          </>
        )}
      </Group>
    );
  };

  const reFocusDocument = (event) => {
    if (props.isEmpty && isDocumentEmpty(props.editor)) {
      event.preventDefault();
      event.stopPropagation();
      props.editor.chain().focus().run();
    }
  };

  const handleRightClick = (event) => {
    props.editor.chain().focus().run();
  };

  const handleInput = () => {
    props.editor.chain().focus().run();
  };

  return (
    <>
      <Group onClick={(event) => reFocusDocument(event)}>
        {props.isEmpty &&
          isEmptySelection(props.editor) &&
          isTopNode(props.editor) &&
          !isMultilineSelection(props.editor) && (
            <Text
              contentEditable={true}
              onClick={() => props.editor.chain().focus().run()}
              onContextMenu={(event) => handleRightClick(event)}
              onInput={handleInput}
              sx={(theme) => ({
                color: theme.colors.gray[5],
                marginLeft: -10,
              })}
            >
              {t("tiptap.components.blockMenuActions.write")}
            </Text>
          )}
        <Paper shadow="sm" radius="md" withBorder sx={{ padding: 0 }}>
          <Group spacing={0} sx={{ padding: 0 }}>
            <p>{document.getElementsByClassName("is-focus").length > 0}</p>
            {props.isEmpty ? (
              <>
                <Group spacing={3} sx={{ padding: 5 }}>
                  {!props.isMultiple && !window.$spaceIsPost && (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={t("tiptap.components.blockMenuActions.label9")}
                      position="bottom"
                      withArrow
                      zIndex={999999}
                    >
                      <ActionIcon onClick={() => openBlocksSideMenu()}>
                        <FontAwesomeIcon icon={solid("plus")} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.blockMenuActions.label10")}
                    position="bottom"
                    withArrow
                    zIndex={999999}
                  >
                    <ActionIcon
                      onClick={() =>
                        props.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 1 })
                          .run()
                      }
                    >
                      <Text
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {t("tiptap.components.blockMenuActions.h1")}
                      </Text>
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.blockMenuActions.label11")}
                    position="bottom"
                    withArrow
                    zIndex={999999}
                  >
                    <ActionIcon
                      onClick={() =>
                        props.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 2 })
                          .run()
                      }
                    >
                      <Text
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {t("tiptap.components.blockMenuActions.h2")}
                      </Text>
                    </ActionIcon>
                  </Tooltip>

                  {window.location.pathname.indexOf("/registration") < 0 &&
                    !window.$spaceIsPost && (
                      <Tooltip
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={"Discussion prompt"}
                        position="bottom"
                        withArrow
                        zIndex={999999}
                      >
                        <ActionIcon onClick={() => insertIj()}>
                          <FontAwesomeIcon icon={regular("comment")} />
                        </ActionIcon>
                      </Tooltip>
                    )}

                  {window.location.pathname.indexOf("/registration") < 0 &&
                    !window.$spaceIsPost && (
                      <Tooltip
                        disabled={
                          window.$isTouchDevice && window.$isTouchDevice()
                        }
                        label={t("tiptap.components.blockMenuActions.label12")}
                        position="bottom"
                        withArrow
                        zIndex={999999}
                      >
                        <ActionIcon
                          onClick={() => insertCircle()}
                          // sx={{
                          //   width: "30px",
                          //   height: "30px"
                          // }}
                        >
                          <SpacesIcon width={20} height={20} />
                        </ActionIcon>
                      </Tooltip>
                    )}

                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.blockMenuActions.label13")}
                    position="bottom"
                    withArrow
                    zIndex={999999}
                  >
                    <ActionIcon onClick={() => openDirectMedia("image")}>
                      <FontAwesomeIcon icon={regular("image")} />
                    </ActionIcon>
                  </Tooltip>

                  <Tooltip
                    disabled={window.$isTouchDevice && window.$isTouchDevice()}
                    label={t("tiptap.components.blockMenuActions.label14")}
                    position="bottom"
                    withArrow
                    zIndex={999999}
                  >
                    <ActionIcon onClick={() => openDirectMedia("video")}>
                      <FontAwesomeIcon icon={solid("video")} />
                    </ActionIcon>
                  </Tooltip>
                  {!window.$spaceIsPost && (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label="Add grid"
                      position="bottom"
                      withArrow
                      zIndex={999999}
                    >
                      <ActionIcon
                        className={classes.newLineButtonStyle}
                        onClick={() => openBlocksSideMenu("Grids")}
                      >
                        <FontAwesomeIcon icon={solid("border-all")} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                  {!window.$spaceIsPost && (
                    <Tooltip
                      disabled={
                        window.$isTouchDevice && window.$isTouchDevice()
                      }
                      label={t("tiptap.components.blockMenuActions.label16")}
                      position="bottom"
                      withArrow
                      zIndex={999999}
                    >
                      <ActionIcon
                        className={classes.newLineButtonStyle}
                        onClick={() => openBlocksSideMenu("Templates")}
                      >
                        <FontAwesomeIcon icon={solid("map")} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Group>

                <Box
                  ml={7}
                  mr={7}
                  sx={(theme) => ({
                    width: "1px",
                    height: "40px",
                    backgroundColor: theme.colors.gray[4],
                  })}
                ></Box>

                {basicBlockOptions()}
              </>
            ) : (
              <>{basicBlockOptions()}</>
            )}
          </Group>
        </Paper>
      </Group>
      <Drawer
        opened={openedDuplicate}
        onClose={() => setOpenedDuplicate(false)}
        title={<Text size="xs">{"DUPLICATE SPACE"}</Text>}
        padding="xl"
        size="xl"
      >
        <DuplicateForm
          setOpened={setOpenedDuplicate}
          onDuplicate={onDuplicate}
          spaceId={spaceNodeId}
          spaceName={spaceNodeName}
        />
      </Drawer>
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
    </>
  );
};

export default BlockMenu;
