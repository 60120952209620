import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setHiddenSidebar } from "../../../store/app";
import { useTranslation } from "react-i18next";

import SpaceReference from "../../users/SpaceReference";
import FixedHeader from "../FixedHeader";

import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  Button,
  Box,
  Divider,
  Stepper,
  Grid,
  ScrollArea,
} from "@mantine/core";

import { useForm } from "@mantine/form";

import { useMediaQuery, useFocusTrap } from "@mantine/hooks";

export default function SignIn(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) =>
        /^\S+@\S+$/.test(value.trim())
          ? null
          : t("users.signIn.invalidEmailErrorMsg"),
    },
  });

  const focusTrapRef = useFocusTrap();

  const [spaceReference, setSpaceReference] = useState();
  const [showSpaceReference, setShowSpaceReference] = useState(true);
  const [showStepper, setStepper] = useState(false);
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    const b = document.getElementsByTagName("body")[0];
    b.style.display = "none";
    dispatch(setHiddenSidebar({ hidden: true }));
    setTimeout(() => {
      const b = document.getElementsByTagName("body")[0];
      b.style.display = "";
      dispatch(setHiddenSidebar({ hidden: true }));
    }, 500);
    return () => {
      dispatch(setHiddenSidebar({ hidden: false }));
    };
  }, []);

  useEffect(() => {
    setSpaceReference(props.space.slug);
  }, [props]);

  const onSignIn = () => {
    localStorage.removeItem("dummy_was_registered");
    if (props.space.is_paid) {
      navigate(`/space/${props.space.slug}/demo/pay`);
    } else {
      if (props.space && props.space.permissions.block_until_approved){
        navigate(`/space/${props.space.slug}/demo/pending`);
      }
      else{
        navigate(`/space/${props.space.slug}`);
      }
    }
  };

  const getMagicLinkPath = () => {
    return `/space/${props.space.slug}/demo/magic_link`;
  };

  const smallScreen = useMediaQuery("(max-width: 768px)");

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container style={{ marginBottom: "50px" }} size={1000}>
        <FixedHeader
          isMain={false}
          show={true}
          inverted={true}
          editable={false}
          components={["dummy"]}
        />

        {!smallScreen && spaceReference && showStepper ? (
          <Container size={800} mt={30} align="center">
            <Stepper active={0} breakpoint="sm" size="lg">
              <Stepper.Step
                label={t("users.stepper.step1")}
                description={t("users.stepper.step1Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step2")}
                description={t("users.stepper.step2Description")}
              ></Stepper.Step>
              <Stepper.Step
                label={t("users.stepper.step3")}
                description={t("users.stepper.step3Description")}
              ></Stepper.Step>
            </Stepper>
          </Container>
        ) : (
          ""
        )}
        <Grid justify="center" grow gutter="0">
          <Grid.Col xs={12} sm={6}>
            <Container size={500} mt={40} mb={smallScreen ? 0 : 40}>
              <form
                onSubmit={form.onSubmit((values) => onSignIn(values))}
                ref={focusTrapRef}
              >
                <Paper withBorder shadow="md" p={0} mt={30} radius="md">
                  <Box pt={20} pb={5} pl={35} pr={35}>
                    <Title
                      sx={(theme) => ({ fontSize: 28, fontWeight: 900 })}
                      order={1}
                    >
                      {t("users.signIn.title")}
                    </Title>
                    <Text color="dimmed" size="md">
                      {t("users.signIn.subtitle")}{" "}
                      <Link
                        to={`/space/${props.space.slug}/demo/sign_up`}
                        size="sm"
                      >
                        {t("users.signIn.subtitleLink")}
                      </Link>
                    </Text>
                  </Box>
                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                  <Box pt={10} pb={25} pl={35} pr={35}>
                    <TextInput
                      {...form.getInputProps("email")}
                      label={t("users.signIn.email")}
                      radius="sm"
                      size="md"
                      mb={15}
                      data-autofocus
                    />
                    <PasswordInput
                      {...form.getInputProps("password")}
                      label={t("users.signIn.password")}
                      radius="sm"
                      size="md"
                      mb={15}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      type="submit"
                    >
                      {t("users.signIn.submit")}
                    </Button>
                    <Divider
                      sx={(theme) => ({
                        borderTopColor: theme.colors.gray[2],
                        color: theme.colors.gray[2],
                      })}
                      my="sm"
                      label="OR"
                      labelPosition="center"
                      labelProps={{ color: "#666" }}
                    />
                    <Button
                      fullWidth
                      mt="xl"
                      radius="xl"
                      size="md"
                      mb={15}
                      color="dark"
                      component={Link}
                      to={getMagicLinkPath()}
                    >
                      {t("users.signIn.magicLink")}
                    </Button>
                    <Text size="md" align="center" mt={15}>
                      <Link to={`/space/${props.space.slug}/demo/recover`}>
                        {t("users.signIn.forgotPassword")}
                      </Link>
                    </Text>
                  </Box>
                </Paper>
              </form>
            </Container>
          </Grid.Col>
          {spaceReference && showSpaceReference && (
            <Grid.Col xs={12} sm={6}>
              <SpaceReference
                isMember={isMember}
                slug={spaceReference}
                setSpaceReference={setSpaceReference}
                setShowSpaceReference={setShowSpaceReference}
                setStepper={setStepper}
              />
            </Grid.Col>
          )}
        </Grid>
      </Container>
    </ScrollArea>
  );
}
