import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Drawer,
  Text,
  Stack,
  Box,
  createStyles,
  ScrollArea,
} from "@mantine/core";

import SpaceNeo4jModel from "../../../../models/neo4j/Space";
import SpaceModel from "../../../../models/Space";
import ViewModel from "../../../../models/View";
import { storeJSON, getJSON } from "../../../../utils/LocalDB";

import tinycolor from "tinycolor2";
import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import {
  setTiptapSidemenu,
  setSpace2,
  setColors2,
} from "../../../../store/app";

import HeaderImage from "../../HeaderImage";
import FixedHeader from "../../FixedHeader";
import Loading from "../static/Loading";

import TiptapNoHP from "../../../tiptap/components/TiptapNoHP";

import defaultSpaceColors from "../../../../collections/defaultSpaceColors";

import SpaceChannel from "../../../../sockets/Space";

const useStyles = createStyles((theme, { colors }) => ({
  userInfoWrap: {
    paddingBottom: 35,
  },
  avatarWrapMediumScreen: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  avatarMediumScreen: {
    width: 100,
    marginRight: 0,
  },
  avatar: {
    width: 300,
    marginRight: 30,
  },
  userDetailsWrap: {
    alignSelf: "center",
  },
  drawerWrap: {
    backgroundColor: colors.background_color,
  },
  primaryButtonStyle: {
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  secondaryButtonStyle: {
    backgroundColor: colors.secondary_button_background_color,
    color: colors.secondary_button_text_color,
    "&:hover": {
      backgroundColor: tinycolor(colors.secondary_button_background_color)
        .darken(4)
        .toString(),
    },
  },
  buttonStyleOutline: {
    backgroundColor: "none",
    color: colors.primary_button_background_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.background_color).darken(1).toString(),
    },
  },
  themeTextColor: {
    color: colors.default_text_color,
  },
  closeButton: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: colors.background_color,
      color: colors.primary_button_background_color,
    },
    // marginRight: "25px",
  },
  spaceContainer: {
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
    ".ProseMirror a": {
      color: colors.default_link_color,
    },
    ".ProseMirror .file-node svg": {
      stroke: colors.default_text_color,
    },
    ".ProseMirror .file-node a": {
      color: colors.default_text_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.sidebar_text_color,
      backgroundColor: colors.sidebar_background_color,
    },
    ".ProseMirror .fancylink-node .main-content": {
      color: colors.default_text_color,
      backgroundColor: isSpaceThemed(colors)
        ? isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(8).toString()
          : tinycolor(colors.background_color).darken(8).toString()
        : tinycolor(colors.background_color).darken(2).toString(),
      border: isSpaceThemed(colors)
        ? `1px solid ${
            isDark(colors.background_color)
              ? tinycolor(colors.background_color).lighten(20).toString()
              : tinycolor(colors.background_color).darken(20).toString()
          }`
        : `1px solid #e9ecef`,
      "&:hover": {
        // backgroundColor: theme.colors.gray[0],
        backgroundColor: isSpaceThemed(colors)
          ? isDark(colors.background_color)
            ? tinycolor(colors.background_color).lighten(10).toString()
            : tinycolor(colors.background_color).darken(10).toString()
          : tinycolor(colors.background_color).darken(2).toString(),
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
        cursor: "pointer",
        // color: "#000",
        textDecoration: "none",
      },
    },
  },
  drawerHeader: {
    color: colors.default_text_color,
    fontWeight: 700,
    paddingBottom: 10,
    // borderBottom: `1px solid ${colors.default_text_color}`
  },
}));

const paginator = {
  per: 30,
  page: 1,
};

export default function ContentReadonlyModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const space2 = useSelector((state) => state.space2);
  let colors2 = useSelector((state) => state.colors2);

  colors2 = isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;

  const { classes, theme } = useStyles({ colors: colors2 });

  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const [opened, setOpened] = useState(false);
  const [access, setAccess] = useState(false);

  const [edit, setEdit] = useState(false);
  const [params, setParams] = useState({ slug: "" });

  const openedFromUrl = useRef();

  const [loaded, setLoaded] = useState(false);
  const content = useRef({});
  const lastSlug = useRef();
  const width = useRef();
  const viewport = useRef();
  const lastColorTheme = useRef();

  useEffect(() => {
    if (params.slug) {
      onFetchSpace();
      onFetchView();
    }
  }, [params]);

  useEffect(() => {
    if (openedFromUrl.current != location.pathname) {
      setOpened(false);
    }
  }, [location]);

  useEffect(() => {
    if (tiptapSidemenu && tiptapSidemenu.opened == "space-content") {
      setParams({ slug: tiptapSidemenu.option });
      setOpened(true);
    } else {
      // setOpened(false);
    }
  }, [tiptapSidemenu]);

  useEffect(() => {
    if (!opened) {
      window.$showBackButtonModal = false;
      content.current = {};

      setParams({ slug: "" });
      setLoaded(false);

      setEdit(false);
      if (tiptapSidemenu.opened == "space-content") {
        dispatch(setTiptapSidemenu({ opened: null }));
      }
      window.$modalSpaceOpened = false

      dispatch(
        setSpace2({})
      );
    } else {
      window.$showBackButtonModal = true;
      openedFromUrl.current = location.pathname;
      window.$modalSpaceOpened = tiptapSidemenu.option
    }
  }, [opened]);

  const onFetchView = () => {
    ViewModel.onFetch(
      "tiptap",
      params.slug,
      (data) => {
        content.current = data.content;
        window.$spaceUserInteractions = data.interactions;
        width.current = data.view_width;
        window.$spaceWideWidth = data.view_width;
        window.$themeColors = { ...data.colors };

        dispatch(setColors2({ ...data.colors }));
        setTimeout(() => {
          setLoaded(true);
        }, 250);
      },
      (data) => {
        navigate(`/home/top`)
      }
    );
  };

  const onFetchSpace = () => {
    setAccess(false);
    SpaceModel.onAccess(
      params.slug,
      (accessData) => {
        if (accessData.interactions){
          navigate(`/space/${accessData.slug}/${accessData.version}`);
          return
        }
        window.$spaceUserInteractions = accessData.interactions;

        SpaceNeo4jModel.onFetch(
          params.slug,
          (data) => {
            
            setAccess({
              role: accessData.role,
              isTopSpace: data.topSpace == null,
            });

            data.space.redirect_to = { ...accessData.redirect_to };
            onSetSpaceData(accessData, data.space, data.topSpace, data.path);
          },
          (data) => {
            setOpened(false);
          }
        );
      },
      (data) => {}
    );
  };

  const onSetSpaceData = (accessData, _space, topSpace, path) => {
    const currentPath = `/space/${_space.slug}/${_space.version}`;

    if (_space.version != "content" || _space.redirect_to.url != currentPath) {
      navigate(currentPath);
      return;
    }

    window.$spaceIsPost = _space.is_post && !_space.permissions.can_manage;

    window.$currentSpaceName = _space.name;

    dispatch(
      setSpace2({
        ..._space,
        completed: _space.completed,
        permissions: { ..._space.permissions[accessData.role] },
        colors: { ..._space.colors },
        interactions: _space.interactions ? { ..._space.interactions } : false,
        settings_tabs: [..._space.settings_tabs],
        settings: { ..._space.settings },
        is_top_level: _space.is_top_level,
        tags: [..._space.tags],
        tagged_with: [..._space.tagged_with],
        is_liked: _space.is_liked,
        path: path ? [...path] : [],
      })
    );
  };

  const onSpaceFetched = (data) => {
    onSetSpaceData(data);
  };

  const onUpdateChildren = (data) => {
    window.$space2Children = data;
  };

  return (
    <>
      {access && (
        <SpaceChannel
          spaceId={params.slug}
          fetchSpace={onFetchSpace}
          access={access}
          updateChildren={false}
          onUpdateChildren={onUpdateChildren}
        />
      )}
      <Drawer
        classNames={{
          drawer: classes.drawerWrap,
          closeButton: classes.closeButton,
          header: classes.drawerHeader,
        }}
        opened={opened}
        onClose={() => setOpened(false)}
        title={
          <Text className={classes.themeTextColor} weight="bold">
            {""}
          </Text>
        }
        padding="xs"
        size={700}
        
      >
        {!space2.slug || !loaded || !access ? (
          <Loading />
        ) : (
          <>
            <Stack
              mt={0}
              className={classes.spaceContainer}
              spacing={0}
              style={{ height: "100%" }}
            >
              <ScrollArea
                id="main-scroll-area"
                className="content-scrollview"
                style={{ height: "100%" }}
                viewportRef={viewport}
              >
                <Box ml={10} mr={10}>
                  <Box pl={15} pr={15} pt={10}>
                    <HeaderImage useSpace2={true} radius={10} />
                  </Box>
                  <FixedHeader
                    isMain={false}
                    show={true}
                    inverted={true}
                    editable={false}
                    components={[
                      "title",
                      "calendar",
                      "status",
                      "interactions",
                      "tag",
                      "redirect",
                    ]}
                    useSpace2={true}
                  />
                </Box>
                <Box mt={10}></Box>
                <TiptapNoHP
                  key={`tiptap-${new Date()}`}
                  content={content.current.default}
                  allowComments={true}
                  spaceWidth={width.current}
                  useSpace2={true}
                  ml={10}
                  mr={10}
                  buttonsZIndex={900}
                />
              </ScrollArea>
            </Stack>
          </>
        )}
      </Drawer>
    </>
  );
}
