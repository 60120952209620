import React, { useState, useEffect, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import {
  setSpace,
  setColors,
  setHiddenSidebar,
  setHiddenHeader,
  setHiddenCover,
  setHiddenTitle,
  setHiddenBreadcrumbs,
  setForceLayoutUpdate,
  setActions,
  setTiptapSidemenu
} from "../../store/app";
import SpaceModel from "../../models/Space";
import SpaceNeo4jModel from "../../models/neo4j/Space";
import { storeJSON, getJSON, deleteJSON } from "../../utils/LocalDB";
import { useTranslation } from "react-i18next";

import { setCookie, getCookie } from "../../utils/Cookies";

import { Helmet } from "react-helmet";

import SpaceChannel from "../../sockets/Space";

const Access = memo(({ spaceId, setSpaceAccess }) => {
  const space = useSelector((state) => state.space);
  const mainActions = useSelector((state) => state.mainActions);

  const { t, i18n } = useTranslation();

  const preview = useRef(false);
  const invitationSlug = useRef(null);
  const lastPage = useRef(null);

  const forceLayoutUpdate = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const lastColorTheme = useRef();

  const [access, setAccess] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    return () => {
      dispatch(setColors());
      dispatch(setHiddenSidebar({ hidden: false }));
      dispatch(setHiddenHeader({ hidden: false }));
      dispatch(setHiddenCover({ hidden: false }));
      dispatch(setHiddenTitle({ hidden: false }));
      dispatch(setHiddenBreadcrumbs({ hidden: false }));
      dispatch(setSpace({ slug: null }));
      window.$currentTopSlug = null;
      window.$fetchedTopSlug = null;
    };
  }, []);

  useEffect(() => {
    onSlugChange();
  }, [spaceId]);

  useEffect(() => {
    if (lastPage.current){
      if ((lastPage.current.indexOf("pending") >= 0 && location.pathname.indexOf("pending") < 0) || (lastPage.current.indexOf("private") >= 0 && location.pathname.indexOf("private") < 0) || (lastPage.current.indexOf("pay") >= 0 && location.pathname.indexOf("pay") < 0) || (lastPage.current.indexOf("register") >= 0 && location.pathname.indexOf("register") < 0)){
        onSlugChange();
      }
    }

    const refresh = new URLSearchParams(location.search).get("refresh");
    if (refresh){
      hideLayout(window.$hiddenLayoutElements);
    }
    
    lastPage.current = location.pathname;
  }, [location]);

  useEffect(() => {
    hideLayout(window.$hiddenLayoutElements);
  }, [space]);

  const onSlugChange = () => {
    fetchSpace();

    const edit = new URLSearchParams(location.search).get("edit");
    if (edit) {
      dispatch(setActions({ editPage: true }));

      searchParams.delete('edit');
      setSearchParams(searchParams);
      setTimeout(() => {
        const zoom = new URLSearchParams(location.search).get("zoom_block");
        if (zoom){
          dispatch(setTiptapSidemenu({ opened: "newMedia", option: "zoom" }));

          searchParams.delete('zoom_block');
          setSearchParams(searchParams);
        }
      }, 4000)
    }

    invitationSlug.current = new URLSearchParams(location.search).get(
      "invitation_slug"
    );

    if (invitationSlug.current) {
      setCookie("invitation_slug", invitationSlug.current, 1);
    } else {
      invitationSlug.current = getCookie("invitation_slug");
    }

    if (
      location.pathname.split("/")[0] == "circle" ||
      location.pathname.split("/")[1] == "circle"
    ) {
      const newUrl = location.pathname.replace("circle", "space");
      navigate(newUrl);
      return;
    }

    preview.current =
      location.pathname.indexOf("/register") >= 0 ||
      location.pathname.indexOf("/registration") >= 0;
  };

  const hideLayout = (hidden_layout_elements = null) => {
    if (space && !space.slug) {
      return;
    }

    if (window.location.pathname.indexOf("/settings") >= 0){
      return
    }

    if (!hidden_layout_elements) {
      hidden_layout_elements = space.hidden_layout_elements;
    }

    dispatch(
      setHiddenSidebar({
        hidden:
          hidden_layout_elements.indexOf("sidebar") >= 0 ||
          space.pod_type == "user_message",
      })
    );

    if (hidden_layout_elements.indexOf("header") >= 0) {
      const hide = space.permissions.can_manage ? "admin" : true;
      dispatch(
        setHiddenHeader({
          hidden: hide,
        })
      );
      if (!forceLayoutUpdate.current) {
        forceLayoutUpdate.current = true;
        setTimeout(() => {
          dispatch(setForceLayoutUpdate({ update: true }));
        }, 1000);
      }
    } else {
      dispatch(setHiddenHeader(false));
    }
    dispatch(
      setHiddenCover({
        hidden: hidden_layout_elements.indexOf("cover") >= 0,
      })
    );
    dispatch(
      setHiddenTitle({
        hidden: hidden_layout_elements.indexOf("title") >= 0,
      })
    );
    dispatch(
      setHiddenBreadcrumbs({
        hidden: hidden_layout_elements.indexOf("breadcrumbs") >= 0,
      })
    );

    if (mainActions.editPage) {
      window.$spaceWideWidth = space.space_width;
      window.$registrationWideWidth = space.registration_width;
    }
  };

  const fetchSpace = () => {
    // setLoading(true);
    try {
      $(`#collapse-button-node-${spaceId}-closed`).click();
    } catch {}

    setAccess(false);

    
    const spaceAction = window.location.href.split("/").pop()
    SpaceModel.onAccess(
      spaceId,
      (accessData) => {
        window.$spaceWideWidth = accessData.space_width;
        window.$registrationWideWidth = accessData.registration_width;
        window.$currentRole = accessData.role;
        window.$userIsPending = accessData.is_pending;
        window.$userIsSubscribed = accessData.is_subscribed;

        try{
          if (
            accessData.colors &&
            JSON.stringify(accessData.colors) != JSON.stringify(lastColorTheme.current)
          ) {
            lastColorTheme.current = { ...accessData.colors };
            window.$themeColors = { ...accessData.colors };
            dispatch(setColors(accessData.colors));
          } else if (!accessData.colors) {
            lastColorTheme.current = null;
            dispatch(setColors());
          }
          
          if (window.location.pathname.indexOf("/content") >= 0 || window.location.pathname.indexOf("/discussion") >= 0 || window.location.pathname.indexOf("/list") >= 0 || window.location.pathname.indexOf("/events") >= 0 || window.location.pathname.indexOf("/showcase") >= 0){
            hideLayout(accessData.hidden_layout_elements)
          }
          else if (window.location.pathname.indexOf("/private") >= 0 || window.location.pathname.indexOf("/pending") >= 0 || window.location.pathname.indexOf("/pay") >= 0 || window.location.pathname.indexOf("/register") >= 0 || window.location.pathname.indexOf("/registration") >= 0){
            dispatch(setHiddenSidebar({ hidden: true }));
          }
        }
        catch{

        }

        SpaceNeo4jModel.onFetch(
          spaceId,
          (data) => {
            window.$isNeo4j = accessData.is_neo4j;
            if (accessData.is_neo4j != "new") {
              navigate(`/space_legacy/${spaceId}`);
              return;
            }
            setAccess({
              role: accessData.role,
              isTopSpace: data.topSpace == null,
            });

            try{
              onSetSpaceData(accessData, data.space, data.topSpace, data.path);
              onSpaceFetched(data.space);
            }
            catch{

            }
            
            

            if (accessData.redirect_to && accessData.redirect_to.mobile != spaceAction && ["content", "discussion", "list", "events"].indexOf(spaceAction) >= 0){
              setSpaceAccess(true)
              navigate(accessData.redirect_to.url);
              return
            }
            
          },
          (data) => {
            navigate(`/home/top`);
          }
        );
      },
      (data) => {
        navigate(`/home/top`);
      }
    );
  };

  const onSetSpaceData = (accessData, _space, topSpace, path) => {
    _space.permissions = _space.permissions[accessData.role];

    window.$spaceUserInteractions = accessData.interactions;
    _space.redirect_to = { ...accessData.redirect_to };

    // Set Global Variables
    window.$currentSpaceName = space.name;
    window.$currentTopSlug = topSpace ? topSpace.slug : _space.slug;
    window.$fetchedTopSlug = topSpace ? topSpace.slug : _space.slug;
    window.$currentSlug = spaceId;
    window.$showMarkCompleteButton =
      _space.permissions.show_mark_complete_button_in_header;
    window.$spaceIsPost = _space.is_post && !_space.permissions.can_manage;
    window.$spacePath = _space.path; // This is not in Neo4j

    if (mainActions.editPage) {
      window.$spaceWideWidth = _space.space_width;
      window.$registrationWideWidth = _space.registration_width;
    }

    
    dispatch(
      setSpace({
        ..._space,
        completed: _space.completed,
        permissions: { ..._space.permissions },
        colors: { ..._space.colors },
        interactions: _space.interactions ? { ..._space.interactions } : false,
        settings_tabs: [..._space.settings_tabs],
        settings: { ..._space.settings },
        is_top_level: _space.is_top_level,
        tags: [..._space.tags],
        tagged_with: [..._space.tagged_with],
        is_liked: _space.is_liked,
        path: path ? [...path] : [],
      })
    );
  };

  const onSpaceFetched = (_space) => {
    window.$hiddenLayoutElements = null;

    if (
      _space.redirect_to &&
      _space.redirect_to.action == "react" &&
      _space.redirect_to.url.indexOf("/processing") >= 0 &&
      location.pathname.indexOf("processing") < 0
    ) {
      navigate(_space.redirect_to.url);
      setSpaceAccess(true)
      return;
    }

    if (
      _space.redirect_to &&
      _space.redirect_to.action == "react" &&
      location.pathname.indexOf("processing") >= 0
    ) {
      navigate(_space.redirect_to.url);
      setSpaceAccess(true)
      return;
    }

    if (
      location.pathname.indexOf("/members") >= 0 &&
      ((_space.permissions.show_members &&
        (_space.is_open || _space.permissions.is_member)) ||
        _space.permissions.can_manage ||
        _space.permissions.is_admin)
    ) {
      setSpaceAccess(true)
      return;
    }

    if (location.pathname.indexOf("/broadcast/exclude") >= 0) {
      setSpaceAccess(true)
      return;
    }

    if (location.pathname.indexOf("/getting_started") >= 0) {
      setSpaceAccess(true)
      return;
    }

    if (location.pathname.indexOf("/gateway") >= 0) {
      setSpaceAccess(true)
      return;
    }

    if (
      location.pathname.indexOf("/pay") >= 0 &&
      !_space.permissions.is_member
    ) {
      setSpaceAccess(true)
      return;
    }

    if (
      location.pathname.indexOf("/edit") >= 0 &&
      location.pathname.indexOf("/registration") < 0
    ) {
      setSpaceAccess(true)
      return;
    }

    if (location.pathname.indexOf("/demo") >= 0) {
      if (_space.permissions.can_manage) {
        setSpaceAccess(true)
        return;
      } else {
        navigate(`/space/${_space.slug}`);
      }
    }

    if (location.pathname.indexOf("/settings") >= 0) {
      if (_space.permissions.can_manage) {
        setSpaceAccess(true)
        return;
      } else {
        navigate(`/space/${_space.slug}`);
      }
    }

    if (
      preview.current &&
      ((_space.block_until_registered && !_space.permissions.is_member) ||
        _space.permissions.can_manage ||
        _space.permissions.is_admin)
    ) {
      if (
        location.pathname.indexOf("/circle") < 0 &&
        _space.permissions.use_legacy_registration_page
      ) {
        window.location.href= `/circle/${_space.slug}/register`;
        return
      } else {
        if (
          !_space.permissions.can_manage &&
          !_space.permissions.is_admin &&
          location.pathname.indexOf("/registration") >= 0
        ) {
          if (_space.block_until_registered) {
            window.location.href= `/space/${_space.slug}/register`;
            return
          } else {
            window.location.href= `/space/${_space.slug}`;
            return
          }
        }
      }
      setSpaceAccess(true)
      return;
    }

    if (
      _space.redirect_to &&
      _space.redirect_to.action == "react" &&
      ((!_space.permissions.can_manage && !_space.permissions.is_admin) ||
        _space.redirect_to.force_admin)
    ) {
      const search = window.location.search;
      const _pod_message_id = new URLSearchParams(search).get("pod_message_id");
      const _reflection_id = new URLSearchParams(search).get("reflection_id");
      const _interactive_slug = new URLSearchParams(search).get(
        "interactive_slug"
      );
      const _tag = new URLSearchParams(search).get("tag");
      if (_pod_message_id) {
        const symbol = _space.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        _space.redirect_to.url += `${symbol}pod_message_id=${_pod_message_id}`;
      }
      if (_reflection_id) {
        const symbol = _space.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        _space.redirect_to.url += `${symbol}reflection_id=${_reflection_id}`;
      }
      if (_tag) {
        const symbol = _space.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        _space.redirect_to.url += `${symbol}tag=${_tag}`;
      }
      if (_interactive_slug) {
        const symbol = _space.redirect_to.url.indexOf("?") >= 0 ? "&" : "?";
        _space.redirect_to.url += `${symbol}interactive_slug=${_interactive_slug}`;
      }

      navigate(_space.redirect_to.url);
    } else if (_space.redirect_to && _space.redirect_to.action == "redirect") {
      window.location.href= _space.redirect_to.url;
      return;
    } else if (
      _space.version != location.pathname.split("/").pop() &&
      location.pathname.indexOf("settings") < 0 &&
      location.pathname.indexOf("processing") < 0
    ) {
      navigate(`/space/${_space.slug}/${_space.version}`);
    }
    if (
      _space.version == location.pathname.split("/").pop() &&
      _space.permissions.is_member
    ) {
      localStorage.removeItem("invitation_slug");
    }
    setSpaceAccess(true)
  };

  return (
    <>
      <Helmet>
        <title>
          {space.slug ? space.name : t("spaces.space.sutraLoading")}
        </title>
      </Helmet>
      {access && (
        <SpaceChannel
          spaceId={spaceId}
          fetchSpace={fetchSpace}
          access={access}
          updateChildren={true}
        />
      )}
    </>
  );
});

export default Access;
