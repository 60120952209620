import React, { useState, useEffect, useRef } from "react";

import {
  createStyles,
  Text,
  ActionIcon,
  Button,
  MantineProvider,
  Group,
  Box,
  Select,
} from "@mantine/core";
import { useListState } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import Swal from "sweetalert2";
import { DateTime } from "luxon";

import useLangUser from "../../../../utils/LangUser";
import JourneyModel from "../../../../models/Journey";
import StepModel from "../../../../models/Step";
import EditStep from "./EditStep";
import AddStep from "./AddStep";
import StepIcon from "./StepIcon";

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[4]}`,
    padding: `15px 20px 15px 5px`,
    // paddingRight: theme.spacing.xs, // to offset drag handle
    backgroundColor: "#fff",
  },

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    textAlign: "center",
    fontSize: 30,
    fontWeight: 700,
    width: 80,
  },

  settings: {
    color: theme.colors.gray[6],
    fontSize: 18,
    width: 30,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    color: theme.colors.gray[6],
    paddingLeft: 10,
    paddingRight: 0,

    "&:hover": {
      color: theme.colors.gray[7],
    },
  },

  stepFooterPadding: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "spaceBetween",
  },

  stepFooterLeft: {
    borderRight: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterRight: {
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
    height: 10,
    width: "100%",
  },

  stepFooterAddStep: {
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
  },

  addStepHidden: {
    visibility: "hidden",
  },

  addStepHover: {
    "&:hover": {
      color: "#fff",
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      backgroundColor: theme.colors.sutrablue[3],
    },
  },

  bottomConnector: {
    fontSize: 20,
    color: theme.colors.gray[4],
    marginTop: -15,
    textAlign: "center",
    height: 20,
  },

  addStepButtonWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 5,
  },

  addStepButtonHover: {
    "&:hover": {
      backgroundColor: theme.colors.sutrablue[3],
      border: `1px solid ${theme.colors.sutrablue[3]}`,
      color: "#fff",
    },
  },
}));

export default function RegistrationFlow({ onUpdate }) {
  const { classes, cx, theme } = useStyles();
  const { language, setLangUser } = useLangUser();
  const space = useSelector((state) => state.space);
  const { t, i18n } = useTranslation();
  const [journey, setJourney] = useState(null);
  const [steps, handlers] = useListState([]);
  const mounted = useRef(false);
  const [isDragging, setIsDragging] = useState(false);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [addStepOpened, setAddStepOpened] = useState(false);
  const [selectedStep, setSelectedStep] = useState("");
  const [triggerEdit, setTriggerEdit] = useState(null);
  // const [actionName, setActionName] = useState("");
  // const [actionValue, setActionValue] = useState("");
  const [stepPosition, setStepPosition] = useState(null);
  const [trigger, setTrigger] = useState("interested-registered");

  useEffect(() => {
    if (!space.settings) {
      return;
    }
    onFetch();
    if (space.journeys && space.journeys.length > 0) {
      setTrigger(space.journeys[0].trigger);
    }
  }, [space]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [addStepOpened]);

  useEffect(() => {
    if (!addStepOpened && !settingsOpened) {
      onFetch();
      setSelectedStep(null);
    }
  }, [addStepOpened, settingsOpened]);

  useEffect(() => {
    if (mounted.current) {
      onReorder();
    } else {
      mounted.current = true;
    }
  }, [steps]);

  useEffect(() => {
    if (space && space.slug) {
      onFetch();
    }
  }, [trigger]);

  const onReorder = () => {
    if (steps.length == 0) {
      return;
    }

    const order = steps.map((step, index) => {
      return { id: step.id, position: index + 1 };
    });

    StepModel.onReorder(
      space.slug,
      journey,
      order,
      (data) => {},
      (data) => {}
    );
  };

  const onDestroy = (event, step) => {
    event.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      html: "This step will be permanently deleted",
      showDenyButton: true,
      confirmButtonText: "Delete step",
      denyButtonText: "Cancel",
      confirmButtonColor: theme.colors.red[8],
      denyButtonColor: theme.colors.gray[6],
    }).then((result) => {
      if (result.isConfirmed) {
        StepModel.onDestroy(
          step.id,
          space.slug,
          step.journey_id,
          (data) => {
            setSettingsOpened(false);
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const triggerOptions = [
    {
      label: t(
        "spaces.settings.registration.registrationFlow.registration.label1"
      ),
      value: "interested-registered",
      description: t(
        "spaces.settings.registration.registrationFlow.registration.description3"
      ),
    },

    {
      label: t(
        "spaces.settings.registration.registrationFlow.registration.label2"
      ),
      value: "registered",
      description: t(
        "spaces.settings.registration.registrationFlow.registration.description4"
      ),
    },

    {
      label: t(
        "spaces.settings.registration.registrationFlow.registration.label3"
      ),
      value: "interested",
      description: t(
        "spaces.settings.registration.registrationFlow.registration.description5"
      ),
    },
  ];

  const onFetch = () => {
    JourneyModel.onFetchByTrigger(
      trigger,
      space.slug,
      (data) => {
        setJourney(data.id);
        handlers.setState(data.steps);

        if (triggerEdit) {
          setSelectedStep(
            data.steps.filter((step) => step.id === triggerEdit)[0]
          );
          setSettingsOpened(true);
          setTriggerEdit(null);
        }
      },
      (data) => {}
    );

    // JourneyModel.onFetchById(space.journey_id, space.slug, (data) => {
    //   setJourney(data.id)
    //   handlers.setState(data.steps);
    //
    //   if (triggerEdit){
    //     setSelectedStep(data.steps.filter(step => step.id === triggerEdit)[0])
    //     setSettingsOpened(true)
    //     setTriggerEdit(null)
    //   }
    //
    // }, (data) => {
    //
    // })
  };

  const displayName = (step) => {
    if (step.action == "wait_until" && step.wait_until) {
      const wait_until = new Date(step.wait_until).toISOString();
      return `${step.name} ${DateTime.fromISO(wait_until)
        .setLocale(language)
        .toFormat("EEE, MMM d y, h:mm a")}`;
    } else {
      return step.name;
    }
  };

  const displayStepIcon = (value) => {
    if (value == "send_email") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.blue[5] })}
        >
          <FontAwesomeIcon icon={solid("envelope")} />
        </Text>
      );
    } else if (
      value == "add_to_existing_space" ||
      value == "add_to_new_space" ||
      value == "add_from_sub_space" ||
      value == "add_sub_circle_members"
    ) {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.green[5] })}
        >
          <FontAwesomeIcon icon={solid("user")} />
        </Text>
      );
    } else if (value == "add_structure") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.cyan[5] })}
        >
          <FontAwesomeIcon icon={solid("list-ol")} />
        </Text>
      );
    } else if (value == "survey_person") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.violet[5] })}
        >
          <FontAwesomeIcon icon={solid("square-poll-horizontal")} />
        </Text>
      );
    } else if (value == "set_to_pending") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.red[5] })}
        >
          <FontAwesomeIcon icon={solid("circle-pause")} />
        </Text>
      );
    } else if (
      value == "wait" ||
      value == "wait_until" ||
      value == "wait_until_structure_complete"
    ) {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.gray[5] })}
        >
          <FontAwesomeIcon icon={solid("clock")} />
        </Text>
      );
    } else if (value == "apply_template") {
      return (
        <Text
          className={classes.symbol}
          sx={(theme) => ({ color: theme.colors.yellow[5] })}
        >
          <FontAwesomeIcon icon={solid("map")} />
        </Text>
      );
    }
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const AddStepBlock = (position) => {
    return (
      <div>
        <div className={cx({ [classes.addStepHidden]: isDragging })}>
          <div>
            <div className={classes.stepFooterPadding}>
              <div className={classes.stepFooterLeft}></div>
              <div className={classes.stepFooterRight}></div>
            </div>
            <div className={classes.stepFooterAddStep}>
              <MantineProvider theme={{ primaryShade: 5 }}>
                <ActionIcon
                  size="sm"
                  radius="xl"
                  variant="outline"
                  color="gray"
                  onClick={() => {
                    setAddStepOpened(true);
                    setStepPosition(position);
                  }}
                  classNames={{
                    outline: classes.addStepHover,
                  }}
                >
                  <FontAwesomeIcon icon={solid("plus")} />
                </ActionIcon>
              </MantineProvider>
            </div>
            <div className={classes.stepFooterPadding}>
              <div className={classes.stepFooterLeft}></div>
              <div className={classes.stepFooterRight}></div>
            </div>
            <div className={classes.bottomConnector}>
              <FontAwesomeIcon icon={solid("caret-down")} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const items = steps.map((item, index) => (
    <Draggable
      key={item.id.toString()}
      index={index}
      draggableId={item.id.toString()}
    >
      {(provided, snapshot) => (
        <div>
          <div
            className={cx(classes.item, {
              [classes.itemDragging]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <StepIcon value={item.action} />
            {/*displayStepIcon(item.action)*/}
            <Group position="apart" sx={{ width: "100%" }} noWrap>
              <Box sx={{ width: "90%" }}>
                <Text weight={600}>{displayName(item)}</Text>
                <Text color="dimmed" size="sm">
                  {item.description}
                </Text>
              </Box>
              <Group spacing={0} noWrap>
                <ActionIcon
                  size="sm"
                  radius="xl"
                  onClick={() => {
                    setSelectedStep(item);
                    setSettingsOpened(true);
                  }}
                  classNames={{
                    hover: classes.settings,
                    outline: classes.settings,
                  }}
                >
                  <FontAwesomeIcon icon={solid("cog")} />
                </ActionIcon>
                <div
                  {...provided.dragHandleProps}
                  className={classes.dragHandle}
                >
                  <FontAwesomeIcon icon={solid("grip-vertical")} />
                </div>
              </Group>
            </Group>
          </div>
          {AddStepBlock(index + 1)}
        </div>
      )}
    </Draggable>
  ));

  const onSettingsChange = () => {
    
  }

  return (
    <>
      <Text size="sm" color="dimmed" mb={20}>
        {t(
          "spaces.settings.registration.registrationFlow.registration.description1"
        )}
      </Text>
      <Text size="lg" mb={5}>
        {t(
          "spaces.settings.registration.registrationFlow.registration.description2"
        )}
      </Text>
      <MantineProvider theme={{ primaryShade: 6 }}>
        <Select
          value={trigger}
          onChange={setTrigger}
          placeholder={t(
            "spaces.settings.registration.registrationFlow.registration.placeholder1"
          )}
          itemComponent={SelectItem}
          data={triggerOptions}
          maxDropdownHeight={400}
          radius="md"
          size="lg"
        />
      </MantineProvider>
      {AddStepBlock(0)}
      <DragDropContext
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragEnd={({ destination, source }) => {
          handlers.reorder({ from: source.index, to: destination.index });
          setIsDragging(false);
        }}
      >
        <Droppable droppableId="dnd-list" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <MantineProvider theme={{ primaryShade: 5 }}>
        <div className={classes.addStepButtonWrap}>
          <Button
            onClick={() => {
              setAddStepOpened(true);
              setStepPosition(null);
            }}
            classNames={{
              outline: classes.addStepButtonHover,
            }}
            variant="outline"
            color="gray"
            radius="xl"
          >
            {t(
              "spaces.settings.registration.registrationFlow.registration.addButton"
            )}
          </Button>
        </div>
      </MantineProvider>
      <EditStep
        opened={settingsOpened}
        setOpened={setSettingsOpened}
        step={selectedStep}
        journey={journey}
        onDestroy={onDestroy}
        trigger={trigger}
        settings={space}
        onSettingsChange={onSettingsChange}
      />
      <AddStep
        opened={addStepOpened}
        setOpened={setAddStepOpened}
        position={stepPosition}
        journey={journey}
        setTriggerEdit={setTriggerEdit}
        trigger={trigger}
        settings={space}
        onSettingsChange={onSettingsChange}
      />
    </>
  );
}
