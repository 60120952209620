import React, { useState } from "react";
import { Text, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";

import Coupons from "./Coupons";
import FreeTrial from "./FreeTrial";
import Bundles from "./Bundles";

import { Accordion } from "@mantine/core";

export default function Extras(props) {
  const { t, i18n } = useTranslation();
  const [accordionState, setAccordionState] = useState({
    0: false,
    1: false,
  });

  const AccordionLabel = ({ label, description, index }) => {
    return (
      <Group noWrap>
        <div>
          <Text size="lg" weight={700}>
            {label}
          </Text>

          {!accordionState[index] ? (
            <Text size="sm" color="dimmed" weight={400}>
              {description}
            </Text>
          ) : (
            ""
          )}
        </div>
      </Group>
    );
  };

  return (
    <Accordion>
      <Accordion.Item
        label={
          <AccordionLabel
            index={0}
            label={"Coupons"}
            key={"coupons"}
            description={`Coupons allow you to offer custom discounts on space payments.`}
          />
        }
      >
        <Coupons />
      </Accordion.Item>

      {/* <Accordion.Item label="Free trial">
        <FreeTrial />
      </Accordion.Item> */}

      <Accordion.Item
        label={
          <AccordionLabel
            index={0}
            label={"Bundles"}
            key={"bundles"}
            description={`Bundles allow you to package access to multiple spaces with one purchase.`}
          />
        }
      >
        <Bundles />
      </Accordion.Item>
    </Accordion>
  );
}
