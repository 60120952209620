import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  NativeSelect,
  Select,
  TextInput,
  Group,
} from '@mantine/core'

import Fees from './Fees'
import Continuously from './charging/Continuously'
import NumberOfTimes from './charging/NumberOfTimes'
import UntilDate from './charging/UntilDate'

import FreeTrial from '../payment/FreeTrial';


const data = [
  { value: 'usd', label: '🇺🇸 USD' },
  { value: 'eur', label: '🇪🇺 EUR' },
  { value: 'cad', label: '🇨🇦 CAD' },
  { value: 'aud', label: '🇦🇺 AUD' },
  { value: 'gbp', label: '🇬🇧 GBP' },
  { value: 'sek', label: '🇸🇪 SEK' },
  { value: 'mxn', label: '🇲🇽 MXN' },
  { value: 'inr', label: '🇮🇳 INR' },
  { value: 'chf', label: '🇨🇭 CHF' },
  { value: 'nzd', label: '🇳🇿 NZD' },
];

const frequency = [
  { value: 'per month', label: 'per month' },
  { value: 'per year', label: 'per year' }
];

const chargeOptions = [
  { value: 'ongoing',
    label: 'Charge continuously',
    description: 'Members will be charged on every billing cycle until the subscription is cancelled'
  },
  { value: 'number_of_times',
    label: 'Charge number of times',
    description: 'Members will be charged on every billing cycle for a set number of times'
  },
  { value: 'until_date',
    label: 'Charge until date',
    description: 'Members will be charged on every billing cycle until a set date'
  }
];

export default function Recurring(props) {

  const [subscriptionType, setSubscriptionType] = useState("ongoing");
  const [currency, setCurrency] = useState("usd");
  const [defaultPaymentAmount, setDefaultPaymentAmount] = useState();
  const [defaultPaymentFrequency, setDefaultPaymentFrequency] = useState('per month');
  const [untilDate, setUntilDate] = useState("");
  const [numberOfTimes, setNumberOfTimes] = useState(1);
  const [disableActions, setDisableActions] = useState(false);
  const { t, i18n } = useTranslation();
  const propsLoaded = useRef()
  const defaultUpdated = useRef()
  const lastSpaceId = useRef();
  const timoutId = useRef();

  useEffect(() => {
    return () => {
      propsLoaded.current = false
    };
  }, []);

  useEffect(() => {
    if (props.settings){

      if (propsLoaded.current && props.settings.slug == lastSpaceId.current && props.settings.payment_settings.default_payment_frequency != "once"){
        return
      }
      propsLoaded.current = false
      // setDisableActions(true)
      lastSpaceId.current = props.settings.slug

      setCurrency(props.settings.payment_settings.currency)
      setDefaultPaymentAmount(props.settings.payment_settings.default_payment_amount)
      setDefaultPaymentFrequency(props.settings.payment_settings.default_payment_frequency == "once" ? "per month" : props.settings.payment_settings.default_payment_frequency)

      if (["ongoing", "number_of_times", "until_date"].indexOf(props.settings.payment_settings.subscription_type) >= 0){
        setSubscriptionType(props.settings.payment_settings.subscription_type)
      }
      else{
        setSubscriptionType("ongoing")
      }

      if (props.settings.payment_settings.until_date){
        setUntilDate(new Date(props.settings.payment_settings.until_date))
      }
      setNumberOfTimes(props.settings.payment_settings.number_of_times)


      setTimeout(() => {
        propsLoaded.current = true
        // setDisableActions(false)
      }, 500)

      if (props.settings.payment_settings.default_payment_frequency == "once" && !defaultUpdated.current){
        defaultUpdated.current = true
        props.onUpdate({pod_payment_settings_attributes: {default_payment_frequency: "per month", subscription_type: "ongoing"}})
      }
    }
  }, [props])

  useEffect(() => {
    if (propsLoaded.current && currency){
      if (props.updateAll){
        updateAll()
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {currency: currency}})
      }
    }
  }, [currency])

  useEffect(() => {
    if (propsLoaded.current && defaultPaymentAmount){
      

      if (timoutId.current){
        clearTimeout(timoutId.current)
      }

      timoutId.current = setTimeout(() => {
        if (props.updateAll){
          updateAll()
        }
        else{
          props.onUpdate({pod_payment_settings_attributes: {default_payment_amount: defaultPaymentAmount}})
        }
      }, 1000)
    }
  }, [defaultPaymentAmount])

  useEffect(() => {
    if (propsLoaded.current && defaultPaymentFrequency){
      if (props.updateAll){
        updateAll()
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {default_payment_frequency: defaultPaymentFrequency}})
      }
    }
  }, [defaultPaymentFrequency])

  useEffect(() => {
    if (propsLoaded.current && untilDate){
      if (props.updateAll){
        updateAll()
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {until_date: untilDate.toISOString()}})
      }
    }
  }, [untilDate])

  useEffect(() => {
    if (propsLoaded.current && numberOfTimes){
      if (props.updateAll){
        updateAll()
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {number_of_times: numberOfTimes}})
      }
    }
  }, [numberOfTimes])

  useEffect(() => {
    if (propsLoaded.current && subscriptionType){
      if (props.updateAll){
        updateAll()
      }
      else{
        props.onUpdate({pod_payment_settings_attributes: {subscription_type: subscriptionType}})
      }
    }
  }, [subscriptionType])

  const updateAll = () => {
    props.onUpdate({
      pod_payment_settings_attributes: {
        currency: currency,
        default_payment_amount: defaultPaymentAmount,
        default_payment_frequency: defaultPaymentFrequency,
        until_date: untilDate ? untilDate.toISOString() : null,
        number_of_times: numberOfTimes,
        subscription_type: subscriptionType
      },
      circle_payment: "recurring"
    })
  }

  const displayChargeSelection = () => {
    if (subscriptionType == "ongoing") {
      return (
        <Continuously />
      )
    } else if (subscriptionType == "number_of_times") {
      return (
        <NumberOfTimes
          numberOfTimes={numberOfTimes}
          setNumberOfTimes={setNumberOfTimes}/>
      )
    } else if (subscriptionType == "until_date") {
      return (
        <UntilDate
          untilDate={untilDate}
          setUntilDate={setUntilDate}/>
      )
    } else {
      return (
        <Continuously />
      )
    }
  }

  const SelectItem = (({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="lg" weight={600}>{label}</Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

return (
  <Box mt={20} mb={20}>
    <Text
      mb={8}
      color="dimmed"
      >
      {t("spaces.settings.payment.recurring.recurring.description1")}
    </Text>

    <Group
      noWrap
      grow
      spacing={0}
      >
      <NativeSelect
        disabled={disableActions}
        value={currency}
        onChange={(event) => setCurrency(event.currentTarget.value)}
        data={data}
        size="lg"
        radius="md"
        styles={{
          input: {
            fontWeight: 500,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
        />

      <TextInput
        disabled={disableActions}
        value={defaultPaymentAmount}
        onChange={(event) => setDefaultPaymentAmount(event.target.value)}
        type="number"
        placeholder="1111"
        size="lg"
        radius="md"
        styles={{
          input: {
            fontWeight: 500,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderLeft: "none",
            borderRight: "none",
          },
        }}
        />

      <NativeSelect
        value={defaultPaymentFrequency}
        disabled={disableActions}
        onChange={(event) => setDefaultPaymentFrequency(event.currentTarget.value)}
        data={frequency}
        size="lg"
        radius="md"
        styles={{
          input: {
            fontWeight: 500,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        }}
        />
    </Group>
    {props.settings && (
      <Fees
        fee={props.settings.fee}/>
    )}

    <Text
      mb={8}
      color="dimmed"
      mt={20}
      >
      {t("spaces.settings.payment.recurring.recurring.description2")}
    </Text>

    <Select
      disabled={disableActions}
      value={subscriptionType}
      onChange={setSubscriptionType}
      data={chargeOptions}
      size="lg"
      radius="md"
      itemComponent={SelectItem}
      maxDropdownHeight={400}

      styles={{
        input: {
          fontWeight: 500,
        },
      }}
      />

    {displayChargeSelection()}

    <FreeTrial />

    {/*
      <RadioGroup
      orientation="vertical"
      size="lg"
      mt={20}
      >
      { chargeOptions.map((option) => {
      return (
      <Radio
      value={option.value}
      label={option.label} />
      )
      })}
      </RadioGroup>
      */}

    </Box>
  )
}
