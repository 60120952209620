import React, { useState, useEffect } from "react";

import {
  Text,
  Box,
  Button,
  Divider,
  MultiSelect,
  Checkbox,
  Alert,
  MantineProvider,
  Select,
  Switch,
  Group,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Payment from "../privacy/Paid";

import { useForm } from "@mantine/form";

import InvitationModel from "../../../../models/Invitation";

import Swal from "sweetalert2";

import { AlertCircle } from "tabler-icons-react";

import { Link } from "react-router-dom";

export default function InvitePeople(props) {
  const { t, i18n } = useTranslation();

  const space = useSelector((state) => state.space);
  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [selectedActiveMembers, setSelectedActiveMembers] = useState([]);
  const [selectedPendingMembers, setSelectedPendingMembers] = useState([]);
  const [activeMembers, setActiveMembers] = useState([]);
  const [pendingMembers, setPendingMembers] = useState([]);
  const [spaces, setSpaces] = useState([]);
  const [checked, setChecked] = useState(true);
  const [allowCopySpaces, setAllowCopySpaces] = useState(false);
  const [allowCopyActiveMembers, setCopyAllowActiveMembers] = useState(false);
  const [addToSubspaces, setAddToSubspaces] = useState(false);
  const [allowCopyPendingMembers, setCopyAllowCopyPendingMembers] =
    useState(false);

  const [settings, setSettings] = useState({});

  const form = useForm({
    initialValues: {
      add_people: "",
      message: "",
      notify_members: true,
      circle_payment: "",
      currency: "",
      payment_amount: "",
      payment_frequency: "",
      subscription_type: "",
      until_date: "",
      number_of_times: "",
      role: "member",
      add_to_subspaces: false,
    },
  });

  useEffect(() => {
    setAllowCopySpaces(props.allowCopySpaces);
    setCopyAllowActiveMembers(props.allowCopyActiveMembers);
    setCopyAllowCopyPendingMembers(props.allowCopyPendingMembers);

    setSpaces(props.spaces);
    setActiveMembers(props.activeMembers);
    setPendingMembers(props.pendingMembers);

    if (props.settings) {
      setSettings({ ...props.settings });
      onUpdatePaymentFromProps(props.settings.payment_settings);
    }
  }, [props]);

  const getOnlyMembers = (array) => {
    return array.filter((value) => value.indexOf("SPACE-") < 0);
  };

  const getOnlySpaces = (array) => {
    return array
      .filter((value) => value.indexOf("SPACE") >= 0)
      .map((value) => value.replace("SPACE-", ""));
  };

  const onInvite = () => {
    const params = {
      copy_members_from: selectedSpaces ? selectedSpaces.join(",") : "",
      active_members: getOnlyMembers([...selectedActiveMembers]).join(","),
      pending_members: getOnlyMembers([...selectedPendingMembers]).join(","),
      active_all: getOnlySpaces([...selectedActiveMembers]).join(","),
      pending_all: getOnlySpaces([...selectedPendingMembers]).join(","),
      notify_members: form.values.notify_members,
    };

    InvitationModel.onCreate(
      params,
      space.slug,
      (data) => {
        Swal.fire(data.title, data.message, "success");
        props.setOpened(false);
        props.setRefresh(true);
      },
      (data) => {
        Swal.fire(data.title, data.message, "error");
      }
    );
  };

  const onUpdatePaymentFromProps = (params) => {
    if (!params.payment_settings) {
      return;
    }

    if (params.circle_payment) {
      form.setFieldValue("circle_payment", params.circle_payment);
    }
    if (params.payment_settings.default_payment_amount) {
      form.setFieldValue(
        "payment_amount",
        params.payment_settings.default_payment_amount
      );
    }
    if (params.payment_settings.default_payment_frequency) {
      form.setFieldValue(
        "payment_frequency",
        params.payment_settings.default_payment_frequency
      );
    }
    if (params.payment_settings.subscription_type) {
      form.setFieldValue(
        "subscription_type",
        params.payment_settings.subscription_type
      );
    }
    if (params.payment_settings.until_date) {
      form.setFieldValue("until_date", params.payment_settings.until_date);
    }
    if (params.payment_settings.number_of_times) {
      form.setFieldValue(
        "number_of_times",
        params.payment_settings.number_of_times
      );
    }
  };

  const onUpdatePayment = (params) => {
    if (!params.pod_payment_settings_attributes) {
      return;
    }

    const auxSettings = { ...settings };

    if (params.circle_payment) {
      form.setFieldValue("circle_payment", params.circle_payment);
    }
    if (params.pod_payment_settings_attributes.default_payment_amount) {
      form.setFieldValue(
        "payment_amount",
        params.pod_payment_settings_attributes.default_payment_amount
      );
    }
    if (params.pod_payment_settings_attributes.default_payment_frequency) {
      form.setFieldValue(
        "payment_frequency",
        params.pod_payment_settings_attributes.default_payment_frequency
      );
    }
    if (params.pod_payment_settings_attributes.subscription_type) {
      form.setFieldValue(
        "subscription_type",
        params.pod_payment_settings_attributes.subscription_type
      );
    }
    if (params.pod_payment_settings_attributes.until_date) {
      form.setFieldValue(
        "until_date",
        params.pod_payment_settings_attributes.until_date
      );
    }
    if (params.pod_payment_settings_attributes.number_of_times) {
      form.setFieldValue(
        "number_of_times",
        params.pod_payment_settings_attributes.number_of_times
      );
    }

    auxSettings.circle_payment = form.values.circle_payment;
    auxSettings.payment_settings.currency = form.values.currency;
    auxSettings.payment_settings.default_payment_amount =
      form.values.payment_amount;
    auxSettings.payment_settings.default_payment_frequency =
      form.values.payment_frequency;
    auxSettings.payment_settings.subscription_type =
      form.values.subscription_type;
    auxSettings.payment_settings.until_date = form.values.until_date;
    auxSettings.payment_settings.number_of_times = form.values.number_of_times;
    setSettings(auxSettings);
  };

  return (
    <Box mt={5} p={10}>
      {allowCopySpaces || allowCopyActiveMembers || allowCopyPendingMembers ? (
        <form onSubmit={form.onSubmit((values) => onInvite(values))}>
          {allowCopySpaces ? (
            <>
              <Box>
                <Text size="sm">
                  {t(
                    "spaces.settings.members.inviteExisting.invite.copyMembers"
                  )}
                </Text>
                <MultiSelect
                  mt={5}
                  searchable
                  data={spaces}
                  onChange={setSelectedSpaces}
                  placeholder={t(
                    "spaces.settings.members.inviteExisting.invite.copyMembersPlaceholder"
                  )}
                  value={selectedSpaces}
                />
              </Box>
              <Divider
                mt={20}
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.colors.gray[2]}`,
                })}
              />
            </>
          ) : (
            ""
          )}
          {allowCopyActiveMembers ? (
            <>
              <Box mt={10}>
                <Text size="sm">
                  {t(
                    "spaces.settings.members.inviteExisting.invite.addActiveMembers"
                  )}
                </Text>
                <MultiSelect
                  searchable
                  mt={5}
                  data={activeMembers}
                  onChange={setSelectedActiveMembers}
                  placeholder={t(
                    "spaces.settings.members.inviteExisting.invite.addActiveMembersPlaceholder"
                  )}
                  value={selectedActiveMembers}
                />
              </Box>
              <Divider
                mt={20}
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.colors.gray[2]}`,
                })}
              />
            </>
          ) : (
            ""
          )}
          {allowCopyPendingMembers ? (
            <>
              <Box mt={10}>
                <Text size="sm">
                  {t(
                    "spaces.settings.members.inviteExisting.invite.addPendingMembers"
                  )}
                </Text>
                <MultiSelect
                  searchable
                  mt={5}
                  data={pendingMembers}
                  onChange={setSelectedPendingMembers}
                  placeholder={t(
                    "spaces.settings.members.inviteExisting.invite.addPendingMembersPlaceholder"
                  )}
                  value={selectedPendingMembers}
                />
              </Box>
              <Divider
                mt={20}
                sx={(theme) => ({
                  borderTop: `1px solid ${theme.colors.gray[2]}`,
                })}
              />
            </>
          ) : (
            ""
          )}
          <Select
            {...form.getInputProps("role")}
            mt={10}
            radius="sm"
            size="md"
            label="Add as"
            placeholder="Pick one"
            data={[
              { value: "member", label: "Member" },
              { value: "editor", label: "Editor" },
              { value: "manager", label: "Manager" },
            ]}
          />

          {settings && settings.can_accept_charges && settings.is_paid && (
            <Payment
              settings={settings}
              updateAll={true}
              onUpdate={onUpdatePayment}
              space={props.space}
              options={["paid", "flexible", "recurring"]}
              inner={true}
            />
          )}

          {space.slug && space.permissions.can_send_email_invites[0] ? (
            <Checkbox
              {...form.getInputProps("notify_members")}
              mt={15}
              onLabel="ON"
              offLabel="OFF"
              label={t(
                "spaces.settings.members.inviteExisting.invite.checkboxLabel"
              )}
              checked={form.values.notify_members}
            />
          ) : (
            <Alert
              icon={<AlertCircle size={55} />}
              color="green"
              radius="md"
              mt={15}
            >
              <Text>
                {t("spaces.settings.members.inviteExisting.invite.alert")}
              </Text>
              <MantineProvider theme={{ primaryShade: 5 }}>
                <Button
                  variant="filled"
                  radius="xl"
                  color="green"
                  mt={15}
                  mb={5}
                  component={Link}
                  to={`/app/pricing`}
                >
                  {t(
                    "spaces.settings.members.inviteExisting.invite.alertButton"
                  )}
                </Button>
              </MantineProvider>
            </Alert>
          )}
          <Checkbox
            {...form.getInputProps("add_to_subspaces")}
            mt={15}
            onLabel="ON"
            offLabel="OFF"
            label={"Add to all direct subspaces"}
          />
          <Button
            variant="filled"
            radius="xl"
            mt={20}
            type="submit"
            sx={(theme) => ({
              backgroundColor: theme.colors.sutrablue[3],
              "&:hover": {
                backgroundColor: theme.colors.sutrablue[4],
              },
            })}
          >
            {t("spaces.settings.members.inviteExisting.invite.button")}
          </Button>
        </form>
      ) : (
        <Text>
          {t("`spaces.settings.members.inviteExisting.invite.thisOption`")}
        </Text>
      )}
    </Box>
  );
}
