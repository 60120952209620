import React, { useState, useEffect, useRef } from "react";

import SpaceModel from "../../models/Space";
import StripeModel from "../../models/Stripe";
import { useTranslation } from "react-i18next";

import getSymbolFromCurrency from "currency-symbol-map";

import {
  TextInput,
  Checkbox,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Box,
  Divider,
  Image,
  createStyles,
  ActionIcon,
  Button,
  Stack,
} from "@mantine/core";

import { useMediaQuery } from "@mantine/hooks";

import { DateTime } from "luxon";

import useLangUser from "../../utils/LangUser";

import TiptapReadonly from "../tiptap/components/TiptapReadonly";

import { ArrowRight } from "tabler-icons-react";

import { useLocation } from "react-router-dom";
import { select } from "underscore";

import { getCookie, setCookie } from "../../utils/Cookies";
import { spanStatusfromHttpCode } from "@sentry/react";

const useStyles = createStyles(() => ({
  spaceContainer: {
    ".ProseMirror p": {
      color: "#000000",
    },
    ".ProseMirror a": {
      color: "#000000",
    },
    ".ProseMirror .file-node svg": {
      stroke: "#000000",
    },
    ".ProseMirror .file-node a": {
      color: "#000000",
    },
  },
}));

export default function Start(props) {
  const { t, i18n } = useTranslation();

  const smallScreen = useMediaQuery("(max-width: 768px)");

  const [space, setSpace] = useState(null);

  const [checked, setChecked] = useState([]);
  const [total, setTotal] = useState(0);
  const [isMember, setIsMember] = useState(false);
  const [otherOption, setOtherOption] = useState("");
  const [addCoupon, setAddCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [hasCoupons, setHasCoupons] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [showBundles, setShowBundles] = useState("regular");
  const [showBundlesId, setShowBundlesId] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const normalPrice = useRef(0);
  const frequency = useRef("once");
  const currency = useRef("once");
  const loaded = useRef(null);

  const currentLocation = useLocation();

  const { language, setLangUser } = useLangUser();

  const { classes, cx, theme } = useStyles();

  useEffect(() => {
    if (!space || space.slug != props.slug) {
      fetchSpace();
    }

    if (loaded.current) {
      clearTimeout(loaded.current);
    }

    loaded.current = setTimeout(() => {
      if (currentLocation.pathname.indexOf("/pay") >= 0) {
        setCookie("bundles", "null", 0);
      }
    }, 5000);

    setIsMember(props.isMember);

    const search = currentLocation.search;
    let show_bundles = new URLSearchParams(search).get("bundles");
    const cookie_bundles = getCookie("bundles");

    if (cookie_bundles && cookie_bundles != "null") {
      show_bundles = cookie_bundles;
    } else if (show_bundles) {
      setCookie("bundles", show_bundles, 365 * 10);
    }

    if (show_bundles == "bundles") {
      setShowBundles("bundles");
      return;
    }
    if (show_bundles == "all") {
      setShowBundles("all");
      return;
    }
    if (!show_bundles || show_bundles == "regular") {
      setShowBundles("regular");
      return;
    }
    if (show_bundles) {
      setShowBundles("id");
      setShowBundlesId(show_bundles);
      return;
    }
  }, [props]);

  useEffect(() => {
    if (selectedBundle) {
      window.$selectedBundleId = selectedBundle.id;
      try {
        props.onPriceChange(
          selectedBundle.default_payment_amount,
          selectedBundle.currency,
          selectedBundle.default_payment_frequency
        );
      } catch {}
    } else {
      window.$selectedBundleId = null;
    }
  }, [selectedBundle]);

  const fetchSpace = () => {
    SpaceModel.onFetchSimple(
      props.slug,
      "payment",
      (data) => {
        setSpace({ ...data });
        setShowBundles(true);
        setBundles([...data.bundles]);
        setHasCoupons(data.has_coupons);
        if (props.setShowNewsLetter){
          props.setShowNewsLetter(data.show_newsletter);
        }
        // if (!isMember){
        //   return
        // }
        if (data.privacy_level === "private" && props.setShowSpaceReference) {
          props.setShowSpaceReference(false);
        } else {
          props.setShowSpaceReference(true);
        }

        if (props.setProviders) {
          props.setProviders([...data.sso_providers]);
        }

        if (data.is_paid && props.setStepper) {
          props.setStepper(true);
        }

        currency.current = data.payment.currency;
        frequency.current = data.payment.frequency;
        if (
          !data.payment.is_flexible ||
          data.payment.invitation_has_different_price
        ) {
          window.$selectedPriceToPayCoupon = data.payment.price;
          setTotal(data.payment.price);
          normalPrice.current = [
            data.payment.price,
            currency.current,
            data.payment.frequency ? data.payment.frequency : "once",
          ];

          props.onPriceChange(
            data.payment.price,
            currency.current,
            data.payment.frequency ? data.payment.frequency : "once"
          );
          return;
        } else if (window.$selectedPriceToPay) {
          window.$selectedPriceToPayCoupon = window.$selectedPriceToPay;
          setChecked([...window.$selectedPriceToPay]);
          setTotal(sumTotal());
          normalPrice.current = [
            window.$selectedPriceToPay,
            currency.current,
            window.$selectedPriceToPayFrequency ? window.$selectedPriceToPayFrequency : "once",
          ];
        } else {
          window.$selectedPriceToPayCoupon = data.payment.price[0].amount;
          window.$selectedPriceToPay = [data.payment.price[0].amount];
          window.$selectedPriceToPayFrequency = data.payment.price[0].frequency;
          setChecked([...window.$selectedPriceToPay]);
          normalPrice.current = [
            data.payment.price[0].amount,
            currency.current,
            data.payment.price[0].frequency ? data.payment.price[0].frequency : "once",
          ];
          setTotal(sumTotal());
        }
        if (props.onPriceChange) {
          props.onPriceChange(
            sumTotal(),
            currency.current,
            window.$selectedPriceToPayFrequency ? window.$selectedPriceToPayFrequency : "once"
          );
        }
      },
      (data) => {
        props.setSpaceReference(null);
      }
    );
  };

  const onSelectPrice = (price) => {
    let highest = 0;
    for (var i = 0; i < space.payment.price.length; i++) {
      const auxPrice = space.payment.price[i];
      if (auxPrice.amount > highest) {
        highest = auxPrice.amount;
      }
    }
    if (highest == 0) {
      highest = 100;
    }
    if (window.$selectedPriceToPay == price.amount) {
      return;
    }
    window.$selectedPriceToPay = [
      price.amount || price.amount == 0 ? price.amount : highest * 1.5,
    ];

    setChecked([...window.$selectedPriceToPay]);
    setTotal(sumTotal());
    if (props.onPriceChange) {
      props.onPriceChange(
        sumTotal(),
        space.payment.currency,
        price.frequency ? price.frequency : "once"
      );
    }
  };

  const onApplyCode = (event) => {
    if (event) {
      event.stopPropagation();
    }

    StripeModel.onApplyCode(
      props.slug,
      couponCode,
      window.$selectedPriceToPayCoupon,
      (data) => {
        setTotal(data.data.new_total);
        window.$selectedCouponCode = couponCode;
        window.$selectedCoupon = {
          value: data.data.value,
          type: data.data.type,
        };

        props.onPriceChange(
          window.$selectedPriceToPayCoupon,
          currency.current,
          frequency.current ? frequency.current : "once"
        );
      },
      (data) => {}
    );
  };

  const sumTotal = () => {
    let auxTotal = 0;
    if (window.$selectedPriceToPay) {
      for (let i = 0; i < window.$selectedPriceToPay.length; i++) {
        auxTotal += window.$selectedPriceToPay[i];
      }
    }
    return auxTotal;
  };

  const ensure2Dec = (value) => {
    value = value ? parseFloat(value) : 0;
    return value.toFixed(2).replace(".00", "");
  };

  const isOtherOption = (price) => {
    if (!price.amount) {
      const sum = sumTotal();
      let count = 0;
      for (let i = 0; i < space.payment.price.length; i++) {
        if (space.payment.price[i].amount != sum) {
          count++;
        }
      }
      return count == space.payment.price.length;
    } else {
      return false;
    }
  };

  const getFrequency = () => {
    if (!space) {
      return "";
    }
    if (!space.payment) {
      return "";
    }
    if (!space.payment.frequency) {
      return "";
    }
    return space.payment.frequency != "once"
      ? ` / ${space.payment.frequency.replace("per ", "")}`
      : "";
  };

  const getPaymentDetails = () => {
    if (space.payment.frequency == "once") {
      return `One time payment of ${getSymbolFromCurrency(
        space.payment.currency
      )}${ensure2Dec(space.payment.price)}`;
    }
    if (space.payment.subscription_type == "ongoing") {
      if (space.payment.frequency == "per month") {
        return `Monthly subscription of ${getSymbolFromCurrency(
          space.payment.currency
        )}${ensure2Dec(space.payment.price)}${getFrequency()}`;
      } else {
        return `Annual subscription of ${getSymbolFromCurrency(
          space.payment.currency
        )}${ensure2Dec(space.payment.price)}${getFrequency()}`;
      }
    }
    if (space.payment.number_of_times && space.payment.number_of_times > 0) {
      return `${space.payment.number_of_times} ${
        space.payment.number_of_times == 1 ? "payment" : "payments"
      } of ${getSymbolFromCurrency(space.payment.currency)}${ensure2Dec(
        space.payment.price
      )}`;
    }
    if (space.payment.until_date) {
      return `${getSymbolFromCurrency(space.payment.currency)}${ensure2Dec(
        space.payment.price
      )} ${getFrequency()} until ${DateTime.fromISO(space.payment.until_date)
        .setLocale(language)
        .toFormat("MMM d, y")}`;
    }
    return `${getSymbolFromCurrency(space.payment.currency)}${ensure2Dec(
      space.payment.price
    )}`;
  };

  const getPaymentDetailsFlexible = (item) => {
    if (
      item.frequency == "once" ||
      item.frequency == "" ||
      item.frequency == null
    ) {
      return "";
    } else {
      return item.frequency.replace("per", "/");
    }
  };

  const getTextValue = (price) => {
    const value =
      window.$selectedPriceToPay && isOtherOption(price)
        ? window.$selectedPriceToPay
        : 0;
    try {
      console.log(value);
      return value[0] ? value[0] : 0;
    } catch (e) {
      return value ? value : 0;
    }
  };

  const onOtherSelected = (frequency) => {
    if (otherOption != "0" && otherOption != null && otherOption != "") {
      onSelectPrice({
        amount: parseFloat(otherOption),
        frequency: frequency ? frequency : "once",
      });
    } else {
      setOtherOption("0");
      onSelectPrice({
        amount: parseFloat(0),
        frequency: frequency ? frequency : "once",
      });
    }
  };

  const getBundlePaymentDetails = (bundle) => {
    if (bundle.default_payment_frequency == "once") {
      return `One time payment of ${getSymbolFromCurrency(
        bundle.currency
      )}${ensure2Dec(bundle.default_payment_amount)}`;
    }
    if (bundle.subscription_type == "ongoing") {
      if (bundle.default_payment_frequency == "per month") {
        return `Monthly subscription of ${getSymbolFromCurrency(
          bundle.currency
        )}${ensure2Dec(bundle.default_payment_amount)}${getFrequency()}`;
      } else {
        return `Annual subscription of ${getSymbolFromCurrency(
          bundle.currency
        )}${ensure2Dec(bundle.default_payment_amount)}${getFrequency()}`;
      }
    }
    if (bundle.number_of_times && bundle.number_of_times > 0) {
      return `${bundle.number_of_times} ${
        bundle.number_of_times == 1 ? "payment" : "payments"
      } of ${getSymbolFromCurrency(bundle.currency)}${ensure2Dec(
        bundle.default_payment_amount
      )}`;
    }
    if (bundle.until_date) {
      return `${getSymbolFromCurrency(bundle.currency)}${ensure2Dec(
        bundle.default_payment_amount
      )} ${getFrequency()} until ${DateTime.fromISO(bundle.until_date)
        .setLocale(language)
        .toFormat("MMM d, y")}`;
    }
    return `${getSymbolFromCurrency(bundle.currency)}${ensure2Dec(
      bundle.default_payment_amount
    )}`;
  };

  const filterBundles = () => {
    if (bundles.length == 0 || isMember) {
      return [];
    }

    if (!showBundlesId) {
      return bundles.filter((item) => item.show_in_checkout);
    } else if (showBundlesId == "all") {
      return bundles;
    } else {
      const auxIds = showBundlesId.split(",");
      const auxBundles = bundles.filter(
        (item) => auxIds.indexOf(item.id + "") >= 0
      );

      if (!selectedBundle && auxBundles.length == 1) {
        setSelectedBundle(auxBundles[0]);
      }
      return auxBundles;
    }
  };

  const onSelectBundle = (bundle) => {
    if (!selectedBundle && !bundle.id) {
      return;
    }
    if (selectedBundle && selectedBundle.id == bundle.id) {
      setSelectedBundle(null);
      if (props.onPriceChange) {
        window.$selectedBundleId = null;
        props.onPriceChange(
          normalPrice.current[0],
          normalPrice.current[1],
          normalPrice.current[2]
        );
      }
    } else if (bundle && bundle.id) {
      setSelectedBundle(bundle);
    } else if (bundle && bundle.id == null) {
      setSelectedBundle(null);

      if (props.onPriceChange && !space.payment.is_flexible) {
        window.$selectedBundleId = null;

        props.onPriceChange(
          normalPrice.current[0],
          normalPrice.current[1],
          normalPrice.current[2]
        );
      }
    }
  };

  if (!space) {
    return null;
  }

  return (
    <Container
      size={500}
      mb={40}
      mt={smallScreen ? 10 : 40}
      className={classes.spaceContainer}
    >
      {space && (showBundles == "all" || showBundles == "regular") && (
        <Paper
          withBorder
          shadow="xs"
          p={0}
          mt={30}
          radius="md"
          onClick={() => onSelectBundle({ id: null })}
        >
          {space.header.url ? (
            <Image
              sx={(theme) => ({
                borderTopLeftRadius: theme.radius.md,
                borderTopRightRadius: theme.radius.md,
                overflow: "hidden",
              })}
              src={space.header.url}
              alt={space.name}
            />
          ) : (
            ""
          )}
          <Box pt={25} pb={8} pl={35} pr={35}>
            {space.top_parent && <Text dimmed>{space.top_parent}</Text>}
            <Title
              sx={(theme) => ({ fontSize: 24, fontWeight: 600 })}
              order={2}
              mb={20}
            >
              {space.name}
            </Title>
            {space.is_paid &&
              (!space.payment.is_flexible ||
                space.payment.invitation_has_different_price) &&
              !isMember && (
                <Text mt={5} size="lg" color="dimmed">
                  {`${getPaymentDetails()}`}
                </Text>
              )}
            {space.is_paid &&
              space.payment.is_flexible &&
              !isMember &&
              space.payment.description !== "" && (
                <>
                  <TiptapReadonly content={space.payment.description} />

                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                </>
              )}
            {space.is_paid &&
              space.payment.is_flexible &&
              !space.payment.invitation_has_different_price &&
              !isMember &&
              space.payment.price.map((price, index) => {
                return (
                  <Box mt={20}>
                    <Checkbox
                      radius="xl"
                      styles={{
                        label: {
                          width: "100%",
                        },
                        root: {
                          display: "flex",
                          alignItems: "flex-start",
                        },
                        input: {
                          marginTop: 2,
                        },
                        icon: {
                          marginTop: 9,
                        },
                      }}
                      label={
                        price.amount || !smallScreen ? (
                          <Box>
                            <Group
                              position="apart"
                              noWrap
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <Text>
                                {space.payment.formatted_price[index].content}
                              </Text>
                              {price.amount ? (
                                <Text>
                                  {`${getSymbolFromCurrency(
                                    space.payment.currency
                                  )}${ensure2Dec(
                                    price.amount
                                  )} ${getPaymentDetailsFlexible(price)}`}
                                </Text>
                              ) : (
                                <TextInput
                                  id={`other-value-${index}`}
                                  value={otherOption}
                                  styles={{
                                    root: {
                                      width: "80px",
                                    },
                                    input: {
                                      textAlign: "right",
                                    },
                                  }}
                                  // placeholder="10"
                                  onChange={(event) =>
                                    setOtherOption(event.currentTarget.value)
                                  }
                                  rightSection={
                                    <ActionIcon
                                      size={25}
                                      radius="xl"
                                      color={theme.primaryColor}
                                      variant="filled"
                                      disabled={
                                        otherOption == "" || otherOption == null
                                      }
                                    >
                                      <ArrowRight
                                        size={42}
                                        strokeWidth={2}
                                        color={"white"}
                                        onClick={(event) =>
                                          onSelectPrice({
                                            amount: parseFloat(otherOption),
                                            frequency: price.frequency,
                                          })
                                        }
                                      />
                                    </ActionIcon>
                                  }
                                />
                              )}
                            </Group>
                            {price.description && (
                              <Text color="dimmed" size="xs">
                                {price.description}
                              </Text>
                            )}
                          </Box>
                        ) : (
                          <Box>
                            <Stack
                              position="apart"
                              noWrap
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <Text>
                                {space.payment.formatted_price[index].content}
                              </Text>
                              {price.amount ? (
                                <Text>
                                  {`${getSymbolFromCurrency(
                                    space.payment.currency
                                  )}${ensure2Dec(
                                    price.amount
                                  )} ${getPaymentDetailsFlexible(price)}`}
                                </Text>
                              ) : (
                                <TextInput
                                  id={`other-value-${index}`}
                                  value={otherOption}
                                  styles={{
                                    root: {
                                      width: "100%",
                                    },
                                    input: {
                                      textAlign: "right",
                                    },
                                  }}
                                  // placeholder="10"
                                  onChange={(event) =>
                                    setOtherOption(event.currentTarget.value)
                                  }
                                  rightSection={
                                    <ActionIcon
                                      size={25}
                                      radius="xl"
                                      color={theme.primaryColor}
                                      variant="filled"
                                      disabled={
                                        otherOption == "" || otherOption == null
                                      }
                                    >
                                      <ArrowRight
                                        size={42}
                                        strokeWidth={2}
                                        color={"white"}
                                        onClick={(event) =>
                                          onSelectPrice({
                                            amount: parseFloat(otherOption),
                                            frequency: price.frequency,
                                          })
                                        }
                                      />
                                    </ActionIcon>
                                  }
                                />
                              )}
                            </Stack>
                            {price.description && (
                              <Text color="dimmed" size="xs">
                                {price.description}
                              </Text>
                            )}
                          </Box>
                        )
                      }
                      checked={
                        checked.indexOf(price.amount) >= 0 ||
                        isOtherOption(price)
                      }
                      onClick={(event) =>
                        price.amount &&
                        price.amount != window.$selectedPriceToPay
                          ? onSelectPrice(price)
                          : onOtherSelected(price.frequency)
                      }
                    />
                  </Box>
                );
              })}
            {space.is_paid && !isMember && hasCoupons ? (
              <>
                <Group>
                  {addCoupon && (
                    <TextInput
                      mt={10}
                      placeholder="DISCOUNT1212"
                      value={couponCode}
                      onChange={(event) =>
                        setCouponCode(event.currentTarget.value)
                      }
                      onClick={(event) => event.stopPropagation()}
                    />
                  )}
                  {addCoupon ? (
                    <Button
                      mt={10}
                      variant="outline"
                      onClick={(event) => onApplyCode(event)}
                    >
                      Apply coupon
                    </Button>
                  ) : (
                    <Button
                      mt={10}
                      variant="outline"
                      onClick={(event) => {
                        event.stopPropagation();
                        setAddCoupon(true);
                      }}
                    >
                      + Add coupon
                    </Button>
                  )}
                </Group>
                <Divider
                  sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                  mt={20}
                  mb={15}
                />
              </>
            ) : (
              <Box mb={15}></Box>
            )}

            {space.is_paid && !isMember && (
              <Group position="apart" mb={10}>
                {space.payment.has_trial && space.payment.trial_period > 0 ? (
                  <Text size="lg">{`Total due in ${space.payment.trial_period} days`}</Text>
                ) : (
                  <Text size="lg">{t("users.spaceReference.total")}</Text>
                )}
                <Text size="lg" sx={(theme) => ({ fontWeight: 600 })}>
                  {`${getSymbolFromCurrency(
                    space.payment.currency
                  )}${ensure2Dec(total)}`}
                </Text>
              </Group>
            )}
          </Box>
        </Paper>
      )}

      {filterBundles().map((bundle) => {
        return (
          <Paper
            withBorder
            shadow="xs"
            p={0}
            mt={30}
            radius="md"
            onClick={() => onSelectBundle(bundle)}
          >
            <Box pt={25} pb={8} pl={35} pr={35}>
              <Group sx={{ alignItems: "center" }} mb={20}>
                {(filterBundles().length > 1 ||
                  showBundles == "regular" ||
                  showBundles == "all") && (
                  <Checkbox
                    size="lg"
                    checked={selectedBundle && selectedBundle.id == bundle.id}
                  />
                )}
                <Title
                  sx={(theme) => ({ fontSize: 24, fontWeight: 600 })}
                  order={2}
                >
                  {bundle.name}
                </Title>
              </Group>
              {!bundle.is_flexible && !isMember && (
                <Text mt={5} mb={10} size="lg" color="dimmed">
                  {`${getBundlePaymentDetails(bundle)}`}
                </Text>
              )}
              {!isMember && bundle.description !== "" && (
                <>
                  <TiptapReadonly content={bundle.description} />

                  <Divider
                    sx={(theme) => ({ borderTopColor: theme.colors.gray[2] })}
                    my="sm"
                  />
                </>
              )}

              {!isMember && (
                <Group position="apart" mb={10}>
                  {space.payment.has_trial ? (
                    <Text size="lg">{`Total due in ${space.payment.trial_period} days`}</Text>
                  ) : (
                    <Text size="lg">{t("users.spaceReference.total")}</Text>
                  )}
                  <Text size="lg" sx={(theme) => ({ fontWeight: 600 })}>
                    {`${getSymbolFromCurrency(bundle.currency)}${ensure2Dec(
                      bundle.default_payment_amount
                    )}`}
                  </Text>
                </Group>
              )}
            </Box>
          </Paper>
        );
      })}
    </Container>
  );
}
