import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MessageModel from "../../../../models/Message";
import SpaceSettingModel from "../../../../models/SpaceSetting";

import TiptapInline from "../../../tiptap/components/TiptapInline";

import { storeJSON } from "../../../../utils/LocalDB";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import defaultSpaceColors from '../../../../collections/defaultSpaceColors'

import {
  createStyles,
  Box,
  Paper,
  Group,
  Switch,
  Center,
} from "@mantine/core";

import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme, { colors, props }) => ({
  panelWrap: {
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
    backgroundColor: colors.sidebar_background_color,
  },
}));

export default function Comments(props) {
  const { t, i18n } = useTranslation();
  const space = useSelector((state) => state.space);
  const space2 = useSelector((state) => state.space2);
  
  const navigate = useNavigate()
  
  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  const user = useSelector((state) => state.user);
  const colors = useSelector((state) => state.colors);
  const colors2 = useSelector((state) => state.colors2);

  const { classes, cx, theme } = useStyles({ colors: getColors() });

  const [saving, setSaving] = useState(false);
  const [allowComments, setAllowComments] = useState(false);

  const content = useRef();
  const mounted = useRef();

  useEffect(() => {
    if (!getSpace().slug){
      return
    }
    if (!window.$currentSpaceAllowComments){
      setAllowComments(getSpace().permissions.allow_comments)
    }
    else{
      if (window.$currentSpaceAllowComments.slug == getSpace().slug){
        setAllowComments(window.$currentSpaceAllowComments.allow)
      }
      else{
        setAllowComments(getSpace().permissions.allow_comments)
      }
    }
  }, [space, space2])

  useEffect(() => {
    if (mounted.current) {
      window.$currentSpaceAllowComments = {slug: getSpace().slug, allow: allowComments}
      onSaveSettings();
    } else {
      setTimeout(() => {
        mounted.current = true;
      }, 1000);
    }
  }, [allowComments]);

  const getSpace = () => {
    if (props.useSpace2){
      return space2;
    }
    else{
      return space;
    }
  }

  const onSaveSettings = () => {
    SpaceSettingModel.onUpdate(
      space.slug,
      { pod_settings_attributes: { allow_comments: allowComments } },
      (data) => {
        scrollToBottom();
      },
      (data) => {}
    );
  };

  const onContentUpdate = (_content, html, clearCallback, isEmpty) => {
    content.current = {
      json: _content.content,
      html: html,
      clear: clearCallback,
    };
  };

  const onSave = () => {

    if (!user.authenticated) {

      localStorage.setItem("join_slug", space.slug);
      navigate(`/user/start?s=${space.slug}`);
      return;
    }

    if (!content.current) {
      return;
    }

    if (!props.editable && !saving) {
      setSaving(true);

      setTimeout(() => {
        const json = {
          attrs: {
            uid: `message-${new Date().getTime()}`,
          },
          type: "paragraph",
          content: content.current.json,
        };
        const html = content.current.html;

        MessageModel.onCreate(
          getSpace().slug,
          { json, html, message_type: "comment" },
          (data) => {
            setSaving(false);
            content.current.clear();
            storeJSON("spaces", `comments-${getSpace().slug}`, { json: "", html: "" });
          },
          (data) => {
            setSaving(false);
          }
        );
      }, 550);
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      try {
        const $contentScrollViewport = document.getElementById(`main-scroll-area`).querySelectorAll("div")[0];;
        if ($contentScrollViewport) {
          const top = $contentScrollViewport.scrollHeight;
          $contentScrollViewport.scrollTo({ top: top });
        }
      } catch (e) {}
    }, 500);
  };

  const toggleAllowComments = (event) => {
    event.stopPropagation();

    if (getSpace().interactions && event.currentTarget.checked == true){
      setAllowComments(false)
      return
    }

    setAllowComments(event.currentTarget.checked)
  }

  const getCommentsToggleLabel = () => {
    if (getSpace().interactions){
      return t("spaces.presentations.content.commentInput.toggleLabel1")
    }
    if (getSpace().parents_versions && (getSpace().parents_versions.indexOf("list") >= 0 || getSpace().parents_versions.indexOf("showcase") >= 0)){
      return t("spaces.presentations.content.commentInput.toggleLabel2")
    }
    else{
      if (getSpace().calendar.has_start_date){
        return t("spaces.presentations.content.commentInput.toggleLabel3")
      }
      else{
        return t("spaces.presentations.content.commentInput.toggleLabel4")
      }
    }
  }

  return (
    <Box pb={30}>

      {props.editable && (getSpace().slug && getSpace().permissions.can_manage) && allowComments ? (
        <Paper
          radius={10}
          mt={15}
          mb={15}
          pt={12}
          pb={12}
          pl={20}
          pr={20}
          className={classes.panelWrap}
          onClick={(event) => toggleAllowComments(event)}
        >
          <Center>
            <Group position="apart">
              <Switch
                checked={allowComments}
                onChange={(event) =>
                  toggleAllowComments(event)
                }
                label={getCommentsToggleLabel()}
                styles={{
                  label: { color: getColors().sidebar_text_color },
                }}
              />
            </Group>
          </Center>
        </Paper>
      ) : (
        <Box mt={15}></Box>
      )}
      
      {allowComments && (
        <>
          
          <TiptapInline
            editable={true}
            content={""}
            modals={true}
            onUpdate={onContentUpdate}
            exclude={["formatbar"]}
            clearCallback={true}
            withSaveButton={!props.editable}
            saveButtonText={`Send`}
            onSave={onSave}
            saveButtonDisabled={saving}
            placeholder={props.editable ? t("spaces.presentations.content.commentInput.placeholder1") : t("spaces.presentations.content.commentInput.placeholder2")}
            contextDb={`comments-${getSpace().slug}`}
            useSpace2={props.useSpace2}
          />
        </>
      )}
    </Box>
  );
}
