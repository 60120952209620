import React, { useState, useEffect, useRef } from "react";

import { createStyles } from "@mantine/core";

import { useSelector, useDispatch } from "react-redux";

import NodeLink from "./NodeLink";
import NodeContent from "./NodeContent";

import { getDropPosition } from "../../../utils/DropPosition";

import SpaceModel from "../../../models/neo4j/Space";

import Broadcaster from "../../../helpers/Broadcaster";

import { getJSON, storeJSON } from "../../../utils/LocalDB";

import pako from "pako";

const useStyles = createStyles((theme) => ({
  topNodeItem: {
    padding: "0px",
  },

  nodeItem: {
    // ref: getRef('nodeItem'),
    padding: "0px 0px 0px 20px",
    boxSizing: "border-box",
    width: "100%",
  },

  dragging: {
    backgroundColor: "rgba(0,0,0,0.05)",
  },
}));

export default function NodeItem(props) {
  const sidebarChildrenA = useSelector((state) => state.sidebarChildrenA);
  const sidebarChildrenB = useSelector((state) => state.sidebarChildrenB);

  const { classes, cx } = useStyles();

  const dispatch = useDispatch();

  const [opened, setOpened] = useState("");
  const [children, setChildren] = useState([]);
  const [pos, setPos] = useState();
  const [node, setNode] = useState();
  const [loading, setLoading] = useState();
  const [editable, setEditable] = useState(false);
  // const [draggedOver, setDraggedOver] = useState(null)
  const [dragClasses, setDragClasses] = useState("");
  const [anchorDragOverClasses, setAnchorDragOverClasses] = useState("");

  const broadcasterSet = useRef(false);
  const fetchedOnOpen = useRef(false);
  const intervalId = useRef();
  const intervalCounter = useRef(0);
  const currentDndState = useRef();
  const draggedOver = useRef();
  const fetchEscapeCounter = useRef(0);
  const openedOnProps = useRef(false);

  const onFetchSidebarChildrenTimeout = useRef();

  useEffect(() => {
    intervalCounter.current = 0;
    intervalId.current = setInterval(() => {
      console.log("BROADCAST INIT : " + node);
      intervalCounter.current++;
      if (intervalCounter.current > 100) {
        clearInterval(intervalId.current);
      }
      if (broadcasterSet.current) {
        const element = document.getElementById(
          `sidebar_tree_node-${broadcasterSet.current.slug}`
        );
        if (element) {
          console.log("BROADCAST INIT : " + broadcasterSet.current);
          clearInterval(intervalId.current);

          Broadcaster.receive(
            "change_sidebarTreeNode",
            document.getElementById(
              `sidebar_tree_node-${broadcasterSet.current.slug}`
            ),
            (event, data) => {
              
              console.log("EVENT RECEIVED");
              console.log(data);
              if (data.close) {
                fetchedOnOpen.current = false;
              } else {
                onFetchSidebarChildren(data.data);
              }
            }
          );
        }
      }
    }, 100);

    return () => {
      const element = document.getElementById(
        `sidebar_tree_node-${broadcasterSet.current.slug}`
      );
      if (broadcasterSet.current && element) {
        Broadcaster.unRegisterElement(
          "change_sidebarTreeNode",
          document.getElementById(
            `sidebar_tree_node-${broadcasterSet.current.slug}`
          )
        );
      }
    };
  }, []);

  useEffect(() => {
    if (!props.node.children) {
      props.node.children = [];
    }
    setPos(props.pos);
    setEditable(props.editable);

    setNode({ ...props.node });
    broadcasterSet.current = { ...props.node };
  }, [props]);

  useEffect(() => {
    if (window.$spacePath) {
      for (var i = 0; i < window.$spacePath.length; i++) {
        if (window.$spacePath[i].slug == props.node.slug) {
          if (!opened) {
            setOpened(true);
            return;
          }
        }
      }
    } else {
      const strOpenedNodes = localStorage.getItem("treeOpenedNodes");
      // if (strOpenedNodes) {
      //   const openedNodes = JSON.parse(strOpenedNodes);
      //   if (openedNodes[props.current]) {
      //     setOpened(openedNodes[props.current].indexOf(props.node.slug) >= 0);
      //   } else {
      //     setOpened(props.opened);
      //   }
      // } else {
      //   setOpened(props.opened);
      // }
    }
    
      
    if (props.node && props.node.settings && props.node.settings.sidebar_default_opened && !openedOnProps.current){
      openedOnProps.current = true
      setTimeout(() => {
        setOpened(true)
      }, 500)
    }

    if (opened && node && props.parent !== null && !fetchedOnOpen.current) {
      fetchedOnOpen.current = true;
      onFetchSidebarChildren();
    }
  }, [node]);

  useEffect(() => {
    const openedNodes = {};
    const strOpenedNodes = localStorage.getItem("treeOpenedNodes");
    if (strOpenedNodes) {
      const openedNodes = JSON.parse(strOpenedNodes);
      if (openedNodes[props.current]) {
        if (opened) {
          if (openedNodes[props.current].indexOf(props.node.slug) < 0) {
            openedNodes[props.current].push(props.node.slug);
          }
        } else {
          const nodeIndex = openedNodes[props.current].indexOf(props.node.slug);
          if (nodeIndex >= 0) {
            openedNodes[props.current].splice(nodeIndex, 1);
          }
        }
      } else {
        openedNodes[props.current] = [props.node.slug];
      }
    } else {
      openedNodes[props.current] = [props.node.slug];
    }

    localStorage.setItem("treeOpenedNodes", JSON.stringify(openedNodes));

    if (opened && node && props.parent !== null && !fetchedOnOpen.current) {
      fetchedOnOpen.current = true;
      onFetchSidebarChildren();
    }
  }, [opened]);

  const onFetchSidebarChildren = (data) => {
    if (fetchEscapeCounter.current > 5) {
      return;
    }

    if (onFetchSidebarChildrenTimeout.current) {
      fetchEscapeCounter.current++;
      clearTimeout(onFetchSidebarChildrenTimeout.current);
    }

    onFetchSidebarChildrenTimeout.current = setTimeout(() => {
      fetchEscapeCounter.current = 0;
      console.log("onFetchSidebarChildren");

      for (let i = 0; i < broadcasterSet.current.children.length; i++) {
        if (broadcasterSet.current.children[i]){
          const slug = broadcasterSet.current.children[i].slug;
          Broadcaster.send(
            "change_sidebarTreeNode",
            document.getElementById(`sidebar_tree_node-${slug}`),
            { close: true }
          );
        }
      }

      onFetchChildren(data);

      // SpaceModel.onFetchSidebarChildren(
      //   broadcasterSet.current.slug,
      //   (data) => {
      //     props.onUpdateTreeData({ ...data });

      //     if (window.$fetchedTopSlug == broadcasterSet.current.slug) {
      //       storeJSON("spaces", `tree_${broadcasterSet.current.slug}`, {
      //         ...data,
      //       });
      //     }
      //   },
      //   (data) => {}
      // );
    }, 500);
  };

  const onFetchChildren = (neo_data=null) => {
    if (neo_data){
      console.log("NEO UPDATE 2 -------> " + neo_data.space.name)
      onSetTreeOrChildren({...neo_data});
      return
    }
    setLoading(true)
    // getJSON(
    //   `spaces`,
    //   `space_children_${broadcasterSet.current.slug}`,
    //   (data) => {
    //     if (data && data.space && data.space.slug) {
    //       onSetTreeOrChildren(data);
    //     }
    //   }
    // );
    if (broadcasterSet.current && broadcasterSet.current.slug){
      onFetchChildrenFromAPI(broadcasterSet.current.slug);
    }
  };

  const decompressGzip = (compressedData) => {
      
    try {
      const decompressed = pako.ungzip(compressedData.data, { to: "string" });
      return JSON.parse(decompressed);
    } catch (err) {
      console.error("Error during decompression:", err);
      return null;
    }
    
  };

  const onFetchChildrenFromAPI = () => {
    SpaceModel.onFetchChildren(
      broadcasterSet.current.slug,
      {topSlug: window.$fetchedTopSlug},
      (res) => {
        const auxData = {...res.data}
        auxData.children = [...decompressGzip(res.data.children)]
        storeJSON("spaces", `space_children_${broadcasterSet.current.slug}`, {
          ...auxData,
        });
        
        onSetTreeOrChildren(auxData);
      },
      (data) => {}
    );
  };

  const onSetTreeOrChildren = (data) => {
    const space = { ...data.space };
    let children = []
    try{
      children = [...data.children];
      space.sidebar_default_opened = true;
    }
    catch{

    }

    setTimeout(() => {
      setLoading(false)
    }, 200)
    
    props.onUpdateTreeData({ ...space, children: children });
  };

  const getElement = () => {
    return document.getElementById(`sidebar_tree_node-${node.slug}`);
  };

  const resetClasses = () => {
    $(getElement()).prop("class", "");
    $(getElement()).addClass(
      `${cx(classes.nodeItem, {
        [classes.topNodeItem]: !props.parent,
      })} dnd-anchor ${props.node.slug == props.current ? "active" : ""}`
    );
    // setDragClasses(`dnd-anchor ${props.node.slug == props.current ? "active" : ""}`)
  };

  const onDragStart = (event, node) => {
    event.stopPropagation();
    if (!editable) {
      // const ele = document.createElement("div")
      // ele.innerHTML = "<p>Go to edit mode to Drag</p>"
      // event.dataTransfer.setDragImage(ele, 60, 20);
      return;
    }

    if (currentDndState.current == "onDragStart") {
      return;
    }
    currentDndState.current = "onDragStart";
    console.log("onDragStart");
    event.dataTransfer.setDragImage(event.currentTarget, 60, 20);

    node.parent = props.parent;
    node.parent.setChildren = props.setChildren;
    // dispatch(setSidebarDragging(node));
    window.$draggingNode = node;
    // Add class to dragged element
    $(getElement()).addClass(`dragging`);
    // setDragClasses(`dragging dnd-anchor ${props.node.slug == props.current ? "active" : ""}`)
  };

  const onDragEnd = (event, node) => {
    event.stopPropagation();
    if (!editable) {
      return;
    }

    if (currentDndState.current == "onDragEnd") {
      return;
    }
    currentDndState.current = "onDragEnd";
    console.log("onDragEnd");
    // dispatch(setSidebarDragging({id: null}));
    window.$draggingNode = { id: null };
    // remove class to dragged element
    resetClasses();
    // setDragClasses(`dnd-anchor ${props.node.slug == props.current ? "active" : ""}`)
  };

  const onDragOver = (event, node, type) => {
    event.stopPropagation();
    event.preventDefault();
    if (currentDndState.current == "onDragOver") {
      return;
    }
    currentDndState.current = "onDragOver";
    console.log("onDragOver: " + pos);

    if (!window.$draggingNode || !editable) {
      return;
    }

    if (window.$draggingNode.outsider) {
      let foundInTarget = false;
      for (let i = 0; i < window.$draggingNode.nodes.length; i++) {
        const dNode = window.$draggingNode.nodes[i];
        if (dNode.type == "circle") {
          if (
            node.slug == dNode.attrs.id ||
            props.findNodeInTreeBySlug(node.children, dNode.attrs.id)
          ) {
            foundInTarget = true;
            break;
          }
        }
      }
      if (!foundInTarget) {
        // dispatch(setSidebarDraggedOver(node));
        node.parent = props.parent;
        window.$draggedOverNode = node;

        const auxPos = getDropPosition(
          event.clientX,
          event.clientY,
          event.target
        );
        if (pos > 0 && auxPos == "top") {
          auxPos == "center";
        }

        // setDraggedOver(auxPos)
        draggedOver.current = auxPos;
        // setAnchorDragOverClasses(`dnd-anchor dragged-over`)
        $(getElement()).addClass(`drop-at-${auxPos}`);
        $(getElement()).addClass(`dragged-over`);
      } else {
        // setAnchorDragOverClasses(`dnd-anchor dragged-over-error`)
        $(getElement()).addClass(`dragged-over-error`);
      }
    } else {
      const foundInTarget = props.findNodeInTreeBySlug(
        node.children,
        window.$draggingNode.slug
      );
      if (node.slug != window.$draggingNode.slug && !foundInTarget) {
        node.parent = props.parent;

        window.$draggedOverNode = node;
        const auxPos = getDropPosition(
          event.clientX,
          event.clientY,
          event.target
        );
        if (pos > 0 && auxPos == "top") {
          auxPos == "center";
        }

        // setDraggedOver(auxPos)
        draggedOver.current = auxPos;

        // setAnchorDragOverClasses(`dnd-anchor dragged-over`)
        $(getElement()).addClass(`dnd-anchor dragged-over`);
        $(getElement()).addClass(`drop-at-${auxPos}`);
      } else {
        // setAnchorDragOverClasses(`dnd-anchor dragged-over-error`)
        $(getElement()).addClass(`dnd-anchor dragged-over-error`);
      }
    }
  };

  const onDragLeave = (event, node, type) => {
    event.stopPropagation();
    console.log("onDragLeave");
    if (!editable) {
      return;
    }

    if (currentDndState.current == "onDragLeave") {
      return;
    }
    currentDndState.current = "onDragLeave";

    window.$draggedOverNode = { id: null };
    draggedOver.current = null;
    // setDraggedOver(null)
    // $(getElement()).removeClass(`drop-at-${auxPos}`)

    // setAnchorDragOverClasses(`dnd-anchor`)
    // $(getElement()).addClass(`dnd-anchor`)
    resetClasses();
  };

  const onDrop = (event, node) => {
    event.stopPropagation();

    if (!editable) {
      return;
    }

    if (currentDndState.current == "onDrop") {
      return;
    }
    currentDndState.current = "onDrop";
    console.log("onDrop");

    let auxDraggingNode = { ...window.$draggingNode };
    let auxDraggingNodeParent = window.$draggingNode.parent;
    let multiple = false;

    if (window.$draggingNode.outsider) {
      // Get current selected nodeitem
      // Set the current selected nodeitem as the draggingNode parent
      auxDraggingNodeParent = props.findNodeInTreeBySlug(
        null,
        window.location.href.split("/")[4]
      );

      if (window.$draggingNode.selection_type == "circle") {
        const circleNode = window.$draggingNode.nodes[0];
        auxDraggingNode = props.findNodeInTreeBySlug(null, circleNode.attrs.id);
        auxDraggingNode.node_type = "circle";
      } else {
        multiple = true;
      }
    } else {
      auxDraggingNode.node_type = "circle";
    }

    // If is circle use moveNodeFromTo
    if (!multiple) {
      if (
        node.slug != auxDraggingNode.slug &&
        auxDraggingNode.node_type == "circle"
      ) {
        // Moving logic
        if (draggedOver.current != "center") {
          moveNodeFromTo(
            auxDraggingNode,
            auxDraggingNodeParent,
            window.$draggedOverNode.parent,
            window.$draggedOverNode,
            draggedOver.current
          );
        } else {
          moveNodeFromTo(
            auxDraggingNode,
            auxDraggingNodeParent,
            window.$draggedOverNode,
            null,
            draggedOver.current
          );
        }
      }
      // If not update tree
      else if (
        node.slug != window.$draggingNode.slug &&
        window.$draggingNode.type != "circle"
      ) {
        if (draggedOver.current != "center") {
          props.onTreeUpdate(
            auxDraggingNode,
            auxDraggingNodeParent,
            window.$draggedOverNode.parent,
            window.$draggedOverNode,
            draggedOver.current
          );
        } else {
          props.onTreeUpdate(
            auxDraggingNode,
            auxDraggingNodeParent,
            window.$draggedOverNode,
            null,
            draggedOver.current
          );
        }
      }
    } else {
      if (draggedOver.current != "center") {
        props.onTreeUpdate(
          auxDraggingNode,
          auxDraggingNodeParent,
          window.$draggedOverNode.parent,
          window.$draggedOverNode,
          draggedOver.current
        );
      } else {
        props.onTreeUpdate(
          auxDraggingNode,
          auxDraggingNodeParent,
          window.$draggedOverNode,
          null,
          draggedOver.current
        );
      }
    }

    // dispatch(setSidebarDraggedOver({slug: null}));
    window.$draggedOverNode = { slug: null };
    // setDraggedOver(null)
    draggedOver.current = null;
    // setAnchorDragOverClasses(`dnd-anchor`)
    resetClasses();
  };

  const moveNodeFromTo = (node, prevNode, targetNode, afterNode, position) => {
    props.onTreeUpdate(node, prevNode, targetNode, afterNode, position);
  };

  const isUnpublished = () => {
    return (
      node && node.settings && node.settings.launch_on_date && new Date() < new Date(node.settings.launch_time)
    );
  };

  if (!node) {
    return null;
  }

  return (
    <div
      id={`sidebar_tree_node-${node.slug}`}
      draggable={editable}
      onDragStart={(event) => onDragStart(event, props.node)}
      onDragEnd={(event) => onDragEnd(event, props.node)}
      onDragOver={(event) => onDragOver(event, props.node, "anchor")}
      onDragLeave={(event) => onDragLeave(event, props.node, "anchor")}
      onDrop={(event) => onDrop(event, props.node)}
      className={`${cx(classes.nodeItem, {
        [classes.topNodeItem]: !props.parent,
      })}`}
      style={{
        display: !isUnpublished() ? "block" : "none",
      }}
    >
      {props.parent !== null ? (
        <NodeLink
          name={node.name}
          slug={node.slug}
          version={node.version}
          emojicon={node.emojicon}
          completed={node.completed}
          unreadCount={node.unread_messages}
          hasChildren={
            props.parent &&
            node.hasChildren &&
            !node.settings.sidebar_hide_children
          }
          opened={opened}
          setOpened={setOpened}
          editable={editable}
          loading={loading}
        />
      ) : (
        ""
      )}
      <NodeContent
        current={props.current}
        parent={props.parent}
        node={node}
        opened={opened}
        onTreeUpdate={props.onTreeUpdate}
        findNodeInTreeBySlug={props.findNodeInTreeBySlug}
        onUpdateTreeData={props.onUpdateTreeData}
        editable={editable}
        sidebarHideChildren={node.settings ? node.settings.sidebar_hide_children : false}
      />
    </div>
  );
}
