import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
  Button,
  ScrollArea,
  Stack,
  TextInput,
  Group,
  Text,
  Select,
  SegmentedControl,
  Switch,
  MantineProvider,
  Box,
  Loader,
  Center,
} from "@mantine/core";
import SpaceModel from "../../../models/Space";

import { useForm } from "@mantine/form";
import { TimeInput, DatePicker } from "@mantine/dates";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { useTranslation } from "react-i18next";

import { useFocusTrap } from "@mantine/hooks";

import PremiumModal from "../../app/PremiumModal";

import TiptapInline from "../../tiptap/components/TiptapInline";

import { storeJSON } from "../../../utils/LocalDB";

import { getEnv } from "../../../utils/Host";

const SelectItem = ({ image, label, description, ...others }) => (
  <div {...others}>
    <Group noWrap>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      </div>
    </Group>
  </div>
);

export default function Form(props) {
  const [publish, setPublish] = useState(false);
  const [join, setJoin] = useState(true);
  const [record, setRecord] = useState(false);
  const [privacyDescription, setPrivacyDescription] = useState("");
  const [rootPrivacyDescription, setRootPrivacyDescription] = useState("");
  const [opened, setOpened] = useState(false);
  const space = useSelector((state) => state.space);
  const [newSpaceLoading, setNewSpaceLoading] = useState(false);
  const [newMeetingLoading, setNewMeetingLoading] = useState(false);
  const [zoomUserId, setZoomUserId] = useState("");
  const [zoomMeetingId, setZoomMeetingId] = useState("");
  const [zoomData, setZoomData] = useState({});
  const [zoomMeetings, setZoomMeetings] = useState([]);
  const [linkOption, setLinkOption] = useState("");
  const [zoomLoaded, setZoomLoaded] = useState(false);
  const [canUseZoom, setCanUseZoom] = useState([]);
  const zoomIntervalId = useRef();
  const zoomIntervalCount = useRef(0);

  const { t, i18n } = useTranslation();

  const focusTrapRef = useFocusTrap();

  const form = useForm({
    initialValues: {
      intention: "",
      version: "",
      privacy_level: "open",
      join_on_create: true,
      launch_on_date: null,
      launch_time: null,
      show_start_date: false,
      start_date: new Date(new Date().getTime() + 86400000),
      end_date: new Date(new Date().getTime() + 90000000),
      start_time: new Date(new Date().getTime() + 86400000),
      end_time: new Date(new Date().getTime() + 90000000),
      content: "",
      hangout_link: "",
    },

    validate: {
      intention: (value) =>
        value.length >= 3 && value.length <= 100
          ? null
          : t("spaces.create.invalidNameErrorMsg"),
    },
  });

  useEffect(() => {
    return () => {
      if (zoomIntervalId.current) {
        clearInterval(zoomIntervalId.current);
        zoomIntervalId.current = null;
        zoomIntervalCount.current = 0;
      }
      setNewSpaceLoading(false);
    };
  }, []);

  useEffect(() => {
    form.setFieldValue("version", props.version);
    if (!zoomData.connected) {
      onFetchZoomData();
    }
  }, [props]);

  useEffect(() => {
    form.setFieldValue("join_on_create", join);
  }, [join]);

  useEffect(() => {
    if (record && !canUseZoom[0]) {
      setOpened([true, canUseZoom[1], canUseZoom[2]]);
      setRecord(false);
      return;
    }
    form.setFieldValue("record_zoom", record);
  }, [record]);

  useEffect(() => {
    if (form.values.privacy_level === "open") {
      setPrivacyDescription(
        `${t("spaces.form.form.privacyDescription1")} ${space.name}.`
      );
      setRootPrivacyDescription(
        `${t("spaces.form.form.rootPrivacyDescription1")}`
      );
    } else if (form.values.privacy_level === "private") {
      setPrivacyDescription(`${t("spaces.form.form.privacyDescription2")}`);
      setRootPrivacyDescription(
        `${t("spaces.form.form.rootPrivacyDescription2")}`
      );
    }
  }, [form.values.privacy_level]);

  useEffect(() => {
    if (zoomMeetingId) {
      form.setFieldValue("hangout_link", getMeetingLink());
    } else {
      form.setFieldValue("hangout_link", "");
    }
  }, [zoomMeetingId]);

  useEffect(() => {
    if (newMeetingLoading) {
      return;
    }
    setZoomMeetingId("");
  }, [linkOption]);

  useEffect(() => {
    if (space && space.slug) {
      setCanUseZoom(
        space && space.permissions && space.permissions.can_use_zoom_recordings
      );
    }
  }, [space]);

  const getMeetingLink = (meetingId = null) => {
    return `https://us02web.zoom.us/j/${meetingId ? meetingId : zoomMeetingId}`;
  };

  const onPreSave = () => {
    if (linkOption == "zoom-create-meeting") {
      setNewMeetingLoading(true);
      onCreateZoom();
      return;
    }
    onSave();
  };

  const onSave = (hangout_link = null) => {
    if (newSpaceLoading) {
      return;
    }

    setNewMeetingLoading(false);
    setNewSpaceLoading(true);
    const spaceParams = { ...form.values };
    if (spaceParams.version == "event") {
      spaceParams.version = "content";
      spaceParams.show_start_date = true;

      if (linkOption.indexOf("zoom") >= 0 && hangout_link) {
        spaceParams.hangout_link = hangout_link;
      }

      if (spaceParams.start_date) {
        const _startDate = new Date(spaceParams.start_date);
        const _startTime = spaceParams.start_time
          ? new Date(spaceParams.start_time)
          : new Date();
        try {
          spaceParams.start_date = new Date(
            `${_startDate.toLocaleDateStringAbs()}, ${_startTime.toLocaleTimeString()}`
          ).toISOString();
        } catch {}
      }
      if (spaceParams.end_date) {
        const _endDate = new Date(spaceParams.end_date);
        const _endTime = spaceParams.end_time
          ? new Date(spaceParams.end_time)
          : new Date();
        try {
          spaceParams.end_date = new Date(
            `${_endDate.toLocaleDateStringAbs()}, ${_endTime.toLocaleTimeString()}`
          ).toISOString();
        } catch {}
      }
    }
    if (spaceParams.launch_on_date) {
      const _launchDate = new Date(spaceParams.launch_on_date);
      const _launchTime = spaceParams.launch_time
        ? new Date(spaceParams.launch_time)
        : new Date();
      try {
        spaceParams.launch_time = new Date(
          `${_launchDate.toLocaleDateStringAbs()}, ${_launchTime.toLocaleTimeString()}`
        ).toISOString();
        spaceParams.launch_on_date = true;
      } catch {}
    }

    if (props.duplicateFrom) {
      spaceParams.copy_resources = true;
      spaceParams.copy_sub_circles = true;

      SpaceModel.onDuplicate(
        spaceParams,
        props.duplicateFrom,
        (data) => {
          props.onDuplicate(data.data.slug, data.data.link);
          setTimeout(() => {
            setNewSpaceLoading(false);
          }, 2000);
        },
        (data) => {
          setTimeout(() => {
            setNewSpaceLoading(false);
          }, 500);
        }
      );
    } else {
      SpaceModel.onCreate(
        spaceParams,
        props.parentId,
        (data) => {
          props.onSave(data.slug, data.link, data.name);
          storeJSON("spaces", `new-space-${props.parentId}`, {
            json: "",
            html: "",
          });
          setTimeout(() => {
            setNewSpaceLoading(false);
          }, 2000);
        },
        (data) => {
          if (data.permissions) {
            setOpened([true, data.permissions[1], data.permissions[2]]);
            // props.onSave()
          }
          setTimeout(() => {
            setNewSpaceLoading(false);
          }, 500);
        }
      );
    }
  };

  const spacesVersions = () => {
    let versions = [
      {
        value: "content",
        label: t("spaces.form.form.versions.content.label"),
        description: t("spaces.form.form.versions.content.description"),
      },
      {
        value: "list",
        label: t("spaces.form.form.versions.list.label"),
        description: t("spaces.form.form.versions.list.description"),
      },
      {
        value: "discussion",
        label: t("spaces.form.form.versions.discussion.label"),
        description: t("spaces.form.form.versions.discussion.description"),
      },
      {
        value: "event",
        label: t("spaces.form.form.versions.event.label"),
        description: t("spaces.form.form.versions.event.description"),
      },
      {
        value: "events",
        label: t("spaces.form.form.versions.events.label"),
        description: t("spaces.form.form.versions.events.description"),
      },
      {
        value: "showcase",
        label: t("spaces.form.form.versions.showcase.label"),
        description: t("spaces.form.form.versions.showcase.description"),
      },
    ];

    return versions;
  };

  const onContentUpdate = (content, html, callback, isEmpty) => {
    form.setFieldValue("content", content.content);
  };

  const onConnectZoom = () => {
    let url = "";
    if (getEnv() == "production") {
      url = `https://zoom.us/oauth/authorize?client_id=Iq_GKcCRhWRVRdVaE3W5Q&response_type=code&redirect_uri=https://sutra.co/api/v4/hooks/zoom/redirect?state=zoom2_usr_${window.$currentUserAuthToken}`;
    } else {
      url = `https://zoom.us/oauth/authorize?client_id=YK0Cx_LQ16AVBy0FFvaxQ&response_type=code&redirect_uri=https://sutra.ngrok.dev/api/v4/hooks/zoom/redirect?state=zoom2_usr_${window.$currentUserAuthToken}`;
    }
    window.open(url, "_blank");

    zoomIntervalId.current = setInterval(() => {
      onFetchZoomData();
      zoomIntervalCount.current += 1;

      if (zoomIntervalCount.current > 60) {
        clearInterval(zoomIntervalId.current);
      }
    }, 1000);
  };

  const onDisconnectZoom = () => {
    SpaceModel.onZoomDisconnect(
      "zoom",
      (data) => {
        setZoomData({});
      },
      (data) => {}
    );
  };

  const onFetchZoomData = () => {
    SpaceModel.onZoomData(
      "zoom",
      zoomUserId,
      (data) => {
        setZoomData({ ...data.zoom_data });
        setZoomMeetings([...data.zoom_data.meetings]);
        if (data.zoom_data.users.length == 1) {
          lastZoomUser.current = data.zoom_data.users[0].value;
          setZoomUserId(data.zoom_data.users[0].value);
        }
        setZoomLoaded(true);
      },
      (data) => {}
    );
  };

  const onCreateZoom = (query) => {
    const spaceParams = { ...form.values };
    if (spaceParams.start_date) {
      const _startDate = new Date(spaceParams.start_date);
      const _startTime = spaceParams.start_time
        ? new Date(spaceParams.start_time)
        : new Date();
      try {
        spaceParams.start_date = new Date(
          `${_startDate.toLocaleDateStringAbs()}, ${_startTime.toLocaleTimeString()}`
        ).toISOString();
        version;
      } catch {}
    }
    if (spaceParams.end_date) {
      const _endDate = new Date(spaceParams.end_date);
      const _endTime = spaceParams.end_time
        ? new Date(spaceParams.end_time)
        : new Date();
      try {
        spaceParams.end_date = new Date(
          `${_endDate.toLocaleDateStringAbs()}, ${_endTime.toLocaleTimeString()}`
        ).toISOString();
      } catch {}
    }

    SpaceModel.onZoomCreateMeeting(
      {
        topic: form.values.intention,
        start_time: spaceParams.start_date,
        end_time: spaceParams.end_date,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      (data) => {
        setZoomMeetingId(data.meeting.id + "");
        form.setFieldValue("hangout_link", getMeetingLink(data.meeting.id));
        setTimeout(() => {
          onSave(getMeetingLink(data.meeting.id));
        }, 500);
      },
      (data) => {}
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }} offsetScrollbars={true}>
      <form
        onSubmit={form.onSubmit((values) => onPreSave(values))}
        ref={focusTrapRef}
      >
        <Stack>
          <TextInput
            {...form.getInputProps("intention")}
            label={t("spaces.form.form.intention.label")}
            placeholder={t("spaces.form.form.intention.placeholder")}
            data-autofocus
          />
          {props.isRoot && (
            <Box>
              <Text
                // align="center"
                color="dimmed"
                size="sm"
              >
                {form.values.version == "content" &&
                  t("spaces.form.form.segments.space.description")}
                {form.values.version == "event" &&
                  t("spaces.form.form.segments.event.description")}
                {form.values.version == "discussion" &&
                  t("spaces.form.form.segments.discussion.description")}
              </Text>
            </Box>
          )}
          {props.content && (
            <Box mt={20}>
              <TiptapInline
                editable={true}
                content={""}
                modals={true}
                exclude={[]}
                onUpdate={onContentUpdate}
                placeholder={t("spaces.form.form.content.placeholder")}
                contextDb={`new-space-${props.parentId}`}
              />
            </Box>
          )}
          {!props.isRoot && (
            <MantineProvider theme={{ primaryShade: 6 }}>
              {space && space.slug && space.version == "content" && (
                <Select
                  {...form.getInputProps("version")}
                  label={t("spaces.form.form.spaceFormat.label")}
                  placeholder={t("spaces.form.form.spaceFormat.placeholder")}
                  itemComponent={SelectItem}
                  maxDropdownHeight={400}
                  zIndex={99999}
                  defaultValue={"content"}
                  data={spacesVersions()}
                />
              )}
            </MantineProvider>
          )}
          {form.getInputProps("version").value == "event" ? (
            <Stack>
              {/* <Text color="dimmed">
                {t("spaces.form.form.eventDescription")}
              </Text> */}
              <Select
                zIndex={100000}
                label="Event location"
                placeholder="Pick one"
                data={[
                  {
                    label: "Select a zoom meeting",
                    value: "zoom-meeting",
                  },
                  {
                    label: "Create a zoom meeting",
                    value: "zoom-create-meeting",
                  },
                  { label: "Custom link", value: "custom-link" },
                ]}
                value={linkOption}
                onChange={setLinkOption}
              />
              {zoomLoaded == true &&
                !zoomData.connected &&
                linkOption.indexOf("zoom") >= 0 && (
                  <Button
                    mt={10}
                    mr={10}
                    variant="outline"
                    radius="xl"
                    onClick={() => onConnectZoom()}
                  >
                    {"Connect Zoom Account"}
                  </Button>
                )}
              {zoomData.connected && (
                <>
                  {zoomData.users && zoomData.users.length > 1 && (
                    <Select
                      zIndex={100000}
                      label="Select a user"
                      placeholder="Pick one"
                      data={zoomData.users}
                      value={zoomUserId}
                      onChange={setZoomUserId}
                      searchable
                    />
                  )}
                  {linkOption == "zoom-meeting" && (
                    <>
                      <Select
                        zIndex={100000}
                        label={"Select meeting"}
                        data={zoomMeetings}
                        value={zoomMeetingId}
                        onChange={setZoomMeetingId}
                        placeholder={t(
                          "spaces.form.form.hangoutLink.placeholder"
                        )}
                        nothingFound="Nothing found"
                        searchable
                      />
                      {/* <Text>{getMeetingLink()}</Text> */}
                    </>
                  )}
                  {zoomData.connected && linkOption.indexOf("zoom") >= 0 && (
                    <>
                      <Switch
                        mt={10}
                        checked={record}
                        onChange={(event) => setRecord(event.target.checked)}
                        label={`Auto save zoom recording`}
                      />
                      <Text color="dimmed" size="sm">
                        {
                          "Enabling this option will automatically upload your Zoom cloud recording to Sutra once the meeting is over"
                        }
                      </Text>
                    </>
                  )}
                </>
              )}
              {linkOption == "custom-link" && (
                <TextInput
                  {...form.getInputProps("hangout_link")}
                  label={t("spaces.form.form.hangoutLink.label")}
                  placeholder={t("spaces.form.form.hangoutLink.placeholder")}
                  data-autofocus
                />
              )}
              <Box>
                <Text size="sm" weight={500} mb={4} mt={5}>
                  {t("spaces.form.form.eventStartTime")}
                </Text>
                <Group>
                  <DatePicker
                    minDate={new Date()}
                    {...form.getInputProps("start_date")}
                    placeholder={t("spaces.form.form.date.placeholder")}
                    zIndex={99999}
                  />
                  <TimeInput
                    minDate={new Date()}
                    defaultValue={new Date()}
                    format="12"
                    {...form.getInputProps("start_time")}
                  />
                </Group>
                <Text size="sm" weight={500} mb={4} mt={20}>
                  {t("spaces.form.form.eventEndDate")}
                </Text>
                <Group>
                  <DatePicker
                    {...form.getInputProps("end_date")}
                    placeholder={t("spaces.form.form.date.placeholder")}
                    zIndex={99999}
                    minDate={
                      form.values.start_date ? form.values.start_date : null
                    }
                  />
                  <TimeInput
                    defaultValue={new Date()}
                    format="12"
                    {...form.getInputProps("end_time")}
                  />
                </Group>
              </Box>
            </Stack>
          ) : (
            ""
          )}
          {!props.noAdvance && (
            <Stack>
              <Box>
                <Text size="sm" weight={500} mb={4} mt={5}>
                  {t("spaces.form.form.privacyLevelText")}
                </Text>
                <SegmentedControl
                  fullWidth
                  {...form.getInputProps("privacy_level")}
                  data={[
                    {
                      label: t("spaces.form.form.privacy.open"),
                      value: "open",
                    },
                    {
                      label: t("spaces.form.form.privacy.private"),
                      value: "private",
                    },
                  ]}
                />
                {!props.isRoot && (
                  <Text mt={10} size="sm" color="dimmed">
                    {privacyDescription}
                  </Text>
                )}
                {props.isRoot && (
                  <Text mt={10} size="sm" color="dimmed">
                    {rootPrivacyDescription}
                  </Text>
                )}
              </Box>
              {!props.isRoot ? (
                <>
                  <Switch
                    mt={10}
                    checked={join}
                    onChange={(event) => setJoin(event.target.checked)}
                    label={t("spaces.form.form.joinAsMember.label")}
                  />
                  <Switch
                    checked={publish}
                    onChange={(event) => setPublish(event.target.checked)}
                    label={t("spaces.form.form.publishDate.label")}
                  />
                  {publish ? (
                    <div>
                      <Text size="sm" weight={500} mb={4} mt={5}>
                        {t("spaces.form.form.publishDateTime")}
                      </Text>
                      <Group>
                        <DatePicker
                          {...form.getInputProps("launch_on_date")}
                          placeholder={t("spaces.form.form.date.placeholder")}
                          zIndex={99999}
                        />
                        <TimeInput
                          defaultValue={new Date()}
                          format="12"
                          {...form.getInputProps("launch_time")}
                        />
                      </Group>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Stack>
          )}

          {newSpaceLoading || newMeetingLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Button radius="xl" size="md" mt={10} type="submit">
              {t("spaces.form.form.create")}
            </Button>
          )}
        </Stack>
      </form>
      <PremiumModal
        opened={opened}
        setOpened={setOpened}
        onUpgrade={props.onSave}
      />
      {props.padding && <Box sx={{ height: "200px" }}></Box>}
    </ScrollArea>
  );
}
