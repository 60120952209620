import React, { useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { NodeViewWrapper, NodeViewContent } from "@tiptap/react";

import { stylesToCamelCase } from "../../../utils/StylesToString";

import SurveyModel from "../../../models/Survey";
import { setTiptapSidemenu } from "../../../store/app";

import {
  Text,
  Button,
  Stack,
  Group,
  createStyles,
  Menu,
  ActionIcon,
} from "@mantine/core";

import tinycolor from "tinycolor2";

import { wideOrNarrow } from "../../../utils/PageWidth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { checkInteractionAndHideIfNeededReact, setUniqId} from "../utils/selection";

import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme, { colors, props }) => ({
  surveyWrap: {
    padding: 20,
    borderRadius: 5,
    backgroundColor: colors.sidebar_background_color,
    color: colors.sidebar_text_color,
  },
  surveyTitle: {
    fontWeight: 800,
    fontSize: 18,
    textAlign: "center",
  },
  surveyDescription: {
    marginBottom: 10,
    textAlign: "center",
  },
  surveyButton: {
    marginTop: 5,
    backgroundColor: colors.primary_button_background_color,
    color: colors.primary_button_text_color,
    border: `1px solid ${colors.primary_button_background_color}`,
    "&:hover": {
      backgroundColor: tinycolor(colors.primary_button_background_color)
        .darken(5)
        .toString(),
    },
    // backgroundColor: props.node.attrs.buttonBackgroundColor,
    // color: props.node.attrs.buttonTextColor,
    // border: `1px solid ${props.node.attrs.buttonBorderColor}`,
    // '&:hover': {
    //   backgroundColor: tinycolor(props.node.attrs.buttonBackgroundColor).darken(5).toString(),
    // },
  },
  settingsButtonStyle: {
    color: colors.default_text_color,
    "&:hover": {
      backgroundColor: colors.background_color,
      color: colors.primary_button_background_color,
    },
  },
}));

export default function Survey(props) {
  const colors = useSelector((state) => state.colors);
  const user = useSelector((state) => state.user);
  const { classes, cx, theme } = useStyles({ colors, props });

  const dispatch = useDispatch();

  const tiptapSidemenu = useSelector((state) => state.tiptapSidemenu);
  const taking = useRef();

  const [survey, setSurvey] = useState({ pages: [] });
  const [status, setStatus] = useState("pending");
  const [menuOpened, setMenuOpened] = useState(false);

  const navigate = useNavigate();

  //setUniqId(props.editor, props.node, props.getPos);

  useEffect(() => {
    onFetch();
  }, [props]);

  useEffect(() => {
    if (taking.current) {
      taking.current = false;
      onFetch();
    }
  }, [tiptapSidemenu]);

  const onFetch = () => {
    SurveyModel.onFetchById(
      props.node.attrs.id,
      props.node.attrs.circle_id,
      (data) => {
        setSurvey(data);
        setStatus(data.status);
      },
      (data) => {}
    );
  };

  const onTake = () => {
    
    if (!user || (user && !user.authenticated)) {
      const spaceSlug = window.location.href.split("/")[4];
      localStorage.setItem("join_slug", spaceSlug);
      navigate(`/user/start?s=${spaceSlug}`);
      return;
    }

    if (props.editor && !props.editor.isEditable) {
      setTimeout(() => {
        taking.current = true;
      }, 2000);
      dispatch(
        setTiptapSidemenu({
          opened: "takeSurvey",
          option: {
            survey: { ...survey },
          },
        })
      );
    }
  };

  const getRadius = () => {
    if (props.node.attrs.radius == "no-radius") {
      return "xs";
    } else if (props.node.attrs.radius == "circle-radius") {
      return "xl";
    } else if (props.node.attrs.radius == "with-radius") {
      return "sm";
    } else {
      return "xl";
    }
  };

  const onView = () => {
    dispatch(
      setTiptapSidemenu({
        opened: "showSurvey",
        option: {
          survey: { ...survey },
        },
      })
    );
  };

  const onEdit = () => {
    dispatch(
      setTiptapSidemenu({
        opened: "createSurvey",
        option: {
          survey: { ...survey },
        },
      })
    );
  };

  return (
    <NodeViewWrapper
      className="react-component"
      id={props.node.attrs.uid}
      dataUid={props.node.attrs.uid}
      style={{
        maxWidth: wideOrNarrow(),
      }}
    >
      {checkInteractionAndHideIfNeededReact(props.editor, props.getPos) && (
        <NodeViewContent
          className={`node-content ${props.node.attrs.class}`}
          contentEditable="false"
          style={{
            ...stylesToCamelCase(props.node.attrs.styles),
            textAlign: props.node.attrs.textAlign,
          }}
          radius={props.node.attrs.radius}
        >

          {survey && (
            <form>
              <Stack spacing={0} className={classes.surveyWrap}>
                <Group
                  sx={{
                    justifyContent: "center",
                    marginBottom: 10,
                  }}
                >
                  <Text className={classes.surveyTitle}>
                    {props.node.attrs.blockTitle
                      ? props.node.attrs.blockTitle
                      : survey.title}
                  </Text>
                  {props.editor.isEditable && (
                    <Menu
                      control={
                        <ActionIcon
                          className={classes.settingsButtonStyle}
                          size={20}
                          radius="xl"
                          onClick={(event) => setMenuOpened(true)}
                          opened={menuOpened}
                          mb={3}
                        >
                          <FontAwesomeIcon size="md" icon={solid("gear")} />
                        </ActionIcon>
                      }
                    >
                      <Menu.Label>Options</Menu.Label>
                      <Menu.Item
                        icon={
                          <FontAwesomeIcon size="sm" icon={solid("list")} />
                        }
                        onClick={() => onView()}
                      >
                        View responsess
                      </Menu.Item>
                      <Menu.Item
                        icon={
                          <FontAwesomeIcon size="sm" icon={solid("pencil")} />
                        }
                        onClick={() => onEdit()}
                      >
                        Edit survey
                      </Menu.Item>
                    </Menu>
                  )}
                </Group>
                <Group>
                  <Button
                    fullWidth
                    radius={getRadius()}
                    onClick={() => onTake()}
                    className={classes.surveyButton}
                    rightIcon={
                      status == "taken" && (
                        <FontAwesomeIcon size="sm" icon={solid("check")} />
                      )
                    }
                    disabled={!survey.id}
                    sx={(theme) => ({
                      backgroundColor:
                        props.node.attrs.isMarkComplete && completed
                          ? theme.colors.green[6]
                          : props.node.attrs.buttonBackgroundColor,
                      color:
                        props.node.attrs.isMarkComplete && completed
                          ? "#fff"
                          : props.node.attrs.buttonTextColor,
                      border:
                        props.node.attrs.isMarkComplete && completed
                          ? "none"
                          : `1px solid ${props.node.attrs.buttonBackgroundColor}`,
                      "&:hover": {
                        backgroundColor:
                          props.node.attrs.isMarkComplete && completed
                            ? theme.colors.green[7]
                            : tinycolor(props.node.attrs.buttonBackgroundColor)
                                .darken(5)
                                .toString(),
                      },
                    })}
                  >
                    {status == "taken"
                      ? "Taken"
                      : `${props.node.attrs.buttonText}`}
                  </Button>
                </Group>
              </Stack>
            </form>
          )}
        </NodeViewContent>
      )}
    </NodeViewWrapper>
  );
}
