import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Text,
  Box,
  Group,
  Alert,
  SegmentedControl,
  Button,
  MantineProvider,
  Tooltip,
  Center,
} from "@mantine/core";

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

import Once from "../payment/Once";
import Flexible from "../payment/Flexible";
import Recurring from "../payment/Recurring";
import Extras from "../payment/Extras";

import StripeModel from "../../../../models/Stripe";
import { colors } from "../../../../collections/colors";
import { sutraTheme } from "../../../../collections/sutraTheme";


import Swal from "sweetalert2";

const paymentOptions = [
  {
    label: "One time",
    value: "paid",
    description: "Set a one time price",
  },

  {
    label: "Flexible",
    value: "flexible",
    description: 'Offer different payment levels or "pay what you can"',
  },
  {
    label: "Recurring",
    value: "recurring",
    description: "Set a recurring subscription fee",
  },
];

export default function Paid(props) {
  const [paymentSelection, setPaymentSelection] = useState();
  const propsLoaded = useRef();
  const lastSpaceId = useRef();
  const paymentOptionsList = useRef([...paymentOptions]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      propsLoaded.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.settings) {
      if (propsLoaded.current || props.settings.slug == lastSpaceId.current) {
        return;
      }

      lastSpaceId.current = props.settings.slug;

      setTimeout(() => {
        propsLoaded.current = true;
      }, 500);

      const excluded = [];
      for (let i = 0; i < paymentOptionsList.current.length; i++) {
        let included = false;
        for (let j = 0; j < props.options.length; j++) {
          if (paymentOptionsList.current[i].value == props.options[j]) {
            included = true;
            break;
          }
        }
        if (!included) {
          excluded.push(i);
        }
      }
      for (let i = 0; i < excluded.length; i++) {
        paymentOptionsList.current.splice(excluded[i], 1);
      }

      setTimeout(() => {
        setPaymentSelection(props.settings.circle_payment);
      }, 200);
    }
  }, [props]);

  useEffect(() => {
    if (propsLoaded.current) {
      if (paymentSelection == "paid") {
        props.onUpdate({
          circle_payment: paymentSelection,
          pod_payment_settings_attributes: {
            currency: props.settings.payment_settings.currency,
            default_payment_amount:
              props.settings.payment_settings.default_payment_amount,
            default_payment_frequency: "once",
          },
        });
      } else if (paymentSelection == "flexible") {
        props.onUpdate({
          circle_payment: paymentSelection,
          pod_payment_settings_attributes: {
            currency: props.settings.payment_settings.currency,
            default_payment_amount:
              props.settings.payment_settings.default_payment_amount,
            default_payment_frequency: "once",
          },
        });
      } else if (paymentSelection == "recurring") {
        props.onUpdate({
          circle_payment: paymentSelection,
          pod_payment_settings_attributes: {
            currency: props.settings.payment_settings.currency,
            default_payment_amount:
              props.settings.payment_settings.default_payment_amount,
            default_payment_frequency: "per month",
          },
        });
      } else {
        props.onUpdate({
          circle_payment: paymentSelection,
          pod_payment_settings_attributes: {
            currency: props.settings.payment_settings.currency,
            default_payment_amount:
              props.settings.payment_settings.default_payment_amount,
            default_payment_frequency: "once",
          },
        });
      }
    }
  }, [paymentSelection]);

  const displayPaymentSelection = () => {
    if (paymentSelection == "paid") {
      return (
        <Once
          settings={props.settings}
          onUpdate={props.onUpdate}
          updateAll={props.updateAll}
        />
      );
    } else if (paymentSelection == "flexible") {
      return (
        <Flexible
          settings={props.settings}
          onUpdate={props.onUpdate}
          updateAll={props.updateAll}
          inner={props.inner}
        />
      );
    } else if (paymentSelection == "recurring") {
      return (
        <Recurring
          settings={props.settings}
          onUpdate={props.onUpdate}
          updateAll={props.updateAll}
        />
      );
    } else {
      return null;
    }
  };

  const onConnect = () => {
    StripeModel.onConnect(
      props.space.slug,
      (data) => {
        if (data.url) {
          window.location.href= data.url;
        } else {
          props.onRefresh();
        }
      },
      (data) => {}
    );
  };

  const onDisconnect = () => {

    navigate(`/settings/account`)

    return
    Swal.fire({
      title: "Are you sure?",
      html: "You won't be able to collect any payments.",
      showDenyButton: true,
      confirmButtonText: "Yes, disconnect",
      denyButtonText: "No",
      confirmButtonColor: colors.red,
      denyButtonColor: colors.gray,
    }).then((result) => {
      if (result.isConfirmed) {
        StripeModel.onDisconnect(
          props.space.id,
          (data) => {
            props.onRefresh();
          },
          (data) => {}
        );
      } else if (result.isDenied) {
      }
    });
  };

  const onStripeAccount = () => {
    window.open("https://dashboard.stripe.com/settings/user", "_blank");
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  return (
    <Box mt={20} mb={20}>
      {props.settings && props.settings.can_accept_charges ? (
        <>
          <Alert color="gray" radius="md" mt={15}>
            <SegmentedControl
              value={paymentSelection}
              onChange={setPaymentSelection}
              data={paymentOptionsList.current}
              radius="md"
              size="lg"
              fullWidth
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[2],
              })}
            />

            {displayPaymentSelection()}
          </Alert>
          {!props.inner && <Extras />}
          
          {props.showDisconnect && (
            <Center>
              <Text align="center" mt={10} color={"dimmed"} size="sm">
                {t("spaces.settings.privacy.paid.paid.stripeConnected")}
                <Tooltip
                  disabled={window.$isTouchDevice && window.$isTouchDevice()}
                  label={
                    "This is your Stripe account ID.  To find this ID on Stripe, please click on the link to log into Stripe and scroll to the bottom of your account settings page."
                  }
                  withArrow
                  wrapLines
                  position="bottom"
                  width={300}
                >
                  <Button
                    variant="subtle"
                    radius="xl"
                    compact
                    onClick={() => onStripeAccount()}
                  >
                    {props.settings.payment_settings.stripe_user_id}
                  </Button>
                </Tooltip>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                <Button
                  variant="subtle"
                  radius="xl"
                  compact
                  onClick={() => onDisconnect()}
                >
                  {t("spaces.settings.privacy.paid.paid.disconnectButton")}
                </Button>
              </Text>
            </Center>
          )}
          
        </>
      ) : (
        <div>
          <Text mb={15}>
            {t("spaces.settings.privacy.paid.paid.description")}
          </Text>
          <MantineProvider
            theme={sutraTheme}
            // theme={{ primaryShade: 5 }}
          >
            <Button
              // leftIcon={<FontAwesomeIcon icon={['fab', 'stripe-s']} />}
              // color="green"
              radius="xl"
              onClick={() => onConnect()}
            >
              {t("spaces.settings.privacy.paid.paid.connectButton")}
            </Button>
          </MantineProvider>
        </div>
      )}
    </Box>
  );
}
