import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";
import { sutraTheme } from "../../../collections/sutraTheme";
import tinycolor from "tinycolor2";

import TextIcon from "../../../src/images/buttons/text.png";
import Heading1Icon from "../../../src/images/buttons/heading1.png";
import Heading2Icon from "../../../src/images/buttons/heading2.png";
import Heading3Icon from "../../../src/images/buttons/heading2.png";
import SpaceModel from "../../../models/Space";
import Swal from "sweetalert2";

import colorPickerSwatches from "../../../utils/ColorSwatches";

import {
  Group,
  Button,
  ActionIcon,
  Box,
  createStyles,
  ColorPicker,
  Stack,
  Popover,
  MantineProvider,
  TextInput,
  Menu,
  Tooltip,
  Image,
  Text,
  NativeSelect,
} from "@mantine/core";

const useStyles = createStyles((theme, { colors, sutraTheme }) => ({
  colorButton: {
    color: colors.sidebar_text_color,
    padding: "4px 4px",
    borderRadius: 5,
    marginRight: 10,
    "&:hover": {
      backgroundColor: tinycolor(colors.sidebar_background_color)
        .darken(4)
        .toString(),
    },
  },
  button: {
    borderRadius: 5,
    marginRight: 10,
    color: colors.sidebar_text_color,

    "&:hover": {
      backgroundColor: tinycolor(colors.sidebar_background_color)
        .darken(4)
        .toString(),
    },
  },
  isActive: {
    color: !isSpaceThemed(colors)
      ? sutraTheme.colors.sutrablue[4]
      : colors.sidebar_text_color,
    backgroundColor: !isSpaceThemed(colors)
      ? "#fff"
      : tinycolor(colors.sidebar_background_color).darken(4).toString(),
  },
  toolbarWrap: {
    backgroundColor: colors.sidebar_background_color,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 7,
  },
  menuWrap: {
    cursor: "pointer",
    borderRight: `1px solid ${theme.colors.gray[2]}`,
  },
  menuButton: {
    backgroundColor: colors.sidebar_background_color,
    border: "none",
    height: 28,
  },
}));

const FormatMenuSimple = (props) => {
  const { settingsView, registrationView, listView } = useSidebarStyles();
  let colors = useSelector((state) => state.colors);
  let colors2 = useSelector((state) => state.colors2);

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  colors =
    !settingsView && !registrationView && (!listView || props.useSpace2)
      ? colors
      : defaultSpaceColors;
  colors2 =
    !settingsView && !registrationView && (!listView || props.useSpace2)
      ? colors2
      : defaultSpaceColors;

  const { classes, cx, theme } = useStyles({ colors: getColors(), sutraTheme });

  const [linkOpened, setLinkOpened] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkTarget, setLinkTarget] = useState("_current");
  const [linkFormat, setLinkFormat] = useState("text");
  const [colorOpened, setColorOpened] = useState(false);
  const [colorHOpened, setColorHOpened] = useState(false);
  const [textColor, onTextColorChange] = useState("#000");
  const [auxTextColor, setAuxTextColor] = useState("#000");
  const [highlightColor, onHighlightColorChange] = useState("#FFF");
  const [auxHighlightColor, setAuxHighlightColor] = useState("#FFF");

  const intervalId = useRef();
  const linkOpenedAux = useRef();
  const timeoutId = useRef();

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
    intervalId.current = setInterval(function () {
      if (props.editor && !timeoutId.current) {
        const actualColor = props.editor.getAttributes("textStyle").color;
        const actualHighlightColor =
          props.editor.getAttributes("highlight").color;
        setAuxTextColor(actualColor);
        onTextColorChange(actualColor);
        setAuxHighlightColor(actualHighlightColor);
        onHighlightColorChange(actualHighlightColor);

        const link = props.editor.getAttributes("link");
        if (link && !linkOpenedAux.current) {
          setLinkUrl(link.href);
          setLinkTarget(link.target == "_blank" ? "_blank" : "_current");
        }
      }
    }, 500);
  }, [props]);

  useEffect(() => {
    linkOpenedAux.current = linkOpened;
  }, [linkOpened]);

  useEffect(() => {
    if (props.editor && !props.minimal) {
      const actualColor = props.editor.getAttributes("textStyle").color;
      if (actualColor != textColor) {
        props.editor.chain().focus().setColor(textColor).run();
      }
    }
  }, [textColor]);

  useEffect(() => {
    if (props.editor && !props.minimal) {
      const actualColor = props.editor.getAttributes("highlight").color;
      if (actualColor != highlightColor) {
        props.editor.commands.setHighlight({ color: highlightColor });
      }
    }
  }, [highlightColor]);

  const onChangeColor = (value, callback, callback2, time) => {
    callback2(value);
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      callback(value);
      timeoutId.current = null;
    }, time);
  };
  const { t, i18n } = useTranslation();
  const getFormat = () => {
    if (props.editor.isActive("paragraph")) {
      return t("tiptap.components.formatMenuSimple.t");
    } else if (props.editor.isActive("heading", { level: 1 })) {
      return t("tiptap.components.formatMenuSimple.h1");
    } else if (props.editor.isActive("heading", { level: 2 })) {
      return t("tiptap.components.formatMenuSimple.h2");
    } else if (props.editor.isActive("heading", { level: 3 })) {
      return t("tiptap.components.formatMenuSimple.h3");
    }
    // else if (props.editor.isActive('bulletList')) {
    //   return "Bulleted list"
    // } else if (props.editor.isActive('orderedList')) {
    //   return "Ordered list"
    // }
    else {
      return "Text";
    }
  };
  const getAlignment = () => {
    if (props.editor.isActive({ textAlign: "left" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    } else if (props.editor.isActive({ textAlign: "center" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-center")} />;
    } else if (props.editor.isActive({ textAlign: "right" })) {
      return <FontAwesomeIcon size="sm" icon={solid("align-right")} />;
    } else {
      return <FontAwesomeIcon size="sm" icon={solid("align-left")} />;
    }
  };

  const onUnsetLink = () => {
    props.editor.commands.unsetLink();
    setLinkOpened(false);
  };

  const onSetLink = () => {
    if (linkFormat == "text") {
      if (
        linkUrl.indexOf("http://") < 0 &&
        linkUrl.indexOf("https://") < 0 &&
        linkUrl.indexOf("mailto://") < 0 &&
        linkUrl.indexOf("tel://") < 0
      ) {
        props.editor.commands.setLink({
          href: `https://${linkUrl}`,
          target: linkTarget == "_blank" ? "_blank" : null,
        });
      } else {
        props.editor.commands.setLink({
          href: linkUrl,
          target: linkTarget == "_blank" ? "_blank" : null,
        });
      }
    } else {
      SpaceModel.onFetchLink(
        linkUrl,
        (data) => {
          props.editor
            .chain()
            .enter()
            .insertContent({
              type: "fancyLink",
              attrs: {
                link: {
                  url: linkUrl,
                  target: linkTarget == "_blank" ? "_blank" : null,
                  title: data.data.title,
                  description: data.data.description,
                  image: {
                    url: data.data.image.url,
                  },
                },
              },
            })
            .run();
        },
        (data) => {
          Swal.fire("Opps, something went wrong!", data.message, "error");
        }
      );
    }
    setLinkOpened(false);
  };

  if (!props.editor) {
    return null;
  }

  return (
    <Box className={classes.toolbarWrap}>
      <Group spacing={0}>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleBold().run()}
          // className={props.editor.isActive('bold') ? `is-active ${classes.button}` : `${classes.button}`}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("bold"),
          })}
        >
          <FontAwesomeIcon icon={solid("bold")} size="sm" />
        </ActionIcon>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleItalic().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("italic"),
          })}
        >
          <FontAwesomeIcon icon={solid("italic")} size="sm" />
        </ActionIcon>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleStrike().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("strike"),
          })}
        >
          <FontAwesomeIcon icon={solid("strikethrough")} size="sm" />
        </ActionIcon>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleUnderline().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("underline"),
          })}
        >
          <FontAwesomeIcon icon={solid("underline")} size="sm" />
        </ActionIcon>

        <Popover
          zIndex={999999}
          opened={linkOpened}
          onClose={() => setLinkOpened(false)}
          target={
            <Stack
              onClick={() => setLinkOpened(true)}
              spacing={1}
              align="center"
              className={cx(classes.colorButton)}
              sx={(theme) => ({
                cursor: "pointer",
              })}
            >
              {props.editor.isActive("link") ? (
                <FontAwesomeIcon icon={solid("link-slash")} size="sm" />
              ) : (
                <FontAwesomeIcon icon={solid("link")} size="sm" />
              )}
            </Stack>
          }
          position="bottom"
          gutter={15}
        >
          <Stack mt={20}>
            {props.exclude.indexOf("link-action") < 0 && (
              <NativeSelect
                value={linkTarget}
                onChange={(event) => setLinkTarget(event.target.value)}
                label={t("tiptap.components.formatMenuSimple.label1")}
                description={t(
                  "tiptap.components.formatMenuSimple.description1"
                )}
                placeholder={t(
                  "tiptap.components.formatMenuSimple.placeholder1"
                )}
                data={[
                  {
                    value: "_current",
                    label: t("tiptap.components.formatMenuSimple.label2"),
                  },
                  {
                    value: "_blank",
                    label: t("tiptap.components.formatMenuSimple.label3"),
                  },
                ]}
                styles={{
                  label: {
                    fontWeight: 400,
                    color: "#000",
                  },
                }}
              />
            )}
            {/* <p>{`HELLO - ${linkUrl}`}</p> */}
            <TextInput
              mt={5}
              value={linkUrl}
              onChange={(event) => setLinkUrl(event.currentTarget.value)}
              placeholder={`Link url`}
              label={`Link url`}
              description={`Specify the URL link the user will be taken to`}
              styles={{
                label: {
                  fontWeight: 400,
                  color: "#000",
                },
              }}
            />
            {props.exclude.indexOf("link-format") < 0 && (
              <NativeSelect
                value={linkFormat}
                onChange={(event) => setLinkFormat(event.target.value)}
                label={t("tiptap.components.formatMenu.label4")}
                description={t(
                  "tiptap.components.formatMenuSimple.description2"
                )}
                placeholder={t(
                  "tiptap.components.formatMenuSimple.placeholder2"
                )}
                data={[
                  {
                    value: "text",
                    label: t("tiptap.components.formatMenuSimple.label5"),
                  },
                  {
                    value: "fancy",
                    label: t("tiptap.components.formatMenuSimple.label6"),
                  },
                ]}
                styles={{
                  label: {
                    fontWeight: 400,
                    color: "#000",
                  },
                }}
              />
            )}
            <Group position="apart">
              <Button radius="xl" onClick={() => onSetLink()}>
                {t("tiptap.components.formatMenuSimple.save")}
              </Button>
              {linkUrl && (
                <Button radius="xl" color="red" onClick={() => onUnsetLink()}>
                  {t("tiptap.components.formatMenuSimple.delete")}
                </Button>
              )}
            </Group>
          </Stack>
        </Popover>

        <Menu
          zIndex={99999}
          position="bottom"
          placement="start"
          size="md"
          p={5}
          // closeOnItemClick={false}
          className={classes.menuWrap}
          gutter={10}
          styles={{
            item: {
              padding: "5px 10px",
            },
          }}
          control={
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label={t("tiptap.components.formatMenuSimple.label7")}
              position="top"
              withArrow
              zIndex={999999}
            >
              <Button
                variant="default"
                className={classes.menuButton}
                rightIcon={
                  <FontAwesomeIcon
                    size="sm"
                    color={theme.colors.gray[5]}
                    icon={solid("angle-down")}
                  />
                }
                compact
              >
                {getFormat()}
              </Button>
            </Tooltip>
          }
        >
          <Menu.Item
            onClick={() => props.editor.chain().setParagraph().run()}
            // className={props.editor.isActive('paragraph') ? `isActive ${classes.button}` : `${classes.button}`}
          >
            <Group spacing={10}>
              <Image width={"20px"} src={TextIcon} />
              <Text>T</Text>
            </Group>
          </Menu.Item>
          <Menu.Item
            onClick={() => props.editor.commands.toggleHeading({ level: 1 })}
            // className={props.editor.isActive('heading', { level: 1 }) ? `isActive ${classes.button}` : `${classes.button}`}
          >
            <Group spacing={10}>
              <Image width={"20px"} src={Heading1Icon} />
              <Text>{t("tiptap.components.formatMenuSimple.h1")}</Text>
            </Group>
          </Menu.Item>
          <Menu.Item
            onClick={() => props.editor.commands.toggleHeading({ level: 2 })}
            // className={props.editor.isActive('heading', { level: 2 }) ? `isActive ${classes.button}` : `${classes.button}`}
          >
            <Group spacing={10}>
              <Image width={"20px"} src={Heading2Icon} />
              <Text>{t("tiptap.components.formatMenuSimple.h2")}</Text>
            </Group>
          </Menu.Item>
          <Menu.Item
            onClick={() => props.editor.commands.toggleHeading({ level: 3 })}
            // className={props.editor.isActive('heading', { level: 3 }) ? `isActive ${classes.button}` : `${classes.button}`}
          >
            <Group spacing={10}>
              <Image width={"20px"} src={Heading3Icon} />
              <Text>{t("tiptap.components.formatMenuSimple.h3")}</Text>
            </Group>
          </Menu.Item>
        </Menu>
        <Menu
          zIndex={99999}
          position="bottom"
          placement="start"
          size="md"
          p={5}
          // closeOnItemClick={false}
          className={classes.menuWrap}
          gutter={10}
          styles={{
            item: {
              padding: "5px 10px",
            },
          }}
          control={
            <Tooltip
              disabled={window.$isTouchDevice && window.$isTouchDevice()}
              label="Alignment"
              position="top"
              withArrow
              zIndex={999999}
            >
              <Button
                variant="default"
                className={classes.menuButton}
                rightIcon={
                  <FontAwesomeIcon
                    size="sm"
                    color={theme.colors.gray[5]}
                    icon={solid("angle-down")}
                  />
                }
                compact
              >
                {getAlignment()}
              </Button>
            </Tooltip>
          }
        >
          <Menu.Item
            onClick={() =>
              props.editor.chain().focus().setTextAlign("left").run()
            }
          >
            <Group spacing={10}>
              <FontAwesomeIcon size="sm" icon={solid("align-left")} />
              <Text>{t("tiptap.components.formatMenuSimple.left")}</Text>
            </Group>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              props.editor.chain().focus().setTextAlign("center").run()
            }
          >
            <Group spacing={10}>
              <FontAwesomeIcon size="sm" icon={solid("align-center")} />
              <Text>{t("tiptap.components.formatMenuSimple.center")}</Text>
            </Group>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              props.editor.chain().focus().setTextAlign("right").run()
            }
          >
            <Group spacing={10}>
              <FontAwesomeIcon size="sm" icon={solid("align-right")} />
              <Text>{t("tiptap.components.formatMenuSimple.right")}</Text>
            </Group>
          </Menu.Item>
        </Menu>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleBulletList().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("bulletList"),
          })}
        >
          <FontAwesomeIcon icon={solid("list")} size="sm" />
        </ActionIcon>
        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleOrderedList().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("orderedList", {
              level: 1,
            }),
          })}
        >
          <FontAwesomeIcon icon={solid("list-ol")} size="sm" />
        </ActionIcon>

        <ActionIcon
          onClick={() => props.editor.chain().focus().toggleCode().run()}
          className={cx(classes.button, {
            [classes.isActive]: props.editor.isActive("code"),
          })}
        >
          <FontAwesomeIcon icon={solid("code")} size="sm" />
        </ActionIcon>
        {!props.minimal && (
          <>
            <Popover
              zIndex={999999}
              opened={colorOpened}
              onClose={() => setColorOpened(false)}
              target={
                /*
              <ColorSwatch
              key={textColor}
              color={textColor}
              onClick={() => setColorOpened((o) => !o)}
              size={20} />
              */

                <Stack
                  onClick={() => setColorOpened((o) => !o)}
                  spacing={1}
                  align="center"
                  className={cx(classes.colorButton)}
                  sx={(theme) => ({
                    cursor: "pointer",
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("font")} />
                  <Box
                    sx={(theme) => ({
                      backgroundColor: textColor,
                      height: 5,
                      width: 20,
                      borderRadius: 3,
                    })}
                  ></Box>
                </Stack>
              }
              position="bottom"
              gutter={15}
            >
              <MantineProvider theme={{ primaryShade: 9 }}>
                <ColorPicker
                  swatches={colorPickerSwatches(theme, "dark")}
                  swatchesPerRow={8}
                  format="hex"
                  value={textColor}
                  onChange={onTextColorChange}
                />
                <TextInput
                  mt={5}
                  mb={10}
                  placeholder={t(
                    "tiptap.components.formatMenuSimple.placeholder3"
                  )}
                  value={auxTextColor}
                  onChange={(event) =>
                    onChangeColor(
                      event.target.value,
                      onTextColorChange,
                      setAuxTextColor,
                      2000
                    )
                  }
                />
                {auxTextColor && (
                  <Button
                    mb={8}
                    compact
                    fullWidth
                    variant="light"
                    color="blue"
                    onClick={() =>
                      onChangeColor(
                        auxTextColor,
                        onTextColorChange,
                        setAuxTextColor,
                        0
                      )
                    }
                  >
                    {"Apply color"}
                  </Button>
                )}
                <Button
                  compact
                  fullWidth
                  variant="light"
                  color="gray"
                  onClick={() => props.editor.commands.unsetColor()}
                >
                  {t("tiptap.components.formatMenuSimple.unsetColor")}
                </Button>
              </MantineProvider>
            </Popover>
            <Popover
              zIndex={999999}
              opened={colorHOpened}
              onClose={() => setColorHOpened(false)}
              target={
                /*<ColorSwatch
              key={highlightColor}
              color={highlightColor}
              onClick={() => setColorHOpened((o) => !o)}
              size={20} />
              */

                <Stack
                  onClick={() => setColorHOpened((o) => !o)}
                  spacing={1}
                  align="center"
                  className={cx(classes.colorButton)}
                  sx={(theme) => ({
                    cursor: "pointer",
                  })}
                >
                  <FontAwesomeIcon size="sm" icon={solid("highlighter")} />
                  <Box
                    sx={(theme) => ({
                      backgroundColor: highlightColor,
                      height: 5,
                      width: 20,
                      borderRadius: 3,
                    })}
                  ></Box>
                </Stack>
              }
              position="bottom"
              gutter={15}
            >
              <MantineProvider theme={{ primaryShade: 9 }}>
                <ColorPicker
                  swatches={colorPickerSwatches(theme, "light")}
                  swatchesPerRow={8}
                  format="hex"
                  value={highlightColor}
                  onChange={onHighlightColorChange}
                />
                <TextInput
                  mt={5}
                  mb={10}
                  placeholder={t(
                    "tiptap.components.formatMenuSimple.placeholder4"
                  )}
                  value={auxHighlightColor}
                  onChange={(event) =>
                    onChangeColor(
                      event.target.value,
                      onHighlightColorChange,
                      setAuxHighlightColor,
                      2000
                    )
                  }
                />
                {auxHighlightColor && (
                  <Button
                    mb={8}
                    compact
                    fullWidth
                    variant="light"
                    color="blue"
                    onClick={() =>
                      onChangeColor(
                        auxHighlightColor,
                        onHighlightColorChange,
                        setAuxHighlightColor,
                        0
                      )
                    }
                  >
                    {"Apply color"}
                  </Button>
                )}
                <Button
                  compact
                  fullWidth
                  variant="light"
                  color="gray"
                  onClick={() => props.editor.commands.unsetHighlight()}
                >
                  {t("tiptap.components.formatMenuSimple.unsetColor")}
                </Button>
              </MantineProvider>
            </Popover>
          </>
        )}
      </Group>
    </Box>
  );
};

export default FormatMenuSimple;
