import React, { useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Stack, NativeSelect } from "@mantine/core";
import { DatePicker } from "@mantine/dates";

export default function InteractiveText(props) {
  const timeoutId = useRef(null);

  const loading = useRef(false);
  const { t, i18n } = useTranslation();
  const tiptap = useSelector((state) => state.tiptap);
  const [type, setType] = useState("datetime");
  const [value, setValue] = useState();

  useEffect(() => {
    if (
      !props.element ||
      !props.element.node.attrs ||
      (props.element &&
        props.element.node.attrs &&
        loading.current != props.element.node.attrs.uid)
    ) {
      return;
    }

    const node = props.element;

    if (value) {
      const data = {};
      data.interactiveWaitValue =
        type == "datetime" ? value.toLocaleDateStringAbs() : value;
      if (data.interactiveWaitValue.indexOf("NaN") >= 0) {
        data.interactiveWaitValue = new Date(
          new Date().getTime() + 90000000
        ).toLocaleDateStringAbs();
      }
      data.interactiveWaitType = type;

      tiptap.editor.commands.updateAttributes(
        props.element.node.type.name,
        data
      );

      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        timeoutId.current = null;
        props.saveNodeData();
      }, 2000);
    }
  }, [value]);

  useEffect(() => {
    if (type != props.element.node.attrs.interactiveWaitType) {
      if (type == "datetime") {
        setValue(new Date(new Date().getTime() + 90000000));
      } else {
        setValue("24h");
      }
    }
  }, [type]);

  useEffect(() => {
    if (props.element && props.element.node.attrs.styles) {
      loading.current = props.element.node.attrs.uid;

      setType(props.element.node.attrs.interactiveWaitType);
      if (props.element.node.attrs.interactiveWaitType == "datetime") {
        if (props.element.node.attrs.interactiveWaitValue.indexOf("NaN") >= 0) {
          setValue(new Date(new Date().getTime() + 90000000));
        } else {
          setValue(
            props.element.node.attrs.interactiveWaitValue
              ? new Date(props.element.node.attrs.interactiveWaitValue)
              : ""
          );
        }
      } else {
        setValue(props.element.node.attrs.interactiveWaitValue);
      }
    }
  }, [props.element]);

  return (
    <Stack mt={20}>
      <NativeSelect
        label={t("tiptap.components.sidebar.interactiveWaitTime.label1")}
        value={type}
        onChange={(event) => setType(event.currentTarget.value)}
        data={[
          {
            label: t("tiptap.components.sidebar.interactiveWaitTime.label2"),
            value: `datetime`,
          },
          {
            label: t("tiptap.components.sidebar.interactiveWaitTime.label3"),
            value: `days`,
          },
        ]}
      />
      {type == "datetime" ? (
        <DatePicker
          zIndex={99999}
          placeholder={t(
            "tiptap.components.sidebar.interactiveWaitTime.placeholder"
          )}
          label={t("tiptap.components.sidebar.interactiveWaitTime.label4")}
          value={value}
          onChange={setValue}
        />
      ) : (
        <NativeSelect
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          data={[
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label5"),
              value: `${1 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label6"),
              value: `${2 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label7"),
              value: `${3 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label8"),
              value: `${4 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label9"),
              value: `${5 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label10"),
              value: `${6 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label11"),
              value: `${7 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label12"),
              value: `${14 * 24}h`,
            },
            {
              label: t("tiptap.components.sidebar.interactiveWaitTime.label13"),
              value: `${30 * 24}h`,
            },
          ]}
        />
      )}
    </Stack>
  );
}
