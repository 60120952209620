import React from "react";
import ParagraphIcon from "../../../src/images/buttons/text.png";
import Headline1Icon from "../../../src/images/buttons/heading1.png";
import Headline2Icon from "../../../src/images/buttons/heading2.png";
import Headline3Icon from "../../../src/images/buttons/heading3.png";
import BulletListIcon from "../../../src/images/buttons/list_bullet.png";
import OrderedListIcon from "../../../src/images/buttons/list_ordered.png";
import CheckListIcon from "../../../src/images/buttons/list_checklist.png";
import ResourceTopicsIcon from "../../../src/images/buttons/collapsible_topic.png";
import ImageIcon from "../../../src/images/buttons/image.png";
import EmbedIcon from "../../../src/images/buttons/embeded.png";
import VideoIcon from "../../../src/images/buttons/video.png";
import AudioIcon from "../../../src/images/buttons/audio.png";
import FileIcon from "../../../src/images/buttons/file.png";
import TagsIcon from "../../../src/images/buttons/tag_cloud.png";
import SurveyIcon from "../../../src/images/buttons/survey.png";

import ContentIcon from "../../../src/images/buttons/content.png";
import DiscussionIcon from "../../../src/images/buttons/discussion.png";
import EventIcon from "../../../src/images/buttons/event.png";
import CalendarIcon from "../../../src/images/buttons/calendar.png";
import ForumIcon from "../../../src/images/buttons/forum.png";
import ShowcaseIcon from "../../../src/images/buttons/showcase.png";

import Grid1 from "../../../src/images/grid/one_column.png";
import Grid2 from "../../../src/images/grid/two_columns.png";
import Grid3 from "../../../src/images/grid/three_columns.png";
import Grid4 from "../../../src/images/grid/left_column.png";
import Grid5 from "../../../src/images/grid/right_column.png";
import Grid6 from "../../../src/images/grid/four_columns.png";

import ListBuilder from "../../../src/images/buttons/list_builder.png";
import ActionButton from "../../../src/images/buttons/action_button.png";
import BundleButton from "../../../src/images/buttons/bundle_button.png";

import InteractiveInput from "../../../src/images/buttons/automation_response.png";
import InteractiveButton from "../../../src/images/buttons/automation_next.png";
import InteractiveWait from "../../../src/images/buttons/automation_time_block.png";
import Divider from "../../../src/images/buttons/divider.png";
import InfoBox from "../../../src/images/buttons/info_box.png";
import MarkComplete from "../../../src/images/buttons/mark_complete.png";
import ZoomIcon from "../../../src/images/buttons/zoom.png";

import { DEFAULT_THEME } from "@mantine/core";

import { useTranslation } from "react-i18next";

import {
  isTopNode,
  getTopParentNode,
  closestInteraction,
  updateInteractionsPositions,
} from "../utils/selection";

const onToggleFormat = (editor, format) => {
  if (!window.$tiptapLastSelectionRange) {
    return;
  }

  const { from, to } = window.$tiptapLastSelectionRange;
  if (format == "p") {
    editor.commands.insertContentAt({ from, to }, `<p></p>`, {
      updateSelection: true,
      parseOptions: { preserveWhitespace: "full" },
    });
    setTimeout(() => {
      editor.commands.focus();
    }, 200);
  } else {
    const level = format.charAt(1);

    editor.commands.insertContentAt(
      { from, to },
      `<${format} level="${level}"></${format}>`,
      { updateSelection: true, parseOptions: { preserveWhitespace: "full" } }
    );
  }
  // editor.chain().focus().setTextSelection({ from: to + 1, to: to + 1 }).selectTextblockEnd().run()
};

export const sideMenuOptions = () => {
  const { t, i18n } = useTranslation();

  return [
    {
      name: t("tiptap.blocks.section7"), //interactive
      only_beta: true,
      blocks: [
        {
          tooltip: "Add an input box for discussion or comments",
          name: t("tiptap.blocks.interactiveInput"),
          image: InteractiveInput,
          type: "interactive_input",
          callback: (e, editor, id, circle_id, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            const interaction = closestInteraction(
              editor,
              editor.view.state.selection.to
            );

            const nextPos = interaction
              ? interaction.interactivePosition + 1
              : 0;
            debugger
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<interactiveinput interactiveInputLabel="How do you feel?" interactiveInputPlaceholder="Write something..." interactivePosition="${nextPos}" buttonText="Send" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}" id="${id}" circle_id="${circle_id}" radius="circle-radius" ></interactiveinput><p></p>`
              )
              .run();

            setTimeout(() => {
              updateInteractionsPositions(editor);
            }, 500);
          },
        },
        {
          tooltip: "Content after this button will not be visible until the button is clicked",
          name: t("tiptap.blocks.interactiveButton"),
          image: InteractiveButton,
          type: "interactive_button",
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            const interaction = closestInteraction(
              editor,
              editor.view.state.selection.to
            );

            const nextPos = interaction
              ? interaction.interactivePosition + 1
              : 0;

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<interactivebutton buttonText="Continue" interactivePosition="${nextPos}" radius="circle-radius" class="tiptap-size-actual" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}"></interactivebutton><p></p>`
              )
              .run();

            setTimeout(() => {
              updateInteractionsPositions(editor);
            }, 500);
          },
        },
        {
          tooltip: "Content after this section will not be visible until a time period has passed",
          name: t("tiptap.blocks.interactiveWait"),
          image: InteractiveWait,
          type: "interactive_wait",
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            const interaction = closestInteraction(
              editor,
              editor.view.state.selection.to
            );

            const nextPos = interaction
              ? interaction.interactivePosition + 1
              : 0;

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<interactivewait interactiveWaitMessage="Please wait..." interactiveWaitShowTimeLeft="true" interactiveWaitType="datetime" interactivePosition="${nextPos}" interactiveWaitValue="${new Date(
                  new Date().getTime() + 90000000
                ).toLocaleDateStringAbs()}"></interactivewait><p></p>`
              )
              .run();

            setTimeout(() => {
              updateInteractionsPositions(editor);
            }, 500);
          },
        },
      ],
    },
    {
      name: t("tiptap.blocks.section4"), //media
      blocks: [
        {
          tooltip: "Upload or embed with a link",
          name: t("tiptap.blocks.image"),
          image: ImageIcon,
          type: "image",
          callback: () => {},
        },
        {
          tooltip: "Upload or embed with a link",
          name: t("tiptap.blocks.video"),
          image: VideoIcon,
          type: "video",
          callback: () => {},
        },
        {
          tooltip: "Embed videos, docs, and more",
          name: t("tiptap.blocks.embed"),
          image: EmbedIcon,
          type: "embed",
          callback: () => {},
        },
        {
          tooltip: "Upload or embed with a link",
          name: t("tiptap.blocks.audio"),
          image: AudioIcon,
          type: "audio",
          callback: () => {},
        },
        {
          tooltip: "Upload or embed with a link",
          name: t("tiptap.blocks.file"),
          image: FileIcon,
          type: "file",
          callback: () => {},
        },
        {
          tooltip: "Automatically save a Zoom recording to Sutra",
          name: t("tiptap.blocks.zoom"),
          image: ZoomIcon,
          type: "zoom",
          callback: () => {},
        },
      ],
    },
    {
      name: t("tiptap.blocks.section6"), //buttons
      blocks: [
        {
          tooltip: "Create a call to action or link to a registration page",
          name: t("tiptap.blocks.actionButton"),
          image: ActionButton,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange
            // editor.chain().focus().setTextSelection({ from: to, to: to }).enter().run()

            const target =
              window.location.pathname.indexOf("/registration") >= 0
                ? "_current"
                : "_blank";

            // debugger;
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<actionbutton radius="circle-radius" actionCallbackTarget="${target}" class="tiptap-size-actual" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}"></actionbutton><p></p>`
              )
              .run();
          },
        },
        {
          tooltip: "Clicking this button will mark this section complete",
          name: t("tiptap.blocks.markComplete"),
          image: MarkComplete,
          type: "mark_complete_button",
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange
            // editor.chain().focus().setTextSelection({ from: to, to: to }).enter().run()

            const target = "_nothing";

            // debugger;
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<actionbutton radius="circle-radius" actionCallbackTarget="${target}" class="tiptap-size-actual" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}" isMarkComplete="true" buttonText="Mark complete"></actionbutton><p></p>`
              )
              .run();
          },
        },
        {
          tooltip: "Create a collection of spaces that can be purchased in one go",
          name: t("tiptap.blocks.bundleButton"),
          image: BundleButton,
          type: "bundle_button",
          callback: (e, editor, colors) => {},
        },
      ],
    },
    {
      name: t("tiptap.blocks.section8"), //special
      blocks: [
        {
          tooltip: "Make you content stand out with a fancy box",
          name: t("tiptap.blocks.infobox"),
          layout: "full_width",
          image: InfoBox,
          callback: (e, editor, colors) => {
            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<infobox><column position="1" size="12" nestedradius="with-radius"><p></p></column></infobox><p></p>'
              )
              .run();

            setTimeout(() => {
              editor.commands.setTextSelection({
                from: editor.view.state.selection.to - 4,
                to: editor.view.state.selection.to - 4,
              });

              const { selection } = editor.view.state;
              const { $anchor } = selection;
              const { parent, path } = $anchor;

              const newStyles = {};

              newStyles["align-items"] = "flex-start";
              newStyles["background-color"] = DEFAULT_THEME.colors.orange[1];
              newStyles["background-image"] = "none";
              newStyles["background-position"] = "center center";
              newStyles["background-size"] = "cover";
              newStyles["background-repeat"] = "no-repeat";
              newStyles["margin-top"] = "0px";
              newStyles["margin-bottom"] = "0px";
              newStyles["margin-left"] = "0px";
              newStyles["margin-right"] = "0px";
              newStyles["padding-top"] = "20px";
              newStyles["padding-bottom"] = "20px";
              newStyles["padding-left"] = "25px";
              newStyles["padding-right"] = "25px";

              const { tr } = editor.view.state;
              tr.setNodeMarkup(path[5], undefined, {
                ...path[6].attrs,
                styles: newStyles,
              });
              editor.view.dispatch(tr);
            }, 10);
          },
        },
        {
          tooltip: "Visually divide content on this page",
          name: t("tiptap.blocks.divider"),
          image: Divider,
          callback: (e, editor) => {
            editor.chain().focus().setHorizontalRule().run();
          },
        },
        {
          tooltip: "Create a collapsible section with content and sub pages",
          name: t("tiptap.blocks.collapsableSection"),
          type: "resource",
          image: ResourceTopicsIcon,
          callback: (e, editor, colors) => {},
        },
        {
          tooltip: "Add a survey or quiz",
          name: t("tiptap.blocks.survey"),
          type: "survey",
          image: SurveyIcon,
          callback: (e, editor, colors) => {},
        },
        {
          tooltip: "Add a form to collect first name and email address",
          name: t("tiptap.blocks.interestForm"),
          image: ListBuilder,
          type: "interest_form",
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange
            // editor.chain().focus().setTextSelection({ from: to, to: to }).enter().run()

            // debugger;
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                `<customform radius="circle-radius" actionCallback="message" actionCallbackValue="Thank you for registering!" buttonBackgroundColor="${colors.primary_button_background_color}" buttonBorderColor="${colors.primary_button_background_color}" buttonTextColor="${colors.primary_button_text_color}"></customform><p></p>`
              )
              .run();
          },
        },
        {
          tooltip: "Pull tagged messages from chat spaces and discussion prompts",
          name: "Tag feed",
          type: "tags-feed",
          image: TagsIcon,
          callback: (e, editor, colors) => {
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(`<tagsfeed></tagsfeed><p></p>`)
              .run();
          },
        },
        
        // {
        //   name: "Collapsable content",
        //   image: Banner2,
        //   callback: (e, editor) => {
        //     const {from, to} = window.$tiptapLastSelectionRange
        //     editor.chain().focus().setTextSelection({ from: to, to: to }).setDetails().run()
        //   }
        // }
      ],
    },
    {
      name: t("tiptap.blocks.section2"), //spaces
      blocks: [
        {
          tooltip: "Create a space for a learning module or general content",
          name: t("tiptap.blocks.content"),
          type: "content",
          image: ContentIcon,
          callback: () => {},
        },
        {
          tooltip: "Create a post feed where people can add discussion topics",
          name: t("tiptap.blocks.list"),
          type: "list",
          image: ForumIcon,
          callback: () => {},
        },
        {
          tooltip: "Create a messaging space for discussion or announcements",
          name: t("tiptap.blocks.discussion"),
          type: "discussion",
          image: DiscussionIcon,
          callback: () => {},
        },
        {
          tooltip: "Create a single event",
          name: t("tiptap.blocks.event"),
          type: "event",
          image: EventIcon,
          callback: () => {},
        },
        {
          tooltip: "Create a list of events",
          name: t("tiptap.blocks.events"),
          type: "events",
          image: CalendarIcon,
          callback: () => {},
        },
        {
          tooltip: "Create a gallery of spaces",
          name: t("tiptap.blocks.showcase"),
          type: "showcase",
          image: ShowcaseIcon,
          callback: () => {},
        },
      ],
    },
    {
      name: t("tiptap.blocks.section5"), //grids
      blocks: [
        {
          tooltip: "Add a single column grid",
          name: t("tiptap.blocks.grid1"),
          layout: "full_width",
          image: Grid1,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}
            // debugger;
            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid1><column position="1" size="12"><p></p></column></grid1><p></p>'
              )
              .run();
            // debugger;
            setTimeout(() => {
              editor.commands.setTextSelection({
                from: editor.view.state.selection.to - 4,
                to: editor.view.state.selection.to - 4,
              });
            }, 10);
          },
        },
        {
          tooltip: "Add a two column grid",
          name: t("tiptap.blocks.grid2"),
          layout: "full_width",
          image: Grid2,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid2><column position="1"><p></p></column><column position="2"><p></p></column></grid2><p></p>'
              )
              .run();
            // debugger;
            setTimeout(() => {
              editor.commands.setTextSelection({
                from: editor.view.state.selection.to - 4,
                to: editor.view.state.selection.to - 4,
              });
            }, 10);
          },
        },
        {
          tooltip: "Add a three column grid",
          name: t("tiptap.blocks.grid3"),
          layout: "full_width",
          image: Grid3,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid3><column size="4" position="1"><p></p></column><column size="4" position="2"><p></p></column><column size="4" position="3"><p></p></column></grid3><p></p>'
              )
              .run();
          },
        },
        {
          tooltip: "Add a four column grid",
          name: t("tiptap.blocks.grid6"),
          layout: "full_width",
          image: Grid6,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid4 dist="3,3,3,3"><column size="3" position="1"><p></p></column><column size="3" position="2"><p></p></column><column size="3" position="3"><p></p></column><column size="3" position="4"><p></p></column></grid4><p></p>'
              )
              .run();
          },
        },
        {
          tooltip: "Add a two column grid with a narrow left column",
          name: t("tiptap.blocks.grid4"),
          layout: "full_width",
          image: Grid4,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid2 dist="4,8"><column size="4" position="1"><p></p></column><column size="8" position="2"><p></p></column></grid2><p></p>'
              )
              .run();
          },
        },
        {
          tooltip: "Add a two column grid with a narrow right column",
          name: t("tiptap.blocks.grid5"),
          layout: "full_width",
          image: Grid5,
          callback: (e, editor, colors) => {
            // const {from, to} = window.$tiptapLastSelectionRange

            try {
              if (!isTopNode(editor)) {
                const parentNode = getTopParentNode(editor);
                editor
                  .chain()
                  .setNodeSelection(parentNode.pos)
                  .enter()
                  .focus()
                  .run();
              }
            } catch (error) {}

            editor
              .chain()
              .addNewEmptyLine()
              .insertContent(
                '<grid2 dist="8,4"><column size="8" position="1"><p></p></column><column size="4" position="2"><p></p></column></grid2><p></p>'
              )
              .run();
          },
        },
        
      ],
    },
    {
      name: t("tiptap.blocks.section1"), //basic
      blocks: [
        {
          tooltip: "Just start writing with plain text",
          name: t("tiptap.blocks.text"),
          image: ParagraphIcon,
          callback: (e, editor, colors) => {
            onToggleFormat(editor, "p");
          },
        },
        {
          tooltip: "Big section heading",
          name: t("tiptap.blocks.headline1"),
          image: Headline1Icon,
          callback: (e, editor, colors) => {
            onToggleFormat(editor, "h1");
          },
        },
        {
          tooltip: "Medium section heading",
          name: t("tiptap.blocks.headline2"),
          image: Headline2Icon,
          callback: (e, editor, colors) => {
            onToggleFormat(editor, "h2");
          },
        },
        {
          tooltip: "Small section heading",
          name: t("tiptap.blocks.headline3"),
          image: Headline3Icon,
          callback: (e, editor, colors) => {
            onToggleFormat(editor, "h3");
          },
        },
        {
          tooltip: "Create a bulleted list",
          name: t("tiptap.blocks.bulletList"),
          image: BulletListIcon,
          callback: (e, editor, colors) => {
            const { from, to } = window.$tiptapLastSelectionRange;

            if (!window.$tiptapLastSelectionRange) {
              return;
            }

            editor
              .chain()
              .focus()
              .setTextSelection({ from: to, to: to })
              .toggleBulletList()
              .setCustomStyles({
                "margin-top": "0px",
                "margin-bottom": "0px",
                "margin-left": "0px",
                "margin-right": "0px",
                "padding-top": "4px",
                "padding-bottom": "4px",
                "padding-left": "0px",
                "padding-right": "0px",
                "background-color": "",
                "background-image": "none",
                "background-position": "center center",
                "background-size": "cover",
                "background-repeat": "no-repeat",
              })
              .run();
          },
        },
        {
          tooltip: "Create a numbered list",
          name: t("tiptap.blocks.orderedList"),
          image: OrderedListIcon,
          callback: (e, editor, colors) => {
            const { from, to } = window.$tiptapLastSelectionRange;

            if (!window.$tiptapLastSelectionRange) {
              return;
            }

            editor
              .chain()
              .focus()
              .setTextSelection({ from: to, to: to })
              .toggleOrderedList()
              .setCustomStyles({
                "margin-top": "0px",
                "margin-bottom": "0px",
                "margin-left": "0px",
                "margin-right": "0px",
                "padding-top": "4px",
                "padding-bottom": "4px",
                "padding-left": "0px",
                "padding-right": "0px",
                "background-color": "",
                "background-image": "none",
                "background-position": "center center",
                "background-size": "cover",
                "background-repeat": "no-repeat",
              })
              .run();
          },
        },
        {
          tooltip: "Create a checklist",
          name: t("tiptap.blocks.checklist"),
          image: CheckListIcon,
          callback: (e, editor, colors) => {
            const { from, to } = window.$tiptapLastSelectionRange;

            if (!window.$tiptapLastSelectionRange) {
              return;
            }

            editor
              .chain()
              .focus()
              .setTextSelection({ from: to, to: to })
              .toggleTaskList()
              .setCustomStyles({
                "margin-top": "0px",
                "margin-bottom": "0px",
                "margin-left": "0px",
                "margin-right": "0px",
                "padding-top": "4px",
                "padding-bottom": "4px",
                "padding-left": "0px",
                "padding-right": "0px",
                "background-color": "",
                "background-image": "none",
                "background-position": "center center",
                "background-size": "cover",
                "background-repeat": "no-repeat",
              })
              .run();
          },
        },
      ],
    },    
  ];
};
