import { isTextSelection } from "@tiptap/core";
import Broadcaster from "../../../helpers/Broadcaster.js";

import { v4 as uuidv4 } from "uuid";

export function getLeaf(editor, slice = null) {
  const content = slice ? slice : editor.view.state.selection.content();
  return getNextInnerContent(content);
}

function getNextInnerContent(selectionContent, parentContent = null) {
  const innerContent = selectionContent.content.content;
  if (innerContent.length == 0) {
    return { leaf: selectionContent, parent: parentContent };
  } else {
    if (innerContent[0].content.content.length == 0) {
      return getNextInnerContent(innerContent[0], selectionContent);
    }
    return getNextInnerContent(innerContent[0]);
  }
}

export function isSingleCircleNode(editor, slice = null) {
  if (!(slice && slice.content && slice.content.content)) {
    return false;
  }
  const nodes = slice.content.content;
  if (nodes.length == 1 && nodes[0].content.content.length == 1) {
    return getLeaf(editor, slice).type.name == "circle";
  }
}

export function isSingleNodeOf(editor, type, slice = null) {
  const nodes = (slice ? slice : editor.view.state.selection.content()).content
    .content;
  if (nodes.length == 1) {
    return nodes[0].type.name == type;
  }
}

export function getSimpleStructure(editor, slice = null) {
  const structure = [];
  return convertToSimpleStructure(structure, editor, slice);
}

function convertToSimpleStructure(structure, editor, slice = null) {
  const nodes = (slice ? slice : editor.view.state.selection.content()).content
    .content;

  for (let i = 0; i < nodes.length; i++) {
    const newNodeStructure = {
      type: nodes[i].type.name,
      attrs: { ...nodes[i].attrs },
      content: [],
    };
    convertToSimpleStructure(newNodeStructure.content, editor, nodes[i]);
    structure.push(newNodeStructure);
  }

  return structure;
}

export function getListOfNodes(editor, slice, list) {
  list = list ? list : [];
  const nodes = (slice ? slice : editor.view.state.selection.content()).content
    .content;

  for (let i = 0; i < nodes.length; i++) {
    list.push(nodes[i].type.name);
    getListOfNodes(editor, nodes[i], list);
  }

  return list;
}

export function isDocumentEmpty(editor, node) {
  node = node ? node : editor.view.state.doc;

  for (let i = 0; i < node.content.content.length; i++) {
    const child = node.content.content[i];

    const exludedFoundAux =
      [
        "circle",
        "image",
        "video",
        "audio",
        "file",
        "embed",
        "grid1",
        "grid2",
        "grid3",
        "survey",
        "customform",
        "resource",
        "actionbutton",
        "interactiveInput",
        "interactiveWait",
        "interactiveButton",
        "fancyLink",
        "tagsFeed",
      ].indexOf(child.type.name) >= 0;

    if (
      ([
        "paragraph",
        "heading",
        "taskList",
        "orderedList",
        "bulletList",
        "infobox",
      ].indexOf(child.type.name) >= 0 &&
        child.textContent) ||
      exludedFoundAux
    ) {
      return false;
    }
  }
  return true;
}

export function getAllParents(editor, node) {
  // debugger;
}

export function isEmptySelection(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;
  // debugger
  let holder = editor.view.state.doc.nodeAt(editor.view.state.selection.from);

  if (!holder) {
    holder = $anchor.parent;
  }

  const noneTextBlocks = [
    "grid1",
    "grid2",
    "grid3",
    "column",
    "circle",
    "resource",
    "image",
    "video",
    "embed",
    "file",
    "audio",
    "survey",
    "customform",
    "resource",
    "actionbutton",
    "tagsFeed",
    "interactiveInput",
    "interactiveButton",
    "interactiveWait",
    "infobox",
  ];
  const isEmptyTextBlock =
    isTextSelection(editor.view.state.selection) && holder.textContent == "";

  const onlyTextNodes = noneTextBlocks.indexOf(holder.type.name) < 0;

  return isEmptyTextBlock && onlyTextNodes;
}

export function isNonTextBlock(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  let holder = editor.view.state.doc.nodeAt(editor.view.state.selection.from);

  if (!holder) {
    holder = $anchor.parent;
  }

  const noneTextBlocks = [
    "circle",
    "resource",
    "image",
    "video",
    "embed",
    "file",
    "audio",
    "survey",
    "customform",
    "resource",
    "actionbutton",
    "tagsFeed",
    "interactiveInput",
    "interactiveButton",
    "interactiveWait",
    "infobox",
  ];

  return noneTextBlocks.indexOf(holder.type.name) >= 0;
}

export function isNonTextBlockAbsolute(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  let holder = editor.view.state.doc.nodeAt(editor.view.state.selection.from);

  if (!holder) {
    holder = $anchor.parent;
  }

  const noneTextBlocks = [
    "grid1",
    "grid2",
    "grid3",
    "circle",
    "resource",
    "image",
    "video",
    "embed",
    "file",
    "audio",
    "survey",
    "customform",
    "resource",
    "actionbutton",
    "tagsFeed",
    "interactiveInput",
    "interactiveButton",
    "interactiveWait",
    "infobox",
  ];

  return noneTextBlocks.indexOf(holder.type.name) >= 0;
}

export function isParagraph(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  let holder = node;

  if (!holder) {
    holder = $anchor.parent;
  }

  const noneTextBlocks = ["paragraph"];

  return noneTextBlocks.indexOf(holder.type.name) >= 0;
}

export function isDepth(editor, depth) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  return $anchor.depth == depth;
}

export function isInDepth(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  return $anchor.depth > 1;
}

export function isInsideNode(editor, nodeName) {
  const { from, to, $anchor, node } = editor.view.state.selection;
  for (let i = 0; i < $anchor.path.length; i++) {
    if (
      $anchor.path[i].type &&
      (nodeName.indexOf($anchor.path[i].type.name) >= 0 ||
        $anchor.path[i].type.name.indexOf(nodeName) >= 0)
    ) {
      return true;
    }
  }
  return false;
}

export function isNodeInsideNodeOld(editor, getPos, nodeName) {
  const node = editor.view.state.doc.nodeAt(getPos() - 2);

  try {
    return (
      node.type &&
      (nodeName.indexOf(node.type.name) >= 0 ||
        node.type.name.indexOf(nodeName) >= 0)
    );
  } catch {
    return false;
  }
}

export function isNodeInsideNode(editor, getPos, nodeName) {
  const doc = editor.view.state.doc;

  try {
    let depth = false;
    doc.descendants((node, pos, parent, index) => {
      if (pos === getPos() && parent.type.name == nodeName) {
        depth = true;
        return false; // Stop iterating through the document
      }
    });
    return depth;
  } catch {
    return false;
  }
}

export function isDocumentFocused(editor) {
  return document.getElementsByClassName("is-focus").length > 0;
}

export function isTopNode(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;
  return $anchor.depth == 1;
}

export function getTopParentNode(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  return { node: $anchor.path[3], pos: $anchor.path[2] };
}

export function isMultilineSelection(editor) {
  const { from, to, $anchor, node } = editor.view.state.selection;

  return to > from;
}

export function closestInteraction(editor, pos, blocker = false) {
  let found = -1;
  let interactivePosition = 0;
  let isBlocking = true;

  editor.view.state.doc.nodesBetween(0, pos, (node, pos) => {
    if (node.attrs.isInteractive) {
      found = pos;
      interactivePosition = node.attrs.interactivePosition;
      isBlocking = node.attrs.isBlocking;
    }
  });
  if (found > -1) {
    return { pos: found, interactivePosition, isBlocking };
  }
  return null;
}

export function closestBlockingInteraction(editor, pos, blocker = false) {
  let found = -1;
  let interactivePosition = 0;
  let isBlocking = true;

  editor.view.state.doc.nodesBetween(0, pos, (node, pos) => {
    if (node.attrs.isInteractive && node.attrs.isBlocking) {
      found = pos;
      interactivePosition = node.attrs.interactivePosition;
      isBlocking = node.attrs.isBlocking;
    }
  });
  if (found > -1) {
    return { pos: found, interactivePosition, isBlocking };
  }
  return null;
}

export function interactionCount(editor) {
  const doc = editor.view.state.doc;
  let count = 0;
  editor.view.state.doc.nodesBetween(0, doc.content.size, (node, pos) => {
    if (node.attrs.isInteractive) {
      count++;
    }
  });
  return count;
}

export function showNextInteraction(editor, _pos, newInteraction = true) {
  const doc = editor.view.state.doc;

  const positions = [];
  doc.nodesBetween(_pos, doc.content.size, (node, pos) => {
    positions.push([node, pos]);
  });
  for (let i = 1; i < positions.length; i++) {
    const node = positions[i][0];
    const pos = positions[i][1];

    if (!node.isText) {
      const { tr } = editor.view.state;
      tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        incrementor: node.attrs.incrementor + 1,
      });
      editor.view.dispatch(tr);
    }
  }

  setTimeout(() => {
    try {
      updateInteractionsBar(editor);
      const viewport = document.querySelector(
        ".content-scrollview .mantine-ScrollArea-viewport"
      );
      if (viewport && newInteraction) {
        const node = editor.view.state.doc.nodeAt(_pos + 1);
        let element = document.getElementById(node.attrs.uid);
        if (!element){
          element = document.querySelector(`[uid="${node.attrs.uid}"]`)
        }
        viewport.scrollTo({ top: element.offsetTop - 50 });
      }
    } catch {}
  }, 500);
}

export function beginningOfContextForInteraction(editor, _pos) {
  const doc = editor.view.state.doc;

  let nonInteractiveNode = null;
  doc.nodesBetween(0, _pos, (node, pos) => {

    if (node.attrs.isInteractive && pos != _pos){
      nonInteractiveNode = null
    }
    else{
      if (!nonInteractiveNode){
        nonInteractiveNode = node;
      }
    }

  });
  
  return nonInteractiveNode;

}

export function updateInteractionsBar(editor) {
  try {
    
    const current = window.$spaceUserInteractions.position != null
      ? window.$spaceUserInteractions.position + 1
      : 0;
    const total = interactionCount(editor);
    const progress = (current * 100.0) / total;
    console.log(`CURRENT: ${current} + TOTAL: ${total}`);
    Broadcaster.send("open_InteractionsBar", document.body, {
      progress: progress,
    });
  } catch (e) {
  } finally {
  }
}

export function checkInteractionAndHideIfNeeded(
  editor,
  dom,
  getPos,
  display = "block"
) {
  dom.style.display = display;
  if (window.$spaceUserInteractions) {
    const interaction = closestInteraction(editor, getPos(), true);
    const blockingInteraction = closestBlockingInteraction(
      editor,
      getPos(),
      true
    );

    if (
      blockingInteraction != null &&
      window.$spaceUserInteractions.position <
        blockingInteraction.interactivePosition &&
      blockingInteraction.isBlocking
    ) {
      dom.style.display = "none";
    }

    updateInteractionsBar(editor);
  }
}

export function checkInteractionAndHideIfNeededReact(editor, getPos) {
  if (window.$spaceUserInteractions) {
    try {
      const interaction = closestInteraction(editor, getPos(), true);
      const blockingInteraction = closestBlockingInteraction(
        editor,
        getPos(),
        true
      );

      if (
        blockingInteraction != null &&
        window.$spaceUserInteractions.position <
          blockingInteraction.interactivePosition &&
        blockingInteraction.isBlocking
      ) {
        return false;
      }
    } catch {
      return false;
    }
  }
  return true;
}

export function updateInteractionsPositions(editor) {
  const doc = editor.view.state.doc;

  const positions = [];
  let auxPos = 0;
  doc.nodesBetween(0, doc.content.size, (node, pos) => {
    if (node.type.name.indexOf("interactive") >= 0) {
      positions.push([node, auxPos, pos]);
      auxPos++;
    }
  });

  for (let i = 0; i < positions.length; i++) {
    const node = positions[i][0];
    const intPos = positions[i][1];
    const pos = positions[i][2];

    if (!node.isText) {
      const { tr } = editor.view.state;
      tr.setNodeMarkup(pos, undefined, {
        ...node.attrs,
        interactivePosition: intPos,
      });
      editor.view.dispatch(tr);
    }
  }
}

export function setUniqId(editor, pos) {
  const node = editor.view.state.doc.nodeAt(pos);
  const dom = editor.view.nodeDOM(pos);

  const uid = uuidv4();
  // console.log(`SETTTING ID FOR: ${node.type.name} ${node.attrs.uid} ${uid} ${node.attrs.incrementor} ${pos()}` )
  const { tr } = editor.view.state;
  tr.setNodeMarkup(pos, undefined, {
    ...node.attrs,
    uid: uid,
  });

  editor.view.dispatch(tr);

  if (dom) {
    dom.setAttribute("data-uid", uid);
    dom.setAttribute("id", uid);
  }

  return uid;
}

export function setAttrsToAllByType(editor, type, attrs) {

  const doc = editor.view.state.doc;

  const nodesToUpdate = []

  doc.nodesBetween(0, doc.content.size, (node, pos) => {
    if (node.type.name == type) {
      nodesToUpdate.push([node, pos]);
    }
  });

  for (let i = 0; i < nodesToUpdate.length; i++) {
    const node = nodesToUpdate[i][0];
    const pos = nodesToUpdate[i][1];

    const { tr } = editor.view.state;
    tr.setNodeMarkup(pos, undefined, {
      ...node.attrs,
      ...attrs,
    });

    editor.view.dispatch(tr); 
  }
}
