import React, { useState, useEffect, useRef } from "react";

import {
  Text,
  Box,
  Group,
  Drawer,
  Select,
  ScrollArea,
  Button,
} from "@mantine/core";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import StepModel from "../../../../models/EngagementStep";
import StepIcon from "./StepIcon";

export default function AddStep(props) {
  const { t, i18n } = useTranslation();
  const [firstSelection, setFirstSelection] = useState(null);
  const [actions, setActions] = useState([]);
  const [canSetToPending, setCanSetToPending] = useState(false);
  const [canAddToExistingSpace, setCanAddToExistingSpace] = useState(false);
  const [canWait, setWait] = useState(false);
  const [canSurveyPerson, setCanSurveyPerson] = useState([]);
  const [canSendEmail, setCanSendEmail] = useState([]);
  const [canSendMessage, setCanSendMessage] = useState([]);
  const [canAddFromChildren, setCanAddFromChildren] = useState([]);
  const [canAddToDirectOpenChildren, setCanAddToDirectOpenChildren] = useState(
    []
  );
  const [canWebhook, setCanWebhook] = useState([]);
  const [opened, setOpened] = useState(false);
  const lastOpenedState = useRef(false);

  const space = useSelector((state) => state.space);

  useEffect(() => {
    if (props.functionSlug && props.functions){
      const _function = props.functions.filter(item => item.slug == props.functionSlug)[0];
      setActions(_function.actions.map(item => ({label: item.name, value: item.slug, description: item.description, icon: item.type})));
    }
  }, [props]);

  useEffect(() => {
    if (props.selectedStep){
      setFirstSelection(props.selectedStep.action_slug)
    }
  }, [props]);

  const onSave = () => {
    if (props.selectedStep){
      onUpdate()
    }
    else{
      onCreate()
    }
  }

  const onCreate = () => {
    StepModel.onCreate(
      space.slug,
      props.triggerId,
      { action_slug: firstSelection, position: props.position },
      (data) => {
        props.onUpdate();
        props.setOpened(false);
      },
      (data) => {}
    );
  };

  const onUpdate = () => {
    StepModel.onUpdate(
      props.selectedStep.id,
      space.slug,
      props.triggerId,
      { action_slug: firstSelection, position: props.position },
      (data) => {
        props.onUpdate();
        props.setOpened(false);
      },
      (data) => {}
    );
  };

  const onDelete = () => {
    StepModel.onDestroy(
      props.selectedStep.id,
      space.slug,
      props.triggerId,
      (data) => {
        props.onUpdate();
        props.setOpened(false);
      },
      (data) => {}
    );
  };

  const SelectItem = ({ label, description, ...others }) => (
    <div {...others}>
      <Group noWrap>
        <StepIcon icon={others.icon} />
        <div>
          <Text size="lg" weight={600}>
            {label}
          </Text>
          <Text size="md" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  );

  const displaySecondSelection = () => {
    return <></>;
  };

  return (
    <>
      <Drawer
        opened={props.opened}
        onClose={() => {
          props.setOpened(false);
          setFirstSelection(null);
        }}
        title={
          <Text size="xl" weight="800">
            {!props.selectedStep
              ? t("spaces.settings.registration.addStep.addStep.description")
              : props.selectedStep.action}
          </Text>
        }
        padding="xl"
        size={600}
        offsetScrollbars
      >
        <ScrollArea style={{ height: "100%" }}>
          <Select
            label={t("spaces.settings.registration.addStep.addStep.label")}
            value={firstSelection}
            onChange={setFirstSelection}
            placeholder={t(
              "spaces.settings.registration.addStep.addStep.placeholder"
            )}
            itemComponent={SelectItem}
            data={actions}
            maxDropdownHeight={400}
            radius="sm"
            size="md"
          />
          {displaySecondSelection()}
          <Group position="apart" mt={20}>
            <Button radius={"xl"} onClick={() => onSave()}>{"Save"}</Button>
            {props.selectedStep && props.selectedStep.id && (
              <Button radius={"xl"} color={"red"} onClick={() => onDelete()}>
                {"Delete"}
              </Button>
            )}
          </Group>
          <Box mt={100} />
        </ScrollArea>
      </Drawer>
    </>
  );
}
