import React, { useEffect, useState, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Chips, Chip, Modal, Button } from "@mantine/core";

import { useTranslation } from "react-i18next";

import isSpaceThemed from "../../../../utils/IsSpaceThemed";
import isDark from "../../../../utils/IsDarkColor";

import tinycolor from "tinycolor2";
import { useFetcher } from "react-router-dom";

import {
  setSelectedDiscussionTags
} from "../../../../store/app";

const TagsChips = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch()

  const space = useSelector((state) => state.colors);
  const colors = useSelector((state) => state.colors);
  const selectedTags = useSelector((state) => state.selectedDiscussionTags);

  const [tags, setTags] = useState([]);
  const [opened, setOpened] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const parentRef = useRef(null);

  const loaded = useRef(false);
  const skipNextTags = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      if (!window.$selectedTags) {
        if (props.selectedTag instanceof String) {
          setTags([props.selectedTag]);
        } else {
          setTags([...props.selectedTag]);
        }
      }

      // if (window.$selectedTags && window.$selectedTags.length) {
      //   setTags([...window.$selectedTags]);
      // }
    }
    checkOverflow();
  }, [props]);

  useEffect(() => {
    const auxTags = [...tags].filter(item => item !== null)
    if (skipNextTags.current) {
      skipNextTags.current = false;
      return;
    }
    if (tags[tags.length - 1] == "toggle-mode-options") {
      const auxTags = [...auxTags];
      auxTags.pop();
      skipNextTags.current = true;
      setTags(auxTags);
      setOpened(true);
      return;
    }
    if (loaded.current) {
      props.setTags([...auxTags]);
      window.$selectedTags = [...auxTags];
      dispatch(setSelectedDiscussionTags({tags: [...auxTags]}))
    }
    setTimeout(() => {
      loaded.current = true;
    }, 300);
  }, [tags]);

  useEffect(() => {
    skipNextTags.current = true;
    setTags([...selectedTags.tags]);
  }, [selectedTags]);

  const checkOverflow = () => {
    const parent = parentRef.current;
    try{
      console.log("HAS OVERFOWN: " + parent.children[0].clientWidth + " > " + (parent.clientWidth - 100))
      const isOverflowing = parent.children[0].clientWidth > parent.clientWidth - 100;
      setHasOverflow(isOverflowing);
    }
    catch{

    }
  };

  const orderTags = () => {
    if (props.tagsList && props.tagsList.length < 4) {
      return props.tagsList;
    }
    // Create a map to store the index of each tag value in selectedTags
    const tagIndexMap = new Map();
    tags.forEach((tag, index) => {
      tagIndexMap.set(tag, index);
    });

    const newTagsList = [...props.tagsList];

    
    
    // Sort the tags based on their index in selectedTags
    newTagsList.sort((a, b) => {
      const indexA = tagIndexMap.has(a.name)
      ? tagIndexMap.get(a.name)
      : -1;
      const indexB = tagIndexMap.has(b.name)
      ? tagIndexMap.get(b.name)
      : -1;
      return indexB - indexA;
    });
   

    return newTagsList;
  };

  return (
    <>
      {props.tagsList && props.tagsList.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <Box
            mt={props.m ? props.m[0] : 15}
            mb={props.m ? props.m[1] : 10}
            ml={props.m ? props.m[2] : 0}
            mr={props.m ? props.m[3] : 0}
            spacing={0}
            noWrap
            sx={{
              position: "relative",
            }}
          >
            <Box
              ref={parentRef}
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
                padding: "2px"
              }}
            >
              <Chips
                sx={{
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
                styles={{
                  outline: {
                    color: colors.default_text_color,
                    backgroundColor: colors.background_color,
                    height: "auto",
                    border: isSpaceThemed(colors)
                      ? `1px solid ${
                          isDark(colors.background_color)
                            ? tinycolor(colors.background_color)
                                .lighten(40)
                                .toString()
                            : tinycolor(colors.background_color)
                                .darken(40)
                                .toString()
                        }`
                      : `1px solid #ced4da`,
                    "&:hover": {
                      backgroundColor: isSpaceThemed(colors)
                        ? `${
                            isDark(colors.background_color)
                              ? tinycolor(colors.background_color)
                                  .lighten(5)
                                  .toString()
                              : tinycolor(colors.background_color)
                                  .darken(5)
                                  .toString()
                          }`
                        : `#e9ecef`,
                    },
                  },
                  checked: {
                    border: isSpaceThemed(colors)
                      ? `2px solid ${
                          isDark(colors.background_color)
                            ? tinycolor(colors.background_color)
                                .lighten(40)
                                .toString()
                            : tinycolor(colors.background_color)
                                .darken(40)
                                .toString()
                        } !important`
                      : ``,
                    color: colors.default_text_color,
                  },
                  checkIcon: {
                    color: isSpaceThemed(colors)
                      ? `${
                          isDark(colors.background_color)
                            ? tinycolor(colors.background_color)
                                .lighten(40)
                                .toString()
                            : tinycolor(colors.background_color)
                                .darken(40)
                                .toString()
                        }`
                      : ``,
                  },
                }}
                multiple
                value={tags}
                onChange={setTags}
              >
                {orderTags()
                  .slice(0, 10)
                  .map((tag) => {
                    return <Chip value={tag.name}>{tag.name}</Chip>;
                  })}

              </Chips>
              {hasOverflow && (
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  background: `linear-gradient(to left, ${tinycolor(colors.background_color).setAlpha(1).toRgbString()}, ${tinycolor(colors.background_color).setAlpha(0.5).toRgbString()})`,
                  // background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgb(250, 250, 250) 20%)',
                  height: "100%",
                }}
              >
                <Button size="xs" ml={40} radius="xl" variant="outline" color="dark" sx={{
                  color: colors.default_text_color,
                  backgroundColor: colors.background_color,
                  border: `1px solid ${colors.default_text_color}`,
                  "&:hover": {
                    backgroundColor: isSpaceThemed(colors)
                      ? `${
                          isDark(colors.background_color)
                            ? tinycolor(colors.background_color)
                                .lighten(5)
                                .toString()
                            : tinycolor(colors.background_color)
                                .darken(5)
                                .toString()
                        }`
                      : `#e9ecef`,
                  },
                }} onClick={() => setOpened(true)}>
                  More
                </Button>
              </Box>
              )}
            </Box>
          </Box>
          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            title="All tags"
            size={"lg"}
          >
            <Chips
              styles={{
                outline: {
                  color: "#000",
                  backgroundColor: "#fff",
                  height: "auto",
                  border: `1px solid #ced4da`,
                  "&:hover": {
                    backgroundColor: `#e9ecef`,
                  },
                },
                checked: {
                  color: "#000",
                },
                checkIcon: {
                  color: ``,
                },
              }}
              multiple
              value={tags}
              onChange={setTags}
            >
              {props.tagsList.map((tag) => {
                return <Chip value={tag.name}>{tag.name}</Chip>;
              })}
            </Chips>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default TagsChips;
