import React, { useState, useEffect, useRef } from "react";

import {
  Container,
  Badge,
  Group,
  Center,
  ScrollArea,
  SegmentedControl,
  MantineProvider,
  Button,
  Box,
  useMantineTheme,
  Pagination
} from "@mantine/core";

import { useLocation } from "react-router-dom";

import SectionHeader from "./layout/SectionHeader";
import Registered from "./members/Registered";
import Invited from "./members/Invited";
import Payments from "./members/Payments";
import Interested from "./members/Interested";

import InvitePeople from "./members/InvitePeople";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import MemberModel from "../../../models/Member";

import ComposeBroadcast from "./broadcast/ComposeBroadcast";

import PremiumModal from "../../app/PremiumModal";

export default function Members() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const space = useSelector((state) => state.space);
  const theme = useMantineTheme();
  const [pageSection, setPageSection] = useState("registered");
  const [inviteOpened, setInviteOpened] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [counts, setCounts] = useState(false);
  const [opened, setOpened] = useState(false);
  const [openedPremium, setOpenedPremium] = useState(false);

  const [canSendSelective, setCanSendSelective] = useState([]);

  const sendToUsers = useRef([]);
  const broadcastType = useRef("email");

  useEffect(() => {
    checkSearchParams();
  }, location);

  useEffect(() => {
    
    if (refresh){
      onFetchCounts();
      setRefresh(false)
    }
  }, [refresh]);

  useEffect(() => {
    setCanSendSelective(
      space &&
        space.permissions &&
        space.permissions.can_send_selective_broadcast
    );
    onFetchCounts();
  }, [space]);

  const onBroadcastUpdate = () => {};

  const onSendBroadcast = (type, users, selected) => {
    if (!canSendSelective[0]) {
      setOpenedPremium([true, canSendSelective[1], canSendSelective[2]]);
      return;
    }

    sendToUsers.current = [];
    for (let i = 0; i < users.length; i++) {
      if (
        selected.indexOf(users[i].username) >= 0 &&
        window.$currentUserId != users[i].user_id
      ) {
        sendToUsers.current.push({ ...users[i] });
      }
    }
    broadcastType.current = type;
    setOpened(true);
  };

  const checkSearchParams = () => {
    const search = location.search;
    const section = new URLSearchParams(search).get("section");

    if (section) {
      setPageSection(section);
    }
  };

  const displayPageSection = () => {
    if (pageSection == "registered") {
      return (
        <Registered
          theme={theme}
          refresh={refresh}
          setRefresh={setRefresh}
          onSendBroadcast={onSendBroadcast}
          onFetchCounts={onFetchCounts}
        />
      );
    } else if (pageSection == "invited") {
      return (
        <Invited theme={theme} refresh={refresh} setRefresh={setRefresh} onFetchCounts={onFetchCounts} />
      );
    } else if (pageSection == "interested") {
      return (
        <Interested theme={theme} refresh={refresh} setRefresh={setRefresh} onFetchCounts={onFetchCounts} />
      );
    } else if (pageSection == "payments") {
      return (
        <Payments theme={theme} refresh={refresh} setRefresh={setRefresh} onFetchCounts={onFetchCounts} />
      );
    } else {
      return (
        <Registered theme={theme} refresh={refresh} setRefresh={setRefresh} onFetchCounts={onFetchCounts} />
      );
    }
  };

  const onDownloadExcel = () => {
    MemberModel.onDownloadExcel(space.slug);
  };

  const onFetchCounts = () => {
    MemberModel.onFetchCounts(
      space.slug,
      (data) => {
        
        setCounts(data);
      },
      (data) => {}
      );
    };
    
    const getTab = (label, key) => {
    
    return (
      <Center>
        <Box mr={10}>{label}</Box>
        <Badge color="dark" variant="outline">
          {counts[key]}
        </Badge>
      </Center>
    );
  };

  return (
    <ScrollArea style={{ height: "100%" }}>
      <Container
        style={{ marginBottom: "50px" }}
        pt={20}
        pb={40}
        pl={40}
        pr={40}
        sx={(theme) => ({
          width: "850px",
          maxWidth: "850px",
        })}
      >
        <SectionHeader
          spaceId={space.slug}
          spaceVersion={space.version}
          spaceName={space.name}
          title={t("spaces.settings.members.title")}
          rightSide={
            <Group>
              <Button
                variant="light"
                color="gray"
                radius="xl"
                onClick={(event) => onDownloadExcel()}
                sx={(theme) => ({
                  backgroundColor: theme.colors.gray[1],
                  color: theme.colors.gray[8],
                })}
              >
                {t("spaces.settings.members.members.download")}
              </Button>
              <Button
                variant="filled"
                radius="xl"
                onClick={(event) => setInviteOpened(true)}
                leftIcon={<FontAwesomeIcon icon={solid("user-plus")} />}
                sx={{
                  backgroundColor: theme.colors.sutrablue[3],
                  "&:hover": {
                    backgroundColor: theme.colors.sutrablue[4],
                  },
                }}
              >
                {t("spaces.settings.members.members.invite")}
              </Button>
            </Group>
          }
        />

        <SegmentedControl
          fullWidth
          value={pageSection}
          onChange={(value) => setPageSection(value)}
          data={[
            {
              label: getTab(t("spaces.settings.members.members.member"), "all"),
              value: "registered",
            },
            {
              label: getTab(
                t("spaces.settings.members.members.invited"),
                "invitations"
              ),
              value: "invited",
            },
            {
              label: getTab(
                t("spaces.settings.members.members.interested"),
                "interested"
              ),
              value: "interested",
            },
            {
              label: getTab(
                t("spaces.settings.members.members.payment"),
                "payments"
              ),
              value: "payments",
            },
          ]}
        />

        <MantineProvider theme={{ primaryShade: 6 }}>
          {displayPageSection()}
        </MantineProvider>
        <InvitePeople
          opened={inviteOpened}
          setOpened={setInviteOpened}
          setRefresh={setRefresh}
        />
      </Container>
      <ComposeBroadcast
        opened={opened}
        setOpened={setOpened}
        onUpdate={onBroadcastUpdate}
        sendToUsers={sendToUsers.current}
        broadcastType={broadcastType.current}
        parentName={space.name}
        isTopLevel={space.is_top_level}
      />
      <PremiumModal opened={openedPremium} setOpened={setOpenedPremium} />
    </ScrollArea>
  );
}
