import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEditor, EditorContent } from "@tiptap/react";

import StarterKit from "@tiptap/starter-kit";

import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Underline from "@tiptap/extension-underline";

import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";

import Placeholder from "@tiptap/extension-placeholder";

import UniqueID from "../plugins/CustomUniqId";

import TextAlign from "@tiptap/extension-text-align";
import Details from "@tiptap-pro/extension-details";
import DetailsSummary from "@tiptap-pro/extension-details-summary";
import DetailsContent from "@tiptap-pro/extension-details-content";

import Link from "@tiptap/extension-link";

import ParagraphNode from "../nodes/ParagraphNode";
import HeadingNode from "../nodes/HeadingNode";
import ImageNode from "../nodes/ImageNode";
import VideoNode from "../nodes/VideoNode";
import AudioNode from "../nodes/AudioNode";
import EmbedNode from "../nodes/EmbedNode";
import FileNode from "../nodes/FileNode";
import ListItemNode from "../nodes/ListItemNode";
import FancyLinkNode from "../nodes/FancyLinkNode";

import FormatMenuSimple from "./FormatMenuSimple";
import BlocksMenuInline from "./BlocksMenuInline";

import CustomStyles from "../plugins/CustomStyles";
import EventHandler from "../plugins/EventHandler";
import CustomSize from "../plugins/CustomSize";
import CustomResizeable from "../plugins/CustomResizeable";
import ColumnStyles from "../plugins/ColumnStyles";
import isSpaceThemed from "../../../utils/IsSpaceThemed";
import useSidebarStyles from "../../../utils/SidebarStyles";
import defaultSpaceColors from "../../../collections/defaultSpaceColors";

import EditorEvents from "../plugins/EditorEventsSimple";

import tinycolor from "tinycolor2";

import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";

import { Mention } from "../plugins/Mention";
import mentionSuggestion from "../plugins/mentionSuggestion";

import { isDocumentEmpty } from "../utils/selection";

import { Box, createStyles } from "@mantine/core";

import { storeJSON, getJSON } from "../../../utils/LocalDB";

import isDark from "../../../utils/IsDarkColor";

const useStyles = createStyles((theme, { colors }) => ({
  panelWrap: {
    border: isSpaceThemed(colors)
    ? `1px solid ${
        isDark(colors.background_color)
          ? tinycolor(colors.background_color).lighten(20).toString()
          : tinycolor(colors.background_color).darken(20).toString()
      }`
    : `1px solid #e9ecef`,
    borderRadius: 10,
    backgroundColor: colors.background_color,
    color: colors.default_text_color,
  },
}));

const Tiptap = (props) => {
  const { settingsView, registrationView, listView } = useSidebarStyles();

  const getColors = () => {
    if (props.useSpace2) {
      return isSpaceThemed(colors2) ? colors2 : defaultSpaceColors;
    } else {
      return isSpaceThemed(colors) ? colors : defaultSpaceColors;
    }
  };

  let colors = useSelector((state) => state.colors);
  let colors2 = useSelector((state) => state.colors2);

  colors =
    !settingsView && !registrationView && (!listView || props.useSpace2)
      ? colors
      : defaultSpaceColors;

  colors2 =
    !settingsView && !registrationView && (!listView || props.useSpace2)
      ? colors2
      : defaultSpaceColors;

  const { classes, cx, theme } = useStyles({ colors: getColors() });

  const timeoutId = useRef();
  const contextGet = useRef();
  const loaded = useRef();

  const [formatbar, setFormatbar] = useState(true);

  const editor = useEditor({
    onCreate({ editor }) {},
    editable: props.editable,
    content: props.content ? { type: "doc", content: props.content } : "",
    extensions: [
      StarterKit.configure({
        history: true,
        paragraph: false,
        heading: false,
        listItem: false,
        blockquote: false
      }),
      UniqueID.configure({
        types: [
          "heading",
          "paragraph",
          "grid2",
          "grid3",
          "column",
          "tasklist",
          "taskItem",
          "circle",
          "video",
          "audio",
          "image",
          "embed",
          "file",
          "detailsSummary",
          "details",
          "detailsContent",
          "bulletList",
          "orderedList",
          "listItem",
          "taskItem",
          "fancyLink",
        ],
      }),
      Highlight.configure({
        multicolor: true,
      }),
      EventHandler,
      EditorEvents,
      Underline,
      TextStyle,
      Color,
      VideoNode,
      AudioNode,
      EmbedNode,
      FileNode,
      ListItemNode,
      ParagraphNode,
      HeadingNode,
      FancyLinkNode,

      ColumnStyles.configure({
        types: ["column"],
      }),
      CustomSize.configure({
        types: ["file", "audio", "fancyLink"],
      }),
      CustomResizeable.configure({
        types: ["image", "video", "embed"],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: ({ node, editor }) => {
          if (
            node.type.name === "paragraph" &&
            isDocumentEmpty(editor) &&
            props.placeholder
          ) {
            return props.placeholder;
          }

          return "";
        },
        showOnlyCurrent: true,
      }),
      Link.configure({
        openOnClick: false,
      }),
      ImageNode,
      TextAlign.configure({
        types: [
          "heading",
          "paragraph",
          "detailsSummary",
          "image",
          "video",
          "embed",
          "file",
          "audio",
          "fancyLink",
        ],
      }),
      Details.configure({
        HTMLAttributes: {
          openClassName: "is-open",
          class: "tiptap-details",
        },
      }),
      DetailsSummary.configure({
        HTMLAttributes: {
          class: "tiptap-details-summary",
        },
      }),
      DetailsContent.configure({
        HTMLAttributes: {
          class: "tiptap-details-content",
        },
      }),
      Emoji.configure({
        emojis: gitHubEmojis,
        enableEmoticons: true,
        // suggestion,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "mention",
        },
        suggestion: mentionSuggestion,
        renderLabel({ options, node }) {
          const value = node.attrs.label ?? node.attrs.id.split("|")[1];
          return `${options.suggestion.char}${value}`;
        },
      }),
    ],
  });

  useEffect(() => {
    loaded.current = false;
    return () => {
      contextGet.current = false;
    };
  }, []);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      setFormatbar(props.exclude.indexOf("formatbar") < 0);
    }
  }, [props]);

  useEffect(() => {
    if (editor) {
      editor.off("update", onUpdate);
      editor.on("update", onUpdate);
      editor.off("create", onCreate);
      editor.on("create", onCreate);
    }
  }, [editor]);

  const onCreate = () => {
    contextGet.current = false;
    if (props.contextDb) {
      getJSON(`spaces`, props.contextDb, (data) => {
        setTimeout(() => {
          contextGet.current = true;
        }, 2000);
        
        if (data && data.json) {
          editor.commands.setContent(typeof data.json == "string" ? JSON.parse(data.json) : data.json);
        }
      });
    }
  };
  const cleanHtml = (html) => {
    const el = document.createElement("div");
    el.innerHTML = html;
    let lastNonEmptyEl = null;
    let currEl = el.lastElementChild;
    while (currEl) {
      if (
        currEl.textContent.trim().length > 0 ||
        ["img", "video", "audio", "section"].includes(
          currEl.tagName.toLowerCase()
        )
      ) {
        lastNonEmptyEl = currEl;
        break;
      }
      currEl = currEl.previousElementSibling;
    }
    if (lastNonEmptyEl) {
      let currEmptyEl = lastNonEmptyEl.nextElementSibling;
      while (currEmptyEl) {
        const nextEl = currEmptyEl.nextElementSibling;
        if (
          currEmptyEl.textContent.trim().length == 0 &&
          !["img", "video", "audio", "section"].includes(
            currEmptyEl.tagName.toLowerCase()
          )
        ) {
          currEmptyEl.remove();
        }
        currEmptyEl = nextEl;
      }
    }

    return el.innerHTML;
  };

  const onUpdate = () => {
    
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => {
      const json_data = editor.getJSON();
      const html_data = $(editor.view.dom).html();
      if (props.contextDb && contextGet.current) {
        storeJSON("spaces", props.contextDb, {
          json: JSON.stringify(json_data),
          html: html_data,
        });
      }

      if (props.clearCallback) {
        props.onUpdate(
          editor.getJSON(),
          cleanHtml(
            $(editor.view.dom).html()
          ),
          editor.commands.clearContent,
          isDocumentEmpty(editor)
        );
      } else {
        props.onUpdate(
          editor.getJSON(),
          cleanHtml(
            $(editor.view.dom).html()
          ),
          null,
          isDocumentEmpty(editor)
        );
      }
    }, 500);
  };

  const toggleFormatbar = () => {
    if (props.canToggleFormatbar) {
      setFormatbar(!formatbar);
    }
  };

  return (
    <Box
      className={cx(
        classes.panelWrap,
        "tiptap-wrapper tiptap-comments tiptap-inline-editable"
      )}
      withBorder={true}
      id="tiptap-inline-container"
    >
      
      {formatbar && (
        <FormatMenuSimple
          minimal={false}
          editor={editor}
          exclude={props.exclude}
          useSpace2={props.useSpace2}
        />
      )}
      <EditorContent
        style={{
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
          paddingBottom: 10,
          color: getColors().default_text_color,
        }}
        editor={editor}
      />

      <BlocksMenuInline
        editor={editor}
        exclude={props.exclude}
        withSaveButton={props.withSaveButton}
        saveButtonText={props.saveButtonText}
        saveButtonIcon={props.saveButtonIcon}
        onSave={props.onSave}
        toggleFormatbar={toggleFormatbar}
        canToggleFormatbar={props.canToggleFormatbar}
        useSpace2={props.useSpace2}
      />
    </Box>
  );
};

export default Tiptap;
