
import { useTranslation } from 'react-i18next';

export default function useFrequencyOptions(excludeIndexes=[]) {

  const { t } = useTranslation()

  const frequencyOptions = [
    {
      label: t('settings.frequencyOptions.mute'),
      value: "-1"
    },
    {
      label: t('settings.frequencyOptions.always'),
      value: "0"
    },
    {
      label: t('settings.frequencyOptions.hour1'),
      value: "1"
    },
    {
      label: t('settings.frequencyOptions.hour2'),
      value: "2"
    },
    {
      label: t('settings.frequencyOptions.hour4'),
      value: "4"
    },
    {
      label: t('settings.frequencyOptions.hour8'),
      value: "8"
    },
    {
      label: t('settings.frequencyOptions.hour24'),
      value: "24"
    },
    {
      label: t('settings.frequencyOptions.hour168'),
      value: "168"
    },
    {
      label: t('settings.frequencyOptions.hour336'),
      value: "336"
    },
  ]

  for (let i = 0; i < excludeIndexes.length; i++) {
    frequencyOptions.splice(excludeIndexes[i], 1)
  }
  
  return {
    frequencyOptions
  }
}
